import React, {
  useState,
  Fragment,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import { Transition } from "@headlessui/react";

import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import {
  AiFillCloseCircle,
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineSearch,
} from "react-icons/ai";
import { useTranslation } from "react-i18next";
import useOnClickOutside from "./useClickOutside";
function classNames(...classes) {
  return classes.filter(Boolean).join(",");
}
function MultiSelectComponent({
  defaultItem,
  data,
  onChange,
  setSelected,
  prefix,
}) {
  const [array, setArray] = useState([]);
  const [selectedA, setSelectedA] = useState([]);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [all, setAll] = useState(false);
  const searchRef = useRef();
  const containterRef = useRef(null);
  const { t } = useTranslation();
  const onCHangeFunc = (e) => {
    if (prefix === "CL") onChange(searchRef.current?.value);

    if (e !== "") {
      const results = array.filter((d) => {
        return (
          d.label.toLowerCase().includes(e) ||
          d.labelD.toLowerCase().includes(e.toLowerCase())
        );
      });

      setSearch(e);
      setArray(results);
    } else {
      setSearch("");
      setArray(data);
    }
  };

  function pushArray(d) {
    if (prefix !== "CL" && d === array) {
      setSelectedA(d);
      setSelected("all");
    } else if (prefix === "CL" && d === array) {
      setSelectedA([...d]);

      setSelected("all");
    } else if (d.length === 0) {
      setAll(false);
      setSelectedA([]);
      setSelected("");
    } else {
      setAll(false);
      if (selectedA.find((p) => p.id === d.id)) {
        setSelectedA(selectedA.filter((p) => p.id !== d.id));
        setSelected(
          selectedA
            .filter((p) => p.id !== d.id)
            .map((s) => s.value)
            .toString()
        );
      } else {
        console.log([...selectedA, d]);

        setSelectedA([...selectedA, d]);

        if (
          [...selectedA, d].length === array.length &&
          searchRef.current?.value.length === 0
        )
          setSelected("all");
        else setSelected([...selectedA, d].map((s) => s.value).toString());
      }
    }
  }
  useEffect(() => {
    setArray(data.filter((a, i) => data.findIndex((s) => a.id === s.id) === i));
  }, [data]);
  useLayoutEffect(() => {
    var a,
      b = [];
    let c = [];

    if (defaultItem) {
      if (prefix === "groupe") {
        a = defaultItem.membres_ids?.split(",");
        //.filter((n,i) => i % 2 ===1)
        b = defaultItem.membres_names?.split(",");
        //.filter((n,i)=>i%2===1)
        a?.forEach((e, i) => {
          c?.push({ id: e, value: e, label: b[i] });
        });
        setSelectedA(c);
      } else {
        defaultItem?.forEach((e) => {
          c?.push({ id: e.Id_Agent, value: e.Id_Agent, label: e.Fullname });
        });
        setSelectedA(c);
      }
    }

    return () => {};
  }, [defaultItem]);

  useOnClickOutside(containterRef, open, () => {
    setOpen(false);
  });

  return (
    <div
      ref={containterRef}
      // tabIndex={0}
      // onBlur={() => {
      //   if (focus) {
      //     console.log("taataa");
      //     return false;
      //   } else {
      //     setOpen(false);
      //     console.log("2");
      //   }
      // }}
    >
      <div className="mt-1 relative">
        <div
          className="min-w-full  bg-white dark:bg-gray-700
          w-full border border-gray-300 rounded-md
           shadow-sm  pr-2  text-left cursor-pointer
            focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm relative"
          onClick={() => {
            if (selectedA.length > 0) return false;
            else {
              setOpen(!open);
              console.log("3");
              return true;
            }
          }}
        >
          <div className="max-h-16 relative overflow-y-auto">
            <div
              className="bg-white dark:bg-gray-700   
            cursor-pointer 
            pl-3 py-2 
            text-left
              focus:outline-none
               focus:ring-1 
               focus:ring-indigo-500 space-y-1
                focus:border-indigo-500 sm:text-sm"
            >
              {selectedA?.length > 0 && selectedA?.length < array.length ? (
                selectedA?.map((d, index) => (
                  <div
                    key={`keyselected_${d.label}_${index}`}
                    className="  truncate ml-1  border-blue-400 border-2 rounded-full inline-flex  max-w-lg   justify-between items-center"
                  >
                    <span className="block ml-1 dark:underline-white dark:text-white">
                      {d.label}{" "}
                      {prefix === "CL" && d.labelD.length > 0 && "~" + d.labelD}
                    </span>
                    <div className=" hover:bg-gray-200 w-6   flex items-center justify-center rounded-full">
                      <AiOutlineClose
                        onClick={() => {
                          onCHangeFunc("");
                          if (prefix === "CL") onChange("");
                          pushArray(d);
                        }}
                        className="h-5 w-5  text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                ))
              ) : !all &&
                prefix === "CL" &&
                selectedA?.length > 0 &&
                selectedA?.length === array.length ? (
                selectedA?.map((d, index) => (
                  <div
                    key={`keyselected_${d.label}_${index}`}
                    className="  truncate   border-blue-400 border-2 rounded-full inline-flex  max-w-lg   justify-between items-center"
                  >
                    <span className="block   dark:underline-white dark:text-white">
                      {`${d.label} `}
                      {/*${d.labelD.length>0&& "~"+ d.labelD}*/}
                    </span>
                    <div className=" hover:bg-gray-200 w-6 h-6  flex items-center justify-center rounded-full">
                      <AiOutlineClose
                        onClick={() => pushArray(d)}
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                ))
              ) : all &&
                prefix === "CL" &&
                selectedA?.length > 0 &&
                selectedA?.length === array.length ? (
                <div
                  key={`keyselected_allElement`}
                  className="  truncate ml-3   border-blue-400 border-2 rounded-full inline-flex  max-w-lg   justify-between items-center"
                >
                  <span className="block   dark:underline-white dark:text-white">
                    Tous les éléments sont sélectionnés
                  </span>
                </div>
              ) : prefix !== "CL" &&
                selectedA?.length > 0 &&
                selectedA?.length === array.length ? (
                <div
                  key={`keyselected_allElement`}
                  className="  truncate ml-3   border-blue-400 border-2 rounded-full inline-flex  max-w-lg   justify-between items-center"
                >
                  <span className="block   dark:underline-white dark:text-white">
                    Tous les éléments sont sélectionnés
                  </span>
                </div>
              ) : (
                <span className="block ml-3 dark:text-white text-gray-400  truncate">
                  {t("All.Choisir")}...
                </span>
              )}
            </div>
          </div>
          <div
            onClick={() => {
              console.log("4");
              setOpen(!open);
            }}
            className="absolute inset-y-0  cursor-pointer right-0 space-x-1  flex items-center pr-2 "
          >
            {selectedA?.length > 0 && (
              <div className=" hover:bg-gray-200 w-6 h-6 flex items-center  justify-center rounded-full">
                <AiFillCloseCircle
                  onClick={() => {
                    setOpen(!false);
                    console.log("5");
                    pushArray([]);
                  }}
                  className="h-5 w-5  text-gray-400"
                  aria-hidden="true"
                />
              </div>
            )}
            {open ? (
              <BsChevronUp
                className="h-4 w-4 mr-2  text-gray-400"
                aria-hidden="true"
              />
            ) : (
              <BsChevronDown
                className="h-4 w-4 mr-2  text-gray-400"
                aria-hidden="true"
              />
            )}
          </div>
        </div>

        <Transition
          show={open}
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute z-10 mt-1 w-full  bg-white dark:bg-gray-700   rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            <div className=" dark:border dark:border-white   relative rounded-md shadow-sm">
              <div className="flex items-center relative">
                <div className=" dark:bg-gray-700 absolute top-0 bottom-0 mt-1 mb-1 ml-1 pl-2 flex items-center pointer-events-none">
                  <div className="text-gray-500 text-lg dark:text-white sm:text-sm">
                    <AiOutlineSearch className="h-5 w-5  " aria-hidden="true" />
                  </div>
                </div>
                <input
                  ref={searchRef}
                  value={search}
                  type="search"
                  onChange={(e) => onCHangeFunc(e.target.value)}
                  className={classNames(
                    "focus:ring-indigo-500 space-x-1  py-3 placeholder-gray-600 dark:placeholder-gray-300 focus:border-indigo-500 dark:bg-gray-700 dark:text-white block w-full pl-11 sm:pl-11 sm:text-sm border-gray-300 rounded-md"
                  )}
                  placeholder={t("All.Rechercher")}
                />
                {search.length > 0 && (
                  <div className=" hover:bg-gray-200 w-6 h-6 flex items-center justify-center cursor-pointer absolute right-3 rounded-full">
                    <AiOutlineClose
                      onClick={() => {
                        onCHangeFunc("");
                        if (prefix === "CL") onChange("");

                        setArray(data);
                      }}
                      className="h-5 w-5  text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="max-h-60  overflow-y-auto dark:bg-gray-600 w-full shadow">
              <div className="flex items-center ml-1 pt-4 cursor-pointer">
                <label
                  className="items-center    
           capitalize cursor-pointer text-md hover:text-gray-400    border-transparent   py-2 px-4 inline-flex justify-center font-semibold  "
                >
                  Tous
                  <input
                    type="checkbox"
                    className="hidden"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setAll(true);
                        pushArray(array);
                        setOpen(!open);
                        console.log("6");
                      } else {
                        pushArray([]);
                      }
                    }}
                  />
                </label>
              </div>
              {array.length > 0 ? (
                array.map((d) => (
                  <div
                    onClick={() => pushArray(d)}
                    key={`key_list_selected${d.label}`}
                    className="text-gray-900 dark:text-white font-light  dark:bg-gray-600  cursor-default relative py-1  px-2"
                    value={d.id}
                  >
                    <div
                      className={classNames(
                        selectedA?.find((p) => p.id == d.id)
                          ? "flex items-center h-10 pl-2 hover:shadow hover:text-gray-500 bg-gray-100 dark:bg-gray-300  justify-between cursor-pointer rounded-md "
                          : "flex hover:text-gray-500  items-center h-10 pl-2 hover:shadow hover:bg-gray-100  justify-between cursor-pointer rounded-md "
                      )}
                    >
                      <div className="flex">
                        {" "}
                        {d.label} {d.labelD.length > 0 && "~" + d.labelD}{" "}
                      </div>

                      {selectedA?.find((p) => p.id == d.id) && (
                        <AiOutlineCheck className="h-5 w-5 mr-2 dark:text-gray-600 text-gray-400" />
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex text-lg text-gray-600 items-center justify-center p-2 dark:text-white capitalize ">
                  {" "}
                  Vide
                </div>
              )}
            </div>
          </div>
        </Transition>
      </div>
      {prefix !== "CL" &&
      selectedA?.length > 0 &&
      selectedA?.length === array.length ? (
        <div className="flex underline text-gray-500 dark:text-white">
          Tous les éléments sont choisis
        </div>
      ) : all &&
        prefix === "CL" &&
        selectedA?.length > 0 &&
        selectedA?.length === array.length ? (
        <div className="flex underline text-gray-500 dark:text-white">
          Tous les éléments sont choisis
        </div>
      ) : selectedA?.length > 0 && selectedA?.length < array.length ? (
        <div className="flex underline text-gray-500 dark:text-white">
          {selectedA?.length} élément(s) choisi(s)
        </div>
      ) : null}
    </div>
  );
}
export default MultiSelectComponent;
