import {
  Get_Ticket,
  Get_Ticket_Failed,
  FETCH_TICKET,
  ADD_TICKET,
  ADD_TICKET_FAILED,
  UPDATE_TICKET,
  UPDATE_TICKET_FAILED,
  UPDATE_TICKET_SERVICE,
  UPDATE_TICKET_SERVICE_FAILED,
  SUCCESS_TICKET_UPDATE,
  GET_TICKET_FILTER,
  GET_TICKET_FILTER_OUT,
  DELETE_GROUPE_TICKET,
  DELETE_GROUPE_TICKET_FAILED,
  COMMENTER_GROUPE_TICKET,
  GET_COMMENTER_TICKET,
  GET_COMMENTER_TICKET_FAILED,
  ADD_COMMENTER_TICKET,
  DELETE_TICKET,
  SET_STATUS_TO_FILTER,
} from "./types";
import TicketServices from "../services/TicketSercvices";
import { SET_MESSAGE } from "../../actions/types";
import { t } from "i18next";
export const getTicket =
  (page, limit, id, idPers, Id_D_A, mine, q) => (dispatch) => {
    return TicketServices.getTickett(
      page,
      limit,
      id,
      idPers,
      Id_D_A,
      mine,
      q
    ).then(
      (res) => {
        dispatch({
          type: Get_Ticket,
          payload: {
            mine,
            firstItem: res.firstItem,
            lastItem: res.lastItem,
            ticket: res.response,
            page: res.currentPage,
            total: res.total,
            pageLast: res.lastPage,
            limit: res.perPage,
          },
        });

        return Promise.resolve();
      },
      (error) => {
        const message = error.erreur || error.toString();
        dispatch({
          type: Get_Ticket_Failed,
          payload: t("Ticket.Problemecnx"),
        });

        dispatch({
          type: SET_MESSAGE,
          payload: t("Ticket.Problemecnx"),
        });

        return Promise.reject();
      }
    );
  };

export const getDetailsTicket = (id, Id_Pers) => (dispatch) => {
  return TicketServices.getDetailTicket(id, Id_Pers).then(
    (res) => {
      dispatch({
        type: GET_COMMENTER_TICKET,
        payload: res,
      });
      return Promise.resolve();
    },
    (error) => {
      const message = error.erreur || error.toString();

      dispatch({
        type: GET_COMMENTER_TICKET_FAILED,
        payload: t("Ticket.Problemecnx"),
      });

      dispatch({
        type: SET_MESSAGE,
        payload: t("Ticket.Problemecnx"),
      });
      return Promise.reject();
    }
  );
};
export const addTicket = (data) => (dispatch) => {
  return TicketServices.addTickett(data).then(
    (res) => {
      console.log("res radamanddddd", res);
      res.map((e) => {
        if (data.general) {
          dispatch({
            type: ADD_TICKET,
            payload: {
              ticket: e,
              successG: t("Ticket.SuccesTicket"),
              success: "",
            },
          });
        } else {
          dispatch({
            type: ADD_TICKET,
            payload: {
              ticket: e,
              success: t("Ticket.SuccesTicket"),
              successG: "",
            },
          });
        }
      });
      return Promise.resolve();
    },
    (error) => {
      const message = error.erreur || error.toString();
      dispatch({
        type: ADD_TICKET_FAILED,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: t("Ticket.Problemecnx"),
      });

      return Promise.reject();
    }
  );
};
export const updateTicket = (data) => (dispatch) => {
  return TicketServices.updateTickett(data)
    .then(
      (res) => {
        if (data.general) {
          dispatch({
            type: UPDATE_TICKET,
            payload: {
              ticket: res,
              success: "",
              successG: t("Ticket.SuccesModifSle"),
            },
          });
        } else {
          if (Number(res.Id_Status) === 5)
            dispatch({
              type: DELETE_TICKET,
              // payload: {ticket:res, success:t('Ticket.TickAvecCode') `${res?.code_ticket}` t('Ticket.ModifTcikSucc'),successG:""},
              payload: {
                ticket: res,
                success: t("Ticket.TickAvecCode"),
                successG: "",
              },
            });
          else
            dispatch({
              type: UPDATE_TICKET,
              // payload: {ticket:res, success:t('Ticket.TickAvecCode') `${res?.code_ticket}` t('Ticket.ModifTcikSucc'),successG:""},
              payload: {
                ticket: res,
                success: t("Ticket.TickAvecCode"),
                successG: "",
              },
            });
        }

        return Promise.resolve();
      },
      (error) => {
        dispatch({
          type: UPDATE_TICKET_FAILED,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: t("Ticket.Problemecnx"),
        });

        return Promise.reject();
      }
    )
    .catch((e) => {
      dispatch({
        type: UPDATE_TICKET_FAILED,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: t("Ticket.Problemecnx"),
      });
    });
};
export const updateListTicket =
  (url, extra, ticket, Id_Pers, succes, extra2) => (dispatch) => {
    return TicketServices.updateListTickett(url, extra, ticket, Id_Pers).then(
      (res) => {
        if (extra === "" || extra2) {
          if (succes)
            dispatch({
              type: SUCCESS_TICKET_UPDATE,
              payload: { success: t("Ticket.SuccesModifTick") },
            });
          dispatch({
            type: UPDATE_TICKET_SERVICE,
            payload: res,
          });
        } else {
          if (succes)
            dispatch({
              type: SUCCESS_TICKET_UPDATE,
              payload: { success: t("Ticket.SuccesComm") },
            });
          dispatch({
            type: ADD_COMMENTER_TICKET,
            payload: res,
          });
        }

        return Promise.resolve();
      },
      (error) => {
        const message = error.erreur || error.toString();

        dispatch({
          type: UPDATE_TICKET_SERVICE_FAILED,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: t("Ticket.Problemecnx"),
        });

        return Promise.reject();
      }
    );
  };
export const groupeTickett =
  (url, extra, file, Id_Tckt, user, type) => (dispatch) => {
    return TicketServices.groupeTickett(
      url,
      extra,
      file,
      Id_Tckt,
      user,
      type
    ).then(
      (res) => {
        switch (type) {
          case "Déplacer":
            dispatch({
              type: DELETE_GROUPE_TICKET,
              payload: {
                resp: res,
                success: t("Ticket.TicketDéplacé"),
              },
            });
            break;
          case "remettre":
            dispatch({
              type: DELETE_GROUPE_TICKET,
              payload: {
                resp: res,
                success: t("Ticket.TicketInitial"),
              },
            });
            break;
          case "deleteticket":
            dispatch({
              type: DELETE_GROUPE_TICKET,
              payload: {
                resp: res,
                success: t("Ticket.TickDéplacéCorbeille"),
              },
            });
            break;
          case "Fusionner":
            dispatch({
              type: DELETE_GROUPE_TICKET,
              payload: {
                resp: res,
                success: t("Ticket.TickFusionné"),
              },
            });
            break;
          case "CommenterGroupe":
            dispatch({
              type: COMMENTER_GROUPE_TICKET,
              payload: {
                resp: res,
                success: t("Ticket.TicketCommentSucces"),
              },
            });
            break;
          case "AssingerGroupe":
            dispatch({
              type: COMMENTER_GROUPE_TICKET,
              payload: {
                resp: res,
                success: t("Ticket.AssignerversAgent") + " " + res[0]?.agent,
              },
            });
            break;
          case "updateticket_block":
            dispatch({
              type: COMMENTER_GROUPE_TICKET,
              payload: {
                resp: res,
                success: t("Ticket.TickModifSucces"),
              },
            });
            break;
          default:
            break;
        }

        return Promise.resolve();
      },
      (error) => {
        const message = error.erreur || error.toString();

        dispatch({
          type: DELETE_GROUPE_TICKET_FAILED,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: t("Ticket.Problemecnx"),
        });

        return Promise.reject();
      }
    );
  };
export const filtreTicket =
  (
    dateD,
    DateF,
    client,
    type,
    priorite,
    flux,
    status,
    agent,
    page,
    limit,
    fromRechCom
  ) =>
  (dispatch) => {
    return TicketServices.filtreTickett(
      dateD,
      DateF,
      client,
      type,
      priorite,
      flux,
      status,
      agent,
      page,
      limit
    ).then(
      (res) => {
        if (res && res.response?.length > 0) {
          dispatch({
            type: GET_TICKET_FILTER,
            payload: {
              firstItem: res.firstItem,
              lastItem: res.lastItem,
              ticket: res.response,
              page: res.currentPage,
              total: res.total,
              pageLast: res.lastPage,
              limit: res.perPage,
              filter: fromRechCom ? false : true,
            },
          });
          if (fromRechCom)
            dispatch({
              type: SET_STATUS_TO_FILTER,
              payload: true,
            });
        } else if (res.length === 0) {
          dispatch({
            type: GET_TICKET_FILTER,
            payload: {
              firstItem: 0,
              lastItem: 0,
              ticket: [],
              page: 1,
              total: 0,
              pageLast: 0,
              limit: 7,
            },
          });
        }
        return Promise.resolve();
      },
      (error) => {
        const message = error.erreur || error.toString();

        dispatch({
          type: GET_TICKET_FILTER_OUT,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: t("Ticket.Problemecnx"),
        });

        return Promise.reject();
      }
    );
  };
