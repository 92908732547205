import axios from "axios";
import { useSelector } from "react-redux";
import store from '../JS/store/store'
export default async function updateTicketFunc(Id_Pers,data){
  const userStore=await store.getState().auth.user
        let url = `ticket/edit/${data?.Id_Tckt}`;
        let error=""
         const response = await axios.put(
          process.env.REACT_APP_BASE_URL + url,
          JSON.stringify({
            code_client: data?.code_client,
            Id_Pers: Id_Pers,
            Flux: Number(data?.Flux) > 0
            ? Number(data?.Flux)
            : "" ,
            Id_Priorite:  Number(data?.Id_Priorite) > 0
            ? Number(data?.Id_Priorite)
            : "" ,
            Id_Status: Number(data?.Id_Status) > 0
            ? Number(data?.Id_Status)
            : "" ,
            Id_Type: Number(data?.Id_Type) > 0
            ? Number(data?.Id_Type)
            : "" ,
            Id_Cat: Number(data?.Id_Cat) > 0
            ? Number(data?.Id_Cat)
            : "" ,
            Id_Sous_Cat:  Number( data?.Id_Sous_Cat) > 0
            ? Number( data?.Id_Sous_Cat)
            : "" ,
            Id_Voie:  Number(data?.Id_Voie) > 0
            ? Number(data?.Id_Voie)
            : "" ,
            Id_Source: Number(data?.Id_Source),
            Sujet: data?.Sujet,
            sla: data?.sla,
            Desc_Scis: data?.Desc_Scis??"",
            Solution: data?.Solution??"",
            Commentaires: data?.Commentaires??"",
            Id_departement: Number(data?.id_departement) > 0
            ? Number(data?.id_departement)
            : "",
            Id_service: Number(data?.id_service) > 0
            ? Number(data?.id_service)
            : "",
            Id_groupe: Number(data?.Id_groupe) > 0
            ? Number(data?.Id_groupe)
            : "",
          }),
          {
            headers:{
              "Authorization": 'Bearer '+userStore.token,
              "Content-Type": "application/json"
            
            }
          }
        ).catch((e)=>{
          error=e})
      //  if (response&&response?.statusText === "OK") {
         return {data:response?.data,success:response?.statusText === "OK",err:error}
       // }
      };
