export const FETCH_CLIENT = "FETCH_CLIENT";
export const FETCH_CLIENT_ACTION = "FETCH_CLIENT_ACTION";

export const Get_CLIENT = "Get_CLIENT";
export const Get_CLIENT_Failed = "Get_CLIENT_Failed";

export const ADD_CLIENT = "ADD_CLIENT";
export const ADD_CLIENT_FAILED = "ADD_CLIENT_FAILED";


export const UPDATE_CLIENT= "UPDATE_CLIENT";
export const UPDATE_CLIENT_FAILED = "UPDATE_CLIENT_FAILED";
export const SUCCESS_CLIENT_END = "SUCCESS_CLIENT_END";
