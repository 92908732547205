import React, { useState } from "react";
import { RefreshIcon, ChatIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import { updateListTicket } from "../../../JS/Ticket/actions/TicketA";
import { TextAreaComponent } from "..";
import { useTranslation } from "react-i18next";
function Modals({ close, ticket, type,setTicket,value }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [commentaire, setCommentaire] = useState({text:"",filename:null,filesize:""});

  const [validate, setValidate] = useState(
    commentaire.length > 0 ? true : false
  );
  const { t } = useTranslation();
  const actionFunction = () => {
    if (type==="commentaire"&&commentaire.text.length === 0) {
      setValidate(true);
    } else {
      setValidate(false);


    switch (type) {

      case "relancer":
        dispatch(updateListTicket("ticket/Relance","",ticket, user.Id_Pers,true,false));
        break;
      case "assigner":
        dispatch(updateListTicket("ticket/Assigner","",ticket, user.Id_Pers,true,false));
        break;
      case "commentaire":
        dispatch(updateListTicket("commentaire/store",commentaire,ticket, user,true,false));     
        break;
      default:
        break;
    }
    setTimeout(() => {
      close(false);
      setTicket({})
    }, 100);
  }
  };

  const getIcont = () => {
    switch (type) {
      case "commentaire":
        return (
          <ChatIcon className=" w-9 h-9 mr-2 leading-6 text-green-400 font-semibold  dark:text-green-600" />
        );

      case "Relancer":
        return (
          <RefreshIcon className=" w-9 h-9 mr-2 leading-6 text-green-400 font-semibold  dark:text-green-600" />
        );
      default:
        break;
    }
  };
  //xl:max-w-3xl xl:rounded-xl  lg:rounded-lg lg:max-w-lg   sm:max-w-sm 
  return (
    <div className="shadow w-xl object-cover ">
      <div className=" bg-white rounded-lg   dark:bg-gray-800    ">
      <div className="py-5   sm:p-6">
        <div className="flex items-center py-2  p-0 m-0 justify-between">
          <div className="text-gray-500 dark:text-white capitalize font-light text-2xl flex items-center justify-center  ">
            {getIcont()}
            {/* {type === "commentaire" ? t('Ticket.AjoutCommentaire') : `${type} ticket`} */}
            {type==="commentaire" ?t('All.Commenter'):type==="relancer" ?t('Client.Relancer'):type==="assigner" ?t('Ticket.Assigner'):type} {`ticket`}
          </div>
        </div>
        <div className="grid grid-cols-6 gap-18 w-2xl  ">
          <div className=" col-span-6">
          {type !== "commentaire" && (
              <div className="flex items-center">
                
                <div className="dark:text-white text-gray-500  text-lg">
                  {/* Êtes-vous sûr de vouloir {type} le ticket avec le code */}
              {t('Ticket.vouloir')}    {type==="commentaire" ?t('All.Commenter'):type==="relancer" ?t('Client.Relancer'):type==="assigner" ?t('Ticket.Assigner'):type}   {t('Ticket.TickCode')}                    
                </div>
                <div className="font-semibold text-lg ml-1 dark:text-white  underline animate-pulse">
                  #{ticket.code_ticket}
                </div>
              </div>)}
              </div>
            <div className="col-span-6 " >
              {type === "commentaire" && (
              <div className="flex  flex-col">
                <div className="flex">
                  <div className="dark:text-white   text-gray-500  text-lg">
                  {t('Ticket.AjoutCommentaireà')} :
                
                  </div>
                  <div className="font-semibold text-lg ml-1 dark:text-white  underline animate-pulse">
                    #{ticket.code_ticket}
                  </div>
                  <div className="xl:px-28 md:px-14 sm:px-0"></div>
        

                </div>
                <div className=" mt-3">
                  <TextAreaComponent
                    setSujetValidate={(e) => setValidate(e)}
                    sujetValidate={validate}
                    type={true}
                    nom="Commentaire"
                    onChange={(e) => {
                      setCommentaire({
                        ...commentaire,
                        text:e,
                        
                      });
                    }}
                    selected={commentaire.text}
                  />

               
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="px-4  py-3 space-x-4  dark:bg-gray-800 justify-around flex sm:px-6">
          <button
            className="text-white hover:bg-gray-500 hover:translate-x-8  w-28 h-10 text-lg bg-gray-400 items-center flex justify-center py-2 px-4 border  rounded-full shadow"
            onClick={() => {
              close(false);
              setTicket({})
            }}
          >
            {t('All.Annuler')}
          </button>
          <button
            // disabled={false}
            onClick={() => actionFunction()}
            className="text-white capitalize  hover:bg-green-500 hover:translate-x-8  min-w-28 h-10 text-lg bg-green-400 items-center flex justify-center py-2 px-4 border  rounded-full shadow"

            // className="bg-indigo-600 ml-5   hover:opacity-50 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {type==="commentaire" ?t('All.Commenter'):type==="relancer" ?t('Client.Relancer'):type==="assigner" ?t('Ticket.Assigner'):type}
          </button>
         
        </div>
      </div>
    </div>
    </div>
  );
}

export default Modals;
