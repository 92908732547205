import React from "react";
import { Quill } from "react-quill";

// import "react-quill/dist/quill.snow.css";
import quillEmoji from "react-quill-emoji";
import "react-quill-emoji/dist/quill-emoji.css";
import "./editorCSS.css";
import { Button } from "antd";
import { AiOutlineBold, AiOutlineItalic, AiOutlineLink } from "react-icons/ai";
import { BsCodeSlash } from "react-icons/bs";
import { MdZoomOutMap } from "react-icons/md";
// const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

// const Emoji = Quill.import("formats/emoji");
// Emoji.whitelist = ["extra-small", "small", "medium", "large"];
// Quill.register(Emoji, true);

// Quill.register(
//   {
//     "formats/emoji": quillEmoji.EmojiBlot,
//     "modules/emoji-toolbar": quillEmoji.ToolbarEmoji,
//     "modules/emoji-textarea": quillEmoji.TextAreaEmoji,
//     "modules/emoji-shortname": quillEmoji.ShortNameEmoji,
//   },
//   true
// );

function insertlastName() {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, "Bouzaien");
}

function insertfirstName() {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, "Aymen");
}

function insertemail() {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, "a.bouzaien@comunikmail.com");
}

// insert an @ symbol which will trigger the mentions
function insertMention() {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, "@");
  this.quill.setSelection(cursorPosition + 1);
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      mention: insertMention,
      mentionfirstname: insertlastName,
      mentionlastName: insertfirstName,
      mentionemail: insertemail,
    },
  },
  // "emoji-toolbar": true,
  // "emoji-textarea": true,
  // "emoji-shortname": true,
};

const Emoji = Quill.import("ui/icons");
Emoji["emoji"] = ""; // Disable the emoji icon

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "align",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
  "emoji",
];

// Quill Toolbar component
export const QuillToolbar = ({ fullScreen, setFullScreen }) => (
  <div id="toolbar">
    {/* <span
      className="ql-formats space-x-12"
      style={{
        display: showFields ? "block" : "none",
      }}
    >
      <Button className={`ql-mentionlastName`}>Last Name</Button>
      <Button className={`ql-mentionfirstname`}>First Name</Button>
      <Button className={`ql-mentionemail`}>Email adress</Button>
    </span> */}

    <div>
      <span className="ql-formats">
        <select className="ql-header" defaultValue="3">
          <option value="1">h1</option>
          <option value="2">h2</option>
          <option value="3">Normal text</option>
        </select>
      </span>

      <span className="ql-formats">
        <button className="ql-bold">
          <AiOutlineBold className="w-4 h-4" />
        </button>
        <button className="ql-italic">
          <AiOutlineItalic className="w-4 h-4" />
        </button>
        <button className="ql-underline">
          <AiOutlineItalic className="w-4 h-4" />
        </button>
        <button className="ql-strike">
          <AiOutlineItalic className="w-4 h-4" />
        </button>
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
      </span>
      <span className="ql-formats">
        <button className="ql-blockquote" />
      </span>
      <span className="ql-formats">
        <select className="ql-color" />
        <select className="ql-align" />
        <select className="ql-background" />
      </span>
      <span className="ql-formats">
        {/* <button className="ql-link">
          <AiOutlineLink className="w-4 h-4" />
        </button> */}
        {/* <button className="ql-image" /> */}
        {/* <button className="ql-emoji" /> */}
        <button className="ql-code-block">
          <BsCodeSlash className="w-4 h-4" />
        </button>

        <button
          onClick={() => {
            setFullScreen(!fullScreen);
          }}
        >
          <span className="ql-formats">
            <MdZoomOutMap className="w-4 h-4" />
          </span>
        </button>
      </span>
    </div>
  </div>
);

export default QuillToolbar;
