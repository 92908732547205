import React, { useEffect, useState, Fragment } from "react";
import { MdAttachFile } from "react-icons/md";
import { AiOutlineCloseCircle, AiOutlineSolution } from "react-icons/ai";
import { CgSpinner } from "react-icons/cg";
import Table, {
  AvatarCell,
  StatusPill,
  CharacterSLA,
  Character,
  MomentCell,
  NonAssign,
  CellType,
  CharacterCLient,
  SévPill,
} from "./component/sideBar/Table";
import classNames from "classnames";
import { ToolTip } from "./component/listeTickets/utils";
import FormTicket from "./component/listeTickets/utils/modals/FormTicket";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axiosInstance from "./component/JS/axiosInstance";

const FormulaireContact = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [token, setToken] = useState("");
  const [messageApi, setMessageApi] = useState("");

  const [listSujet, setListSujet] = useState([]);
  const [sujetInput, setSujetInput] = useState(false);
  // const [formvalue, setFormvalue]= useState({ nom:'',prenom:'',email:'',message:'',sujet:''});
  const [formerror, setFormerror] = useState({});
  const [issubmit, setSubmit] = useState(false);

  const [email, setEmail] = useState("");
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [message, setMessage] = useState("");
  const [sujet, setSujet] = useState("");
  const [numTelephone, setNumTelephone] = useState("");
  const [loadingdataRechercheTel, setLoadingdataRechercheTel] = useState(false);
  const [datatype, setDataType] = useState([]);
  const [dataFlux, setDataFlux] = useState([]);
  const [dataStatut, setDataStatut] = useState([]);
  const [dataSeverite, setDataSeverite] = useState([]);
  const [dataDivision, setDataDivision] = useState([]);
  const [dataDepartment, setDataDepartment] = useState([]);
  const [dataService, setDataService] = useState([]);
  const [dataGroupe, setDataGroupe] = useState([]);

  const [flux, setFlux] = useState(1);
  const [statut, setStatut] = useState(1);
  const [severite, setSeverite] = useState(2);
  const [description, setDescription] = useState("");
  const [solution, setSolution] = useState("");
  const [commentaire, setCommentaire] = useState("");

  const [departmentId, setDepartmentId] = useState("");
  const [ServiceId, setServiceId] = useState("");
  const [groupeId, setGroupeId] = useState("");

  const [numEnregistrement, setNumEnregistrement] = useState("");
  const [plaqueImmatriculation, setPlaqueImmatriculation] = useState("");
  const [numPermis, setNumPermis] = useState("");
  const [numDemande, setNumDemande] = useState("");
  const [dateNaissance, setDateNaissance] = useState("");
  const [numLiquidation, setNumLiquidation] = useState("");

  const [dataHistorique, setDataHistorique] = useState([]);
  const [dataRechercheClient, setDataRechercheClient] = useState([]);
  const [loadSujet, setLoadSujet] = useState(false);

  const [typeId, setTypeId] = useState("");
  const [emptydata, setEmptydata] = useState(false);

  const [division, setDivision] = useState([]);

  // const [identifiantUnique, setIdentifiantUnique] = useState(0);
  const [codeClient, setClient] = useState("");
  const [notifSucces, setNotifSucces] = useState(false);

  const [ticketInfo, setTicketINfo] = useState({});
  const [showModal1, setShowModal1] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const [uploadFile, setUploadFile] = useState({
    file: "",
    filename: "",
    filesize: "",
    filetype: "",
  });

  const [loading, setLoading] = useState(false);

  // const RechercheMail = () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", "Bearer " + token);

  //   var formdata = new FormData();
  //   formdata.append("email", email);

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: formdata,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     `${process.env.REACT_APP_BASE_URL}Client/recherche_byemail`,
  //     requestOptions
  //   )
  //     .then((response) => response.text())
  //     .then((result) => {
  //       if (result) {
  //         console.log("recherchemail", JSON.stringify(result));
  //         CreationTicket(result);
  //       } else {
  //         CreationClient(nom, prenom, email);
  //       }
  //     })
  //     .catch((error) => console.log("error", error));
  // };
  //on submit dans cette function verifier reetour recherche_bytel si exite donc creer ticket sinon on va creer un nv client
  // const RechercheTel = () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", "Bearer " + token);

  //   var formdata = new FormData();
  //   formdata.append("Tel", numTelephone);

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: formdata,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     `${process.env.REACT_APP_BASE_URL}Client/recherche_bytelrmc`, // a remplacer par recherche_bytel
  //     requestOptions
  //   )
  //     .then((response) => response.text())
  //     .then((result) => {
  //       if (result) {
  //         console.log("recherche_bytelrmc", JSON.stringify(result));
  //         CreationTicket(result); //result codeclient / id_pers
  //       } else {
  //         CreationClientTel(nom, prenom, numTelephone);
  //       }
  //     })
  //     .catch((error) => console.log("error", error));
  // };

  // const CreationClient = (nom, prenom, email) => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", "Bearer " + token);

  //   var formdata = new FormData();

  //   formdata.append("Nom", nom);
  //   formdata.append("Prenom", prenom);
  //   formdata.append("CIN", "0");
  //   formdata.append("Email", email);
  //   formdata.append("tel", numTelephone);
  //   formdata.append("username", email);
  //   formdata.append("password", email);
  //   formdata.append("RS", nom + " " + prenom);
  //   formdata.append("id", "1");

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: formdata,
  //     redirect: "follow",
  //   };

  //   fetch(`${process.env.REACT_APP_BASE_URL}Client/storeRapide`, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result) {
  //         setClient(result.code_client);
  //         CreationTicket(result.code_client);
  //       }
  //     })

  //     .catch((error) => console.log("error", error));
  // };

  const CreationTicket = (result, Id_Pers) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formData = new FormData();

    if (typeId === "1") {
      formData.append("num_enregistrement", numEnregistrement);
      formData.append("division", division);
    } else if (typeId === "2") {
      formData.append("plaque_matriculation", plaqueImmatriculation);
      formData.append("num_permis_conduire", numPermis);
      formData.append("num_demande_forma", numDemande);
      formData.append("date_naissance", dateNaissance);
      formData.append("num_liquidation", numLiquidation);
    }

    formData.append("num_requerant", numTelephone);
    formData.append(
      "code_client",
      dataRechercheClient.length > 0
        ? dataRechercheClient[0].code_client
        : result
    );
    formData.append("Flux", flux);
    formData.append(
      "Id_Pers",
      dataRechercheClient.length > 0 ? dataRechercheClient[0].Id_Pers : Id_Pers
    );
    formData.append("Id_Priorite", severite);
    formData.append("Id_Status", statut);
    formData.append("Id_Cat", "");
    formData.append("Id_Type", typeId);
    formData.append("Id_Sous_Cat", "");
    formData.append("Id_Voie", "3");
    formData.append("Id_Source", "");
    formData.append("Sujet", sujet);
    formData.append("sla", "");
    formData.append("Desc_Scis", description);
    formData.append("Solution", solution);
    formData.append("Commentaires", commentaire);
    formData.append("Id_departement", departmentId);
    formData.append("Id_service", ServiceId);
    formData.append("Id_groupe", groupeId);
    formData.append("type", typeId);
    formData.append("filename", uploadFile.filename);
    formData.append("filesize", uploadFile.filesize);
    formData.append("file", uploadFile.uri);
    formData.append("uri", uploadFile.uri);
    // formData.append("id", dataRechercheClient[0].Id_Pers ?? Id_Pers);
    // formData.append("code_client", result);
    // formData.append("Id_Priorite", "1");
    // formData.append("Id_Status", "1");

    // formData.append("Sujet", sujet);
    // formData.append("sla", "");
    // formData.append("Desc_Scis", message);
    // formData.append("Commentaires", "");
    // formData.append("file", uploadFile.file);
    // formData.append("type", uploadFile.filetype);
    // formData.append("filename", uploadFile.filename);
    // formData.append("filesize", uploadFile.filesize);
    // formData.append("id", Id_Pers);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };
    let s;
    fetch(`${process.env.REACT_APP_BASE_URL}ticket/add`, requestOptions)
      .then((response) => {
        s = response.status;
        return response.json();
      })
      .then((result) => {
        if ((s = 200)) {
          HistoriqueTicket();
          setMessageApi("Merci de bien vouloir consulter votre boite e-mail");
          setNom("");
          setPrenom("");
          setStatut(1);
          setSeverite(2);
          setFlux(1);
          setNumTelephone("");
          setSujet("");
          setDescription("");
          setSolution("");
          setCommentaire("");
          setDivision("");
          setNumEnregistrement("");
          setServiceId("");
          setGroupeId("");

          setPlaqueImmatriculation("");
          setNumPermis("");
          setNumDemande("");
          setDateNaissance("");
          setNumLiquidation("");

          setUploadFile({
            filename: "",
            filetype: "",
            filesize: 0,
            file: null,
            preview: null,
          });
          setLoading(false);
          setTimeout(() => {
            setMessageApi(false);
          }, 6000);

          setTimeout(() => {
            setNotifSucces(true);
          }, 3000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const CreationClientTel = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();

    formdata.append("Nom", nom);
    formdata.append("Prenom", prenom);
    formdata.append("CIN", "0");
    formdata.append("Email", numTelephone);
    formdata.append("tel", numTelephone);
    formdata.append("username", nom + " " + prenom);
    formdata.append("password", nom + " " + prenom);
    formdata.append("RS", nom + " " + prenom);
    formdata.append("id", "1");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}Client/storeRapide`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("ressss", result);
        // setClient(result.code_client);
        CreationTicket(result.code_client, result.Id_Pers);
        console.log("succesfuly");
      })

      .catch((error) => console.log("error", error));
  };
  //code client + id pers

  const Login = () => {
    var formdata = new FormData();
    formdata.append("username", "client@gmail.com");
    formdata.append("password", "test2020");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}login`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setToken(result.data.token);
      })
      .catch((error) => console.log("error", error));
  };

  //   const Sujet = (id) => {
  //     var myHeaders = new Headers();
  //     myHeaders.append("Authorization", "Bearer " + token);

  //     var requestOptions = {
  //       method: "GET",
  //       headers: myHeaders,
  //       redirect: "follow",
  //     };

  //     fetch(`${process.env.REACT_APP_BASE_URL}sujet/type/${id}`, requestOptions)
  //       .then((response) => response.json())
  //       .then((result) => setListSujet(result))
  //       .catch((error) => console.log("error", error));
  //   };

  const Sujet = () => {
    axiosInstance
      .get("sujet")
      .then((result) => {
        setListSujet(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  const GetType = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}Type`, requestOptions)
      .then((response) => response.json())
      .then((result) => setDataType(result))
      .catch((error) => console.log("error", error));
  };

  const GetEtat = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_BASE_URL + "Flux", requestOptions)
      .then((response) => response.json())
      .then((result) => setDataFlux(result))
      .catch((error) => console.log("error", error));
  };

  const GetStatut = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_BASE_URL + "Status", requestOptions)
      .then((response) => response.json())
      .then((result) => setDataStatut(result))
      .catch((error) => console.log("error", error));
  };

  const GetSeverite = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_BASE_URL + "Priorite", requestOptions)
      .then((response) => response.json())
      .then((result) => setDataSeverite(result))
      .catch((error) => console.log("error", error));
  };

  const GetDivision = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_BASE_URL + "Division", requestOptions)
      .then((response) => response.json())
      .then((result) => setDataDivision(result))
      .catch((error) => console.log("error", error));
  };

  const GetDepartement = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_BASE_URL + "departement", requestOptions)
      .then((response) => response.json())
      .then((result) => setDataDepartment(result))
      .catch((error) => console.log("error", error));
  };

  const GetService = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + `Service_departement/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setDataService(result))
      .catch((error) => console.log("error", error));
  };

  const GetGroupe = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_BASE_URL + `groupe/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => setDataGroupe(result))
      .catch((error) => console.log("error", error));
  };
  const HistoriqueTicket = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("tel", numTelephone);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "ticket/Recherchehistorique",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setDataHistorique(result))
      .catch((error) => console.log("error", error));
  };

  const RechercheByTel = () => {
    setLoadingdataRechercheTel(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("Tel", numTelephone);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "Client/recherche_bytel",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoadingdataRechercheTel(false);

        setDataRechercheClient(result);
        console.log("res length", result.length);
        if (result.length === 0) {
          setEmptydata(true);
        }
        if (result[0].Nom.length > 0 && result[0].Prenom.length > 0) {
          setNom(result[0].Nom);
          setPrenom(result[0].Prenom);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    setFormerror(
      validationform({ flux, statut, severite, typeId, sujet, numTelephone })
    );
    setSubmit(true);
  };

  const validationform = (value) => {
    const errors = {};
    const emailPattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const numPattern = /^\+?[0-9]{8,15}$/;
    if (!value.flux) {
      errors.flux = "Ce champ est obligatoire";
    }
    if (!value.statut) {
      errors.statut = "Ce champ est obligatoire";
    }
    if (!value.severite) {
      errors.severite = "Ce champ est obligatoire";
    }
    if (!value.typeId) {
      errors.typeId = "Ce champ est obligatoire";
    }
    if (!value.sujet) {
      errors.sujet = "Ce champ est obligatoire";
    }

    if (!value.numTelephone) {
      errors.numTelephone = "Ce champ est obligatoire";
    }

    return errors;
  };

  const getTokenFromUrl = () => {
    const url_string = window.location.href;
    var url = new URL(url_string);
    setNom(url.searchParams.get("nom"));
    setPrenom(url.searchParams.get("prenom"));
    setEmail(url.searchParams.get("email"));
    setNumTelephone(url.searchParams.get("tel"));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Actions",
        accessor: (props) => (
          <>
            {user ? (
              <div
                className={classNames(
                  "text-gray-700",
                  "block px-4 py-2 text-sm  hover:text-blue-300 hover:cursor-pointer"
                )}
                onClick={() => {
                  setShowModal1(true);
                  setTicketINfo(props);
                }}
              >
                <ToolTip value="Consulter" type="Modifier" color="gray" />
              </div>
            ) : (
              <ToolTip
                value="Vous devez connecter au HelpDesk pour avoir la possibilité de modifier le ticket"
                type="warningTicket"
                color="gray"
              />
            )}
          </>
        ),
      },
      {
        Header: "Identifiant",
        accessor: "code_ticket",
        Cell: (props) => AvatarCell(props, dataHistorique, setDataHistorique),
      },

      {
        Header: "Status",
        accessor: "statut",
        Cell: StatusPill,
        // Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "SLA",
        accessor: "etat_sla",
        Cell: CharacterSLA,
      },
      {
        Header: "Sujet",
        accessor: "Sujet",
        Cell: Character,
      },
      {
        Header: "Date création",
        accessor: "date_creation",
        Cell: MomentCell,
      },
      {
        Header: "Agent",
        accessor: "agent",
        Cell: NonAssign,
      },

      {
        Header: "Type",
        accessor: "type",
        Cell: CellType,
      },
      {
        Header: "Tel",
        accessor: "num_requerant",
        // Cell: CellType,
      },
      {
        Header: "Client",
        accessor: "allclient",
        Cell: CharacterCLient,
      },

      {
        Header: "Sévérité",
        accessor: "severite",
        Cell: SévPill,
      },
      {
        Header: "Groupe",
        accessor: "groupe",
        //Cell: SévPill,
      },
    ],
    [dataHistorique]
  );

  useEffect(() => {
    if (Object.keys(formerror).length === 0 && issubmit) {
      if (dataRechercheClient.length > 0) CreationTicket();
      else {
        CreationClientTel();
      }
      //RechercheMail()
      // RechercheTel();
      setLoading(true);
    }
  }, [formerror, issubmit]);

  useEffect(() => {
    getTokenFromUrl();
    Login();
  }, []);

  useEffect(() => {
    if (token) {
      Sujet();
      GetEtat();
      GetStatut();
      GetSeverite();
      HistoriqueTicket();
      RechercheByTel();
      GetType();
      GetDivision();
      GetDepartement();
    }
  }, [token]);

  return (
    <div className="flex  items-center justify-center">
      <div className=" w-2/3 pt-6 sm:pt-0 rounded-lg   ">
        <form onSubmit={handlesubmit}>
          <div className=" ">
            <div className="bg-white py-4 px-4 border-blue-300 border-2  sm:p-6 mt-8 shadow-2xl rounded-2xl">
              {!loadingdataRechercheTel &&
              dataRechercheClient[0]?.client.length > 0 ? (
                <div className="flex items-center">
                  <AiOutlineSolution className="w-7 h-7" />
                  <h3 className="text-lg leading-6  text-gray-700 flex justify-start">
                    Fiche Client
                  </h3>
                </div>
              ) : !loadingdataRechercheTel &&
                (emptydata ||
                  // dataRechercheClient.length === 0 ||
                  dataRechercheClient[0]?.client === "Null Null") ? (
                <h3 className="text-lg leading-6  text-red-600 flex justify-start">
                  Numéro de téléphone non reconnu!
                </h3>
              ) : (
                <p></p>
              )}

              <div className="flex flex-col justify-center container mx-auto items-center">
                <h2 className="text-xl leading-6 font-bold text-blue-500 flex justify-center">
                  {!loadingdataRechercheTel &&
                  dataRechercheClient[0]?.client.length > 0 ? (
                    dataRechercheClient[0]?.client +
                    "[" +
                    dataRechercheClient[0]?.code_client +
                    "]"
                  ) : !loadingdataRechercheTel &&
                    // dataRechercheClient.length === 0 ? (
                    emptydata ? (
                    numTelephone
                  ) : (
                    <p></p>
                  )}
                </h2>
              </div>
              {messageApi.length > 0 ? (
                <div className=" mt-4 animate-bounce flex justify-center">
                  <p className="text-xl text-green-500  ">
                    Votre demande de réclamation a été créé avec succès
                  </p>
                </div>
              ) : null}

              <div className="mt-2">
                <div className="grid grid-cols-4 gap-4 calcH ">
                  <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                    <label className="block  text-sm font-medium text-gray-700">
                      Nom
                    </label>
                    <textarea
                      disabled={
                        !loadingdataRechercheTel &&
                        dataRechercheClient[0]?.client.length > 0
                      }
                      value={nom}
                      onChange={(e) => setNom(e.target.value)}
                      placeholder="Veuillez saisir le nom"
                      type="text"
                      autocomplete="off"
                      className={
                        !loadingdataRechercheTel &&
                        dataRechercheClient[0]?.client.length > 0
                          ? "bg-gray-200 cursor-not-allowed mt-3 block w-full h-10 border-2 border-blue-400  rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          : "bg-white mt-3 block w-full h-10 border-2 border-blue-400  rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      }
                    />
                  </div>

                  <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                    <label className="block  text-sm font-medium text-gray-700">
                      Prénom
                    </label>
                    <textarea
                      disabled={
                        !loadingdataRechercheTel &&
                        dataRechercheClient[0]?.client.length > 0
                      }
                      value={prenom}
                      onChange={(e) => setPrenom(e.target.value)}
                      placeholder="Veuillez saisir le prénom"
                      type="text"
                      autocomplete="off"
                      className={
                        !loadingdataRechercheTel &&
                        dataRechercheClient[0]?.client.length > 0
                          ? "bg-gray-200 cursor-not-allowed mt-3 block w-full h-10 border-2 border-blue-400  rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          : "bg-white mt-3 block w-full h-10 border-2 border-blue-400  rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      }
                    />
                  </div>
                  <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                    <label className="block dark:text-white text-sm font-medium text-gray-700">
                      Périmètre *
                    </label>

                    <select
                      className=" mt-3 block w-full  py-2 text-base border-2 border-blue-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      onChange={(e) => setFlux(e.target.value)}
                      value={flux}
                    >
                      <option value="">Choisir l'état</option>

                      {dataFlux?.map((post, i) => (
                        <option
                          key={i}
                          value={post.id_flux}
                          className=" text-gray-700  origin-top-right absolute overflow-y-scroll overflow-x-hidden h-28 right-0 dark:bg-gray-700 dark:text-white mt-2 w-36 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {post.l_flux}
                        </option>
                      ))}
                    </select>

                    {formerror.flux && (
                      <div className=" ml-2 mt-2 ">
                        <p className="text-red-500  ">{formerror.flux}</p>
                      </div>
                    )}
                  </div>

                  <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                    <label className="block dark:text-white text-sm font-medium text-gray-700">
                      Statut *
                    </label>

                    <select
                      className=" mt-3 block w-full  py-2 text-base border-2 border-blue-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      onChange={(e) => setStatut(e.target.value)}
                      value={statut}
                    >
                      <option value="">Choisir le statut</option>

                      {dataStatut?.map((post, i) => (
                        <option
                          key={i}
                          value={post.Id_Status}
                          className=" text-gray-700  origin-top-right absolute overflow-y-scroll overflow-x-hidden h-28 right-0 dark:bg-gray-700 dark:text-white mt-2 w-36 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {post.L_Status}
                        </option>
                      ))}
                    </select>

                    {formerror.statut && (
                      <div className=" ml-2 mt-2 ">
                        <p className="text-red-500  ">{formerror.statut}</p>
                      </div>
                    )}
                  </div>

                  <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                    <label className="block dark:text-white text-sm font-medium text-gray-700">
                      Sévérité *
                    </label>

                    <select
                      className=" mt-3 block w-full  py-2 text-base border-2 border-blue-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      onChange={(e) => setSeverite(e.target.value)}
                      value={severite}
                    >
                      <option value="">Choisir la sévérité</option>

                      {dataSeverite?.map((post, i) => (
                        <option
                          key={i}
                          value={post.Id_Priorite}
                          className=" text-gray-700  origin-top-right absolute overflow-y-scroll overflow-x-hidden h-28 right-0 dark:bg-gray-700 dark:text-white mt-2 w-36 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {post.L_Priorite}
                        </option>
                      ))}
                    </select>

                    {formerror.severite && (
                      <div className=" ml-2 mt-2 ">
                        <p className="text-red-500  ">{formerror.severite}</p>
                      </div>
                    )}
                  </div>

                  <div className="col-span-4 sm:col-span-2 lg:col-span-2  ">
                    <label className="block  text-sm font-medium text-gray-700">
                      Numéro du requérant *
                    </label>
                    <input
                      value={numTelephone}
                      onChange={(e) => setNumTelephone(e.target.value)}
                      placeholder="Veuillez saisir l'identifiant"
                      type="text"
                      autocomplete="off"
                      className=" mt-3 block w-full border-2 border-blue-400  rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />

                    {formerror.numTelephone && (
                      <div className="ml-2r mt-2 ">
                        <p className="text-red-500  ">
                          {formerror.numTelephone}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                    <label className="block dark:text-white text-sm font-medium text-gray-700">
                      Type de requête *
                    </label>

                    <select
                      className=" mt-3 block w-full  py-2 text-base border-2 border-blue-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      onChange={(e) => {
                        Sujet(e.target.value);
                        setTypeId(e.target.value);
                        setSujet("");
                      }}
                      value={typeId}
                    >
                      <option value="">Choisir le type</option>

                      {datatype?.map((post, i) => (
                        <option
                          key={i}
                          value={post.Id_Type}
                          className=" text-gray-700  origin-top-right absolute overflow-y-scroll overflow-x-hidden h-28 right-0 dark:bg-gray-700 dark:text-white mt-2 w-36 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {post.L_Type}
                        </option>
                      ))}
                    </select>

                    {formerror.typeId && (
                      <div className=" ml-2 mt-2 ">
                        <p className="text-red-500  ">{formerror.typeId}</p>
                      </div>
                    )}
                  </div>

                  <div className="col-span-4 sm:col-span-2 lg:col-span-2"></div>

                  <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                    <label className="block dark:text-white text-sm font-medium text-gray-700">
                      Sujet *
                    </label>

                    <select
                      className=" mt-3 block w-full  py-2 text-base border-2 border-blue-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      onChange={(e) => {
                        if (e.target.value === "Autres") {
                          setSujet(e.target.value);
                          setSujetInput(true);
                        } else {
                          setSujetInput(false);
                          setSujet(e.target.value);
                        }
                      }}
                      value={sujet}
                    >
                      <option>Choisir le sujet</option>
                      {loadSujet ? (
                        <option>loading...</option>
                      ) : (
                        <>
                          {listSujet?.map((post, i) => (
                            <option
                              key={i}
                              value={post.sujet}
                              className=" text-gray-700  origin-top-right absolute overflow-y-scroll overflow-x-hidden h-28 right-0 dark:bg-gray-700 dark:text-white mt-2 w-36 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              {post.sujet}
                            </option>
                          ))}
                        </>
                      )}
                    </select>

                    {formerror.sujet && (
                      <div className=" ml-2 mt-2 ">
                        <p className="text-red-500  ">{formerror.sujet}</p>
                      </div>
                    )}
                  </div>

                  {sujetInput ? (
                    <>
                      <div className="col-span-4 sm:col-span-2 lg:col-span-4">
                        <label className="block  text-sm font-medium text-gray-700">
                          Autre sujet *
                        </label>
                        <input
                          // value={formvalue.sujet}
                          onChange={(e) => setSujet(e.target.value)}
                          placeholder="Veuillez saisir l'objet"
                          type="text"
                          autocomplete="off"
                          className=" mt-3 block w-full border-2 border-blue-400  rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />

                        {formerror.sujet && (
                          <div className=" ml-2 mt-2 ">
                            <p className="text-red-500">{formerror.sujet}</p>
                          </div>
                        )}
                      </div>
                    </>
                  ) : null}

                  {typeId.length > 0 ? (
                    <>
                      <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                        <label className="block  text-sm font-medium text-gray-700">
                          Description
                        </label>
                        <textarea
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder="Veuillez saisir la description"
                          type="text"
                          autocomplete="off"
                          className=" mt-3 block w-full border-2 border-blue-400  rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                        <label className="block  text-sm font-medium text-gray-700">
                          Intervention / Solution
                        </label>
                        <textarea
                          value={solution}
                          onChange={(e) => setSolution(e.target.value)}
                          placeholder="Veuillez saisir la solution"
                          type="text"
                          autocomplete="off"
                          className=" mt-3 block w-full border-2 border-blue-400  rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                        <label className="block  text-sm font-medium text-gray-700">
                          Commentaire
                        </label>
                        <textarea
                          value={commentaire}
                          onChange={(e) => setCommentaire(e.target.value)}
                          placeholder="Veuillez saisir le commentaire"
                          type="text"
                          autocomplete="off"
                          className=" mt-3 block w-full border-2 border-blue-400  rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </>
                  ) : null}

                  {typeId === "1" ? (
                    <>
                      <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                        <label className="block dark:text-white text-sm font-medium text-gray-700">
                          Division ou structure incriminée
                        </label>

                        <select
                          //id={sujet.id}
                          className=" mt-3 block w-full  py-2 text-base border-2 border-blue-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          onChange={(e) => {
                            setDivision(e.target.value);
                          }}
                          value={division}
                        >
                          <option>
                            Choisir le division ou structure incriminée
                          </option>

                          {dataDivision?.map((post, i) => (
                            <option
                              key={i}
                              value={post.id}
                              className=" text-gray-700  origin-top-right absolute overflow-y-scroll overflow-x-hidden h-28 right-0 dark:bg-gray-700 dark:text-white mt-2 w-36 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              {post.libelle}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                        <label className="block  text-sm font-medium text-gray-700">
                          Numéro d'enregistrement
                        </label>
                        <textarea
                          value={numEnregistrement}
                          onChange={(e) => setNumEnregistrement(e.target.value)}
                          placeholder="Veuillez saisir le numéro d'enregistrement"
                          type="text"
                          autocomplete="off"
                          className=" mt-3 block w-full h-10 border-2 border-blue-400  rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </>
                  ) : typeId === "2" ? (
                    <>
                      <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                        <label className="block  text-sm font-medium text-gray-700">
                          Plaque d'immatriculation
                        </label>
                        <textarea
                          value={plaqueImmatriculation}
                          onChange={(e) =>
                            setPlaqueImmatriculation(e.target.value)
                          }
                          placeholder="Veuillez saisir la Plaque d'immatriculation"
                          type="text"
                          autocomplete="off"
                          className=" mt-3 block w-full h-10 border-2 border-blue-400  rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                        <label className="block  text-sm font-medium text-gray-700">
                          Numéro permis de conduire
                        </label>
                        <textarea
                          value={numPermis}
                          onChange={(e) => setNumPermis(e.target.value)}
                          placeholder="Veuillez saisir le Numéro"
                          type="text"
                          autocomplete="off"
                          className=" mt-3 block w-full h-10 border-2 border-blue-400  rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                        <label className="block  text-sm font-medium text-gray-700">
                          Numéro de demande(sol) de la formalité
                        </label>
                        <textarea
                          value={numDemande}
                          onChange={(e) => setNumDemande(e.target.value)}
                          placeholder="Veuillez saisir le Numéro de demande"
                          type="text"
                          autocomplete="off"
                          className=" mt-3 block w-full h-10 border-2 border-blue-400  rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-6  lg:col-span-2">
                        <label className="block dark:text-white text-sm font-medium text-gray-700">
                          Date de naissance
                        </label>
                        <input
                          type="date"
                          value={dateNaissance}
                          onChange={(e) => {
                            setDateNaissance(e.target.value);
                          }}
                          className="mt-3 block h-10 dark:bg-gray-800 dark:text-white w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                        <label className="block  text-sm font-medium text-gray-700">
                          Numéro de la liquidation
                        </label>
                        <textarea
                          value={numLiquidation}
                          onChange={(e) => setNumLiquidation(e.target.value)}
                          placeholder="Veuillez saisir le message"
                          type="text"
                          autocomplete="off"
                          className=" mt-1 block w-full h-10 border-2 border-blue-400  rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </>
                  ) : null}

                  <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                    <label className="block dark:text-white text-sm font-medium text-gray-700">
                      Département concerné
                    </label>

                    <select
                      className=" mt-3 block w-full  py-2 text-base border-2 border-blue-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      onChange={(e) => {
                        GetService(e.target.value);
                        setDepartmentId(e.target.value);
                      }}
                      value={departmentId}
                    >
                      <option>Choisir le département</option>

                      {dataDepartment?.map((post, i) => (
                        <option
                          key={i}
                          value={post.Id_Dep}
                          className=" text-gray-700  origin-top-right absolute overflow-y-scroll overflow-x-hidden h-28 right-0 dark:bg-gray-700 dark:text-white mt-2 w-36 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {post.L_Dep}
                        </option>
                      ))}
                    </select>
                    {/* 
                    {formerror.departmentId && (
                      <div className=" ml-2 mt-2 ">
                        <p className="text-red-500  ">
                          {formerror.departmentId}
                        </p>
                      </div>
                    )} */}
                  </div>

                  <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                    <label className="block dark:text-white text-sm font-medium text-gray-700">
                      Service concerné
                    </label>

                    <select
                      //id={sujet.id}
                      className=" mt-3 block w-full  py-2 text-base border-2 border-blue-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      onChange={(e) => {
                        GetGroupe(e.target.value);
                        setServiceId(e.target.value);
                      }}
                      value={ServiceId}
                    >
                      <option>Choisir le service</option>

                      {dataService?.map((post, i) => (
                        <option
                          key={i}
                          value={post.Id_Service}
                          className=" text-gray-700  origin-top-right absolute overflow-y-scroll overflow-x-hidden h-28 right-0 dark:bg-gray-700 dark:text-white mt-2 w-36 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {post.L_Service}
                        </option>
                      ))}
                    </select>

                    {/* {formerror.ServiceId && (
                      <div className=" ml-2 mt-2 ">
                        <p className="text-red-500  ">{formerror.ServiceId}</p>
                      </div>
                    )} */}
                  </div>

                  <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                    <label className="block dark:text-white text-sm font-medium text-gray-700">
                      Groupe concerné
                    </label>

                    <select
                      className=" mt-3 block w-full  py-2 text-base border-2 border-blue-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      onChange={(e) => {
                        setGroupeId(e.target.value);
                      }}
                      value={groupeId}
                    >
                      <option>Choisir le groupe</option>

                      {dataGroupe?.map((post, i) => (
                        <option
                          key={i}
                          value={post.id_groupe}
                          className=" text-gray-700  origin-top-right absolute overflow-y-scroll overflow-x-hidden h-28 right-0 dark:bg-gray-700 dark:text-white mt-2 w-36 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {post.libelle_groupe}
                        </option>
                      ))}
                    </select>

                    {/* {formerror.groupeId && (
                      <div className=" ml-2 mt-2 ">
                        <p className="text-red-500  ">{formerror.groupeId}</p>
                      </div>
                    )} */}
                  </div>

                  <div className="w-full col-span-4  flex items-center justify-start ">
                    <label
                      className="bg-gray-400 h-10  w-32 items-center  rounded-full shadow
           capitalize cursor-pointer text-lg  hover:bg-gray-300  border border-transparent  inline-flex justify-center  font-medium text-white "
                    >
                      <MdAttachFile size={20} className="text-white mr-1" />

                      <div className="text-sm ">Piéce jointe</div>
                      <input
                        accept="image/*,application/pdf"
                        type="file"
                        className="hidden"
                        onChange={(e) => {
                          const reader = new FileReader();
                          const file = e.target.files[0];
                          console.log(file);

                          reader.onload = function () {
                            // if (file.size > 4e6) {
                            //   window.alert(
                            //     "Please upload a file smaller than 4 MB"
                            //   );
                            //   return false;
                            // }
                            setUploadFile({
                              filename: file.name,
                              filetype: file.type,
                              filesize: file.size,
                              file,
                              preview: reader.result,
                            });
                          };
                          reader.onerror = function () {
                            setUploadFile({
                              filename: "",
                              filetype: "",
                              filesize: 0,
                              file: null,
                              preview: null,
                            });
                          };
                          reader.readAsDataURL(file);
                        }}
                      />
                    </label>
                    {uploadFile && uploadFile?.filetype?.includes("image") ? (
                      <img
                        src={uploadFile.preview}
                        className="h-16 w-32 object-contain hover:shadow-md  "
                        alt="upload_file"
                      />
                    ) : (
                      <div className="ml-2 italic max-w-16 underline font-light overflow-x-visible">
                        {uploadFile?.filename}
                      </div>
                    )}
                    {uploadFile?.filename && (
                      <AiOutlineCloseCircle
                        size={25}
                        onClick={() =>
                          setUploadFile({
                            filename: "",
                            filetype: "",
                            filesize: 0,
                            file: null,
                            preview: null,
                          })
                        }
                        className=" text-red-400   h-5 w-5 over:opacity-50 cursor-pointer "
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="flex justify-center ">
                <button
                  style={{ backgroundColor: "#1f999e" }}
                  // onClick={()=>{
                  //   setLoading(true)
                  //   RechercheMail()
                  //   }}
                  disabled={loading}
                  className="w-40 h-12 bg-blue-500 rounded-full mt-4 text-white "
                >
                  {loading ? (
                    <div className="flex  justify-center  items-center w-40 h-12  bg-green-800 rounded-full ">
                      <div className="animate-spin">
                        <CgSpinner size={30} color="white" />
                      </div>
                    </div>
                  ) : (
                    <span>Envoyer demande</span>
                  )}
                </button>
              </div>
              <div className=" px-3  mb-1 mt-8 col-span-6 overflow-x-auto lg:overflow-x-visible over    z-20 space-x-4  dark:bg-gray-700 justify-around  sm:px-6">
                <div className="font-medium text-center text-2xl text-gray-600  dark:text-white">
                  Historique du ticket
                </div>
                <div className="max-h-52 py-2 overflow-y-auto overflow-x-hidden w-full">
                  <Table
                    idD={0}
                    size={Object}
                    setSize={() => {}}
                    columns={columns}
                    data={dataHistorique ?? []}
                    // loadingg={loadingDataC}
                  />
                </div>
              </div>
              {showModal1 && (
                <div className="  flex-grow   ">
                  <div className="overflow-y-auto inset-y-14  inset-x-1/4 bottom-2 xl:-top-8  xl:bottom-2 fixed w-full  z-40 outline-none focus:outline-none">
                    <div className="my-6 mx-auto">
                      <div
                        className="dark:bg-gray-900 rounded-lg shadow-lg mt-16  
              flex flex-col w-8/12 lg:w-7/12 xl:w-7/12 2xl:w-7/12 bg-white outline-none focus:outline-none"
                      >
                        <FormTicket
                          general={false}
                          close={setShowModal1}
                          dataSelected={ticketInfo}
                          setDataSelected={setTicketINfo}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-30 bg-black" />
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormulaireContact;
