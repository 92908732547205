import { useRef, useState } from "react";
import axios from "axios";
import { CSVLink } from "react-csv";
import { CgSpinner } from "react-icons/cg";
import Datech from "../date/Datech";
import { classNames } from "../shared/Utils";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";
import axiosInstance from "../JS/axiosInstance";
function ExportModal({ type, stauts, headers, setExportModal }) {
  const [data, setData] = useState([]);
  const [dateD, setDateD] = useState("");
  const [typeE, setTypeE] = useState("CSV");

  const [clicked, setClicked] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [dateF, setDateF] = useState("");
  const { user } = useSelector((state) => state.auth);
  const [delimiteur, setDelimiteur] = useState(";");
  const { t } = useTranslation();
  const csvlink = useRef(null);
  var array = [];
  const getURL = () => {
    switch (type) {
      case "ticket":
        return "ticket/Recherche_simple";
      case "client":
        return "Client/RechercheParcode/0";
      default:
        break;
    }
  };

  const headersPDF = [
    "code_ticket",
    "statut",
    "Sujet",
    "sla",
    "agent",
    "date_creation",
    "client",
    "type",
    "voie",
    "groupe",
    "service",
    "severite",
  ];
  const headersPDFClient = [
    "code_client",
    "Prenom",
    "Nom",
    "Email",
    "Tel",
    "code",
    "RS",
    "Tel_Etpse",
    "Email_Etpse",
    "Adresse_Etpse",
  ];

  const getData = async () => {
    var doc = new jsPDF("l", "px", "A3");

    var formdata = new FormData();
    if (type === "ticket") {
      formdata.append("date_deb", dateD);
      formdata.append("date_fin", dateF);
      formdata.append("client", "");
      formdata.append("type", "");
      formdata.append("priorite", "");
      formdata.append("flux", "");
      formdata.append("statut", stauts ?? "");
    }
    {
      formdata.append("code_client", "");
      formdata.append("Nom", "");
      formdata.append("Prenom", "");
    }

    try {
      const response = await axiosInstance.post(`${getURL()}`, formdata);
      if (response.status !== 200 || response.data.length !== 0) {
        if (typeE === "PDF") {
          response.data.map((r) => {
            if (type === "ticket") {
              array.push([
                r.code_ticket,
                r.statut,
                r.Sujet,
                r.sla,
                r.agent,
                r.date_creation,
                r.client,
                r.type,
                r.voie,
                r.groupe,
                r.service,
                r.severite,
              ]);
            } else {
              array.push([
                r.code_client,
                r.Prenom,
                r.Nom,
                r.Email,
                r.Tel,
                r.code,
                r.RS,
                r.Tel_Etpse,
                r.Email_Etpse,
                r.Adresse_Etpse,
              ]);
            }
          });
          doc.text(
            30,
            20,
            `Helpdesk Tickets :  `,
            doc.lastAutoTable.finalY + 10
          );
          doc.autoTable({
            head: [type === "ticket" ? headersPDF : headersPDFClient],
            body: array,
            startY: 25,
            styles: {
              overflow: "linebreak",
              cellWidth: "nowrap",
              font: "arial",
            },
            columnStyles: { text: { cellWidth: "auto" } },
          });
          doc.save(
            `export ${type}_${moment().format("MMMM Do YYYY, h:mm")}.pdf`
          );
          setTimeout(() => {
            if (ExportModal) setExportModal(false);
          }, 5000);
        } else {
          setError("");
          setData(response.data);
          setTimeout(() => {
            if (ExportModal) setExportModal(false);
          }, 5000);
        }
      } else {
        setError("Network Failed,Try later.");

        setTimeout(() => {
          if (ExportModal) setExportModal(false);
        }, 3000);
      }
    } catch (error) {
      console.log(error);
      setError("Network Failed,Try later.");
      setTimeout(() => {
        if (ExportModal) setExportModal(false);
      }, 3000);
    }
  };

  return (
    <div>
      <div className="justify-center items-center border-2 w-full flex fixed   inset-0 z-50 outline-none focus:outline-none">
        <div className="relative  my-9 mx-auto max-w-3xl">
          <div className="dark:bg-gray-900 w-96 m-2  rounded-lg shadow-lg relative flex flex-col   bg-white outline-none focus:outline-none">
            <div className="flex items-center  pl-2 pt-2 ">
              <div className="text-gray-600 text-lg block   dark:text-white">
                {t("Ticket.ExportDonnée")}
              </div>
              <div className="flex ml-2 space-x-4  ">
                <select
                  name="délimiteur"
                  className="mt-1 dark:text-white  w-2/5 text-base dark:bg-gray-800 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  onChange={(e) => setDelimiteur(e.target.value)}
                >
                  <option value=";" className="dark:text-white">
                    ;
                  </option>
                  <option value="," className="dark:text-white">
                    ,
                  </option>
                  <option value="/t" className="dark:text-white">
                    /t
                  </option>
                </select>

                <select
                  id={typeE}
                  name={typeE}
                  className="mt-1 dark:text-white  w-full  text-base dark:bg-gray-800 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  defaultValue={typeE}
                  onChange={(p) => setTypeE(p.target.value)}
                >
                  <option className="dark:text-white">PDF</option>
                  <option className="dark:text-white">CSV</option>
                </select>
              </div>
            </div>

            {type !== "client" && (
              <div className="flex justify-between items-center">
                <div className="m-5">
                  <div className="block text-gray-500 dark:text-white text-sm ">
                    {t("Client.Début")}
                  </div>
                  <Datech
                    placeholder={moment().format("LL")}
                    disbaledDate={true}
                    defaultValues=""
                    setValues={setDateD}
                  />
                </div>
                <div className="m-5">
                  <div className="block text-gray-500 dark:text-white text-sm ">
                    {t("Client.Fin")}
                  </div>
                  <Datech
                    placeholder={moment().add(1, "day").format("LL")}
                    disbaledDate={false}
                    defaultValues=""
                    values={dateD}
                    setValues={setDateF}
                  />
                </div>
              </div>
            )}
            {error.length > 0 && (
              <div className="text-gray-500 pl-2 dark:text-white text-sm italic">
                {" "}
                {error}{" "}
              </div>
            )}

            <div className="px-4 py-3 space-x-4  dark:bg-gray-900 justify-around flex sm:px-6">
              <button
                disabled={disabled}
                className="text-white  hover:bg-gray-200 hover:translate-x-8  w-28 h-10 text-lg bg-gray-400 items-center flex justify-center py-2 px-4 border  rounded-full shadow"
                onClick={() => {
                  setExportModal(false);
                }}
              >
                {t("All.Annuler")}
              </button>

              <button
                onClick={() => {
                  getData();
                  setClicked(!clicked);
                  setDisabled(true);
                }}
                className={classNames(
                  disabled
                    ? "cursor-not-allowed bg-gray-500 text-white  hover:translate-x-8  w-28 h-10 text-lg  items-center flex justify-center py-2 px-4 border  rounded-full shadow"
                    : "text-white hover:bg-green-200 hover:translate-x-8  w-28 h-10 text-lg bg-green-400 items-center flex justify-center py-2 px-4 border  rounded-full shadow"
                )}
              >
                <div>
                  {!clicked ? (
                    <div>{t("All.Valider")}</div>
                  ) : (
                    <div className="flex">
                      <div className=" justify-center flex items-center">
                        <div className="animate-spin">
                          <CgSpinner size={30} color="white" />
                        </div>
                      </div>
                      <div className="hidden">
                        {data.length > 0 &&
                        error.length === 0 &&
                        typeE === "CSV"
                          ? setTimeout(() => {
                              csvlink.current?.link.click();
                            }, 3000)
                          : null}
                      </div>

                      {error.length === 0 && typeE === "CSV" && (
                        <CSVLink
                          separator={delimiteur}
                          headers={headers}
                          filename={`${moment().format(
                            "MMMM Do YYYY, h:mm"
                          )}.csv`}
                          data={data ?? []}
                          ref={csvlink}
                        />
                      )}
                    </div>
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </div>
  );
}

export default ExportModal;
