import { useRef, useState } from "react";

function SelectComponent({
  nom,
  onChange,
  data,
  selected,
  required,
  severiteValidate,
  defaultValue,
  setSeveriteValidate,
  disabled,
}) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const ref = useRef();
  const [state, setState] = useState(false);
  const onBlur = () => {
    if (severiteValidate || ref.current.value === "") {
      setState(true);
    }
  };
  return (
    <div>
      <div className="w-full">
        <label
          htmlFor={nom}
          className={classNames(
            severiteValidate || (state && required)
              ? " block animate-bounce400 text-sm dark:text-white   font-medium text-red-700"
              : "block text-sm dark:text-white   font-medium text-gray-700"
          )}
        >
          {nom}
        </label>
        <select
          disabled={disabled}
          onChange={(e) => onChange(e.target.value)}
          ref={ref}
          onFocus={() => {
            if (required) setSeveriteValidate(false);
            setState(false);
          }}
          onBlur={onBlur}
          className={classNames(
            severiteValidate || (required && state)
              ? "mt-1 block w-full  bg-white  pl-3 pr-10 py-2 dark:bg-gray-700 dark:text-white text-base border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
              : disabled
              ? "mt-1 block w-full  bg-gray-200  pl-3 pr-10 py-2 dark:bg-gray-500  dark:text-white text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md cursor-not-allowed"
              : "mt-1 block w-full   pl-3 pr-10 py-2 dark:bg-gray-700 dark:text-white text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          )}
          value={defaultValue}
          id={selected}
          name={nom}
        >
          <option
            className="flex text-gray-200   opacity-60 text-sm capitalize"
            key={`key_${nom}_01`}
            value=""
          >
            {data.length < 0 ? "loading" : `${nom}`}
          </option>

          {data.length > 0 &&
            data.map((s, index) => (
              <option
                className="flex text-gray-500 font-light text-sm capitalize"
                key={`key_${nom}__${index}`}
                value={[Object.values(s)[0]]}
              >
                {`\u2742 ${[Object.values(s)[1]]}`}
              </option>
            ))}
        </select>
      </div>

    </div>
  );
}
export default SelectComponent;
