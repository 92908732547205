import React from 'react'
const ProgressBar = ({ progressPercentage }) => {
	return (
	
        <div className="w-full  bg-gray-200 rounded-full">
  <div className="bg-blue-600 py-1 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{width: progressPercentage+"%"}}> {progressPercentage}%</div>
</div>
	);
};

export default ProgressBar;