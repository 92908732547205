import React, { Fragment, useState, useEffect, useRef } from "react";
import { Transition } from "@headlessui/react";
import {
  AiOutlineSearch,
  AiOutlineClose,
  AiOutlineCheck,
} from "react-icons/ai";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";
import { classNames } from "../Table/ListTable";
import { useTranslation } from "react-i18next";

function Select2({
  extraTicket,
  name,
  disabled,
  data,
  extra,
  setSelectItem,
  defaultItem,
  update,
  loading,
  onChnageSearch,
  zIndex,
  required,
  setElementValidate,
  elementValidate,
  reverse,
  hidden,
  openSelect2,
  setOpenSelect2,
  dark,
  selectPlaceholder,
  onlyLabel,
  type,
}) {
  const { t } = useTranslation();
  const ref = useRef();
  const [state, setState] = useState(false);
  const onBlur = () => {
    if (elementValidate || ref.current.value === "") {
      setState(true);
    }
  };
  const options = extraTicket
    ? data.map((d) => ({
        id: `${[Object.values(d)[1]]}`,
        label: `${[Object.values(d)[22]]}`,
        labelExtra: `${[Object.values(d)[14]]}`,
      }))
    : extra
    ? data.map((d) => ({
        id: `${[Object.values(d)[0]]}`,
        label: `${[Object.values(d)[1]]}`,
        labelExtra: `${[Object.values(d)[2]]}`,
      }))
    : data.map((d) => ({
        id: `${[Object.values(d)[0]]}`,
        label: `${[Object.values(d)[1]]}`,
      }));
  const [open, setopen] = useState(false);
  const searchRef = useRef();
  const [search, setsearch] = useState("");
  const [select, setselect] = useState(
    defaultItem === null || defaultItem === "" ? null : defaultItem ?? null
  );
  const [dataTotal, setDataTotal] = useState(options);

  let keys = required ? ["id", "label", "labelExtra"] : ["id", "label"];

  const onCHangeFunc = (e) => {
    if (required) onChnageSearch(searchRef.current?.value);

    if (e !== "") {
      let result = dataTotal.filter((d) => {
        return keys.some((key) =>
          d[key].toLowerCase().includes(search.toLowerCase())
        );
      });
      setDataTotal(result);
      setsearch(e);
    } else {
      setsearch("");
      setDataTotal(options);
    }
  };

  useEffect(() => {
    if (hidden) {
      setopen(openSelect2);
    }

    if (defaultItem === null) {
      setselect(defaultItem);
    } else if (
      Object.values(defaultItem)[0] > 0 ||
      Object.values(defaultItem)[0]?.length > 0
    ) {
      setselect(defaultItem);
    }
  }, [openSelect2, defaultItem]);

  useEffect(() => {
    if (dataTotal.length === 0) setDataTotal(options);

    if (update) {
      setDataTotal(options);
    }
    return () => {};
  }, [data, update]);

  useEffect(() => {
    if (disabled) {
      if (defaultItem === "") {
        setselect(null);
      }
      if (hidden) setOpenSelect2(false);

      setopen(false);
    }

    return () => {};
  }, [disabled]);

  // useOnClickOutside(containterRef, open, () => {
  //   if (hidden) setOpenSelect2(false);
  //   setopen(false);
  // });
  return (
    <div
      style={{
        width: type === "groupe" && zIndex ? null : zIndex ? "265px" : null,
      }}
      className={classNames(
        zIndex ? " absolute w-11/12" : " relative  w-full  ",
        "dark:bg-gray-" + dark
      )}
    >
      <div
        className={classNames(reverse ? " flex flex-col-reverse" : "w-full")}
      >
        <div
          className={classNames(
            name === "hidden"
              ? "hidden"
              : elementValidate || (state && required)
              ? "  animate-bounce400    font-medium text-red-700"
              : " text-gray-700 dark:text-gray-50  font-medium ",
            "ml-2 flex dark:bg-gray-" +
              dark +
              "text-sm  cursor-defaultdark:text-white"
          )}
        >
          {name}
        </div>

        <div
          tabIndex={0}
          //  onBlur={()=>{
          //    if (hidden&&ref.current)
          //       setOpenSelect2(false)
          //      setopen(false)
          //  }}
          className={classNames(
            hidden ? "hidden" : "max-h-24    relative overflow-y-auto"
          )}
        >
          <div
            onClick={() => {
              setopen(!open);
            }}
            className={classNames(
              disabled
                ? "bg-gray-100 cursor-not-allowed pointer-events-none dark:bg-gray-800 max-h-12  flex  pl-3 py-2  text-left focus:outline-none focus:ring-1  focus:ring-indigo-500 border-gray-200  border border-solid rounded-lg m-1 focus:border-indigo-500 sm:text-sm"
                : elementValidate || (required && state)
                ? "bg-white dark:bg-red-700        max-h-12 flex   pl-3 py-2  text-left focus:outline-none focus:ring-1 focus:ring-red-500 border-red-300  border border-solid rounded-lg m-1 focus:border-red-500 sm:text-sm"
                : "bg-white dark:bg-gray-700        max-h-12 flex   pl-3 py-2  text-left focus:outline-none focus:ring-1 focus:ring-indigo-500 border-gray-200  border border-solid rounded-lg m-1 focus:border-indigo-500 sm:text-sm"
            )}
          >
            <div
              key={`keys_selected_${select?.label}_`}
              className="  truncate   block justify-between items-center"
            >
              <span className="block   dark:underline-white ">
                {select && (
                  <div className=" flex justify-between  items-center dark:text-white   text-gray-500  border  capitalize border-blue-500 border-solid  mr-5 rounded-full">
                    <div className="pl-1 pr-1 text-xs truncate flex items-center   break-words  ">
                      {select?.label}{" "}
                      {extraTicket || (extra && required) ? " ~ " : ""}
                      {onlyLabel ? null : name.includes("Code") ? (
                        <div className="font-bold  text-sm">
                          {select?.labelExtra}
                        </div>
                      ) : (
                        select?.labelExtra
                      )}
                    </div>
                    <AiOutlineClose
                      onClick={() => {
                        setState(true);
                        onCHangeFunc("");
                        setopen(!open);
                        if (hidden) setOpenSelect2(false);
                        setSelectItem("");
                        setselect(null);
                      }}
                      className="h-5 w-5  text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                )}
                <div className="dark:text-white normal-case text-gray-500  ">
                  {!select && selectPlaceholder}
                </div>
              </span>
            </div>
          </div>
          <div
            onClick={() => {
              if (hidden) setOpenSelect2(false);
              setopen(!open);
            }}
            className="absolute inset-y-0   cursor-pointer  right-0   flex items-center  "
          >
            {!disabled && open ? (
              <BsChevronUp
                className="h-4 w-4 mr-2  text-gray-500"
                aria-hidden="true"
              />
            ) : (
              <BsChevronDown
                className="h-4 w-4 mr-2  text-gray-500"
                aria-hidden="true"
              />
            )}
          </div>
        </div>
        <Transition
          show={hidden ? openSelect2 : open}
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setopen(false)}
        >
          <div
            className={classNames(
              hidden ? "  bottom-0 w-80    " : "inset-x-1 ",
              " z-20 absolute  rounded-md bg-white dark:bg-gray-800 flex flex-col     rounded-b-md   text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
            )}
          >
            <div className=" dark:border dark:border-white max-w-full   relative rounded-md shadow-sm">
              <div className="flex items-center relative">
                <div className=" dark:bg-gray-700 absolute left-0 z-10 ml-1   pl-2 flex items-center pointer-events-none">
                  <div className="text-gray-500 text-lg dark:text-white sm:text-sm">
                    <AiOutlineSearch className="h-5 w-5  " aria-hidden="true" />
                  </div>
                </div>
                <div className=" flex w-full items-center relative">
                  <input
                    ref={searchRef}
                    value={search}
                    type="text"
                    onChange={(e) => onCHangeFunc(e.target.value)}
                    className="focus:ring-indigo-500 text-sm    py-3 placeholder-gray-600 dark:placeholder-gray-300 focus:border-indigo-500 dark:bg-gray-700 dark:text-white block w-full pl-11 sm:pl-11 sm:text-sm border-gray-300 rounded-md"
                    placeholder={t("All.Rechercher")}
                  />
                  {search !== "" && (
                    <AiOutlineClose
                      onClick={() => {
                        setDataTotal(options);
                        onCHangeFunc("");
                      }}
                      className="h-5 w-5 absolute  right-8 bg-gray-200 p-1 rounded-full cursor-pointer hover:text-gray-50 hover:bg-gray-300   text-gray-600"
                      aria-hidden="true"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="max-h-60   overflow-y-auto dark:bg-gray-600 min-w-full shadow">
              {loading ? (
                <div className="m-1 dark:bg-gray-800 dark:text-white text-gray-600 text-lg animate-pulse italic flex items-center justify-center">
                  {" "}
                  loading...
                </div>
              ) : dataTotal.length > 0 ? (
                dataTotal.map((d) => (
                  <div
                    onBlur={onBlur}
                    ref={ref}
                    onClick={() => {
                      if (hidden) {
                        setOpenSelect2(false);
                      }
                      setopen(false);
                      setselect(d);

                      setSelectItem(d);
                      onCHangeFunc("");
                      setState(false);
                      if (required) setElementValidate(false);
                    }}
                    key={`key_list_selected${d.id}_item`}
                    className="text-gray-900  dark:text-white font-light  dark:bg-gray-600  cursor-default relative py-1  "
                    value={d.id}
                  >
                    <div
                      className={classNames(
                        select?.id == d.id
                          ? "flex text-gray-500 hover:text-gray-700  items-center h-10 pl-2 hover:shadow bg-gray-100 hover:bg-gray-300  justify-between cursor-pointer rounded-md "
                          : "flex hover:text-gray-500  items-center h-10 pl-2 hover:shadow hover:bg-gray-100  justify-between cursor-pointer rounded-md "
                      )}
                    >
                      <div className="flex capitalize ">
                        {`\u2742 ${d.label}${
                          extraTicket || (extra && required) ? " ~ " : ""
                        }`}
                        {onlyLabel || hidden ? (
                          ""
                        ) : name.includes("Code") ? (
                          <div className="font-bold text-sm">
                            {d?.labelExtra}
                          </div>
                        ) : (
                          d?.labelExtra
                        )}
                      </div>

                      {select?.id == d.id && (
                        <AiOutlineCheck className="h-5 w-5 mr-2 dark:text-gray-600 text-gray-400" />
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex text-lg text-gray-600 items-center justify-center p-2 dark:text-white capitalize ">
                  {" "}
                  Vide
                </div>
              )}
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}

export default Select2;
