
// import React from 'react'

// const Loader = () => {
//     let circleCommonClasses = 'h-2.5 w-2.5 bg-current  rounded-full';

//     return (
//         <div className='flex items-center justify-center mt-64'>
//             <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
//             <div
//                 className={`${circleCommonClasses} mr-1 animate-bounce200`}
//             ></div>
//             <div className={`${circleCommonClasses} animate-bounce400`}></div>
//         </div>
//     );
// };

// export default Loader;




import React from 'react'

import './Loading.css'
import ReactLoading from "react-loading"
const Loader = () => {

    return(
      


     <ReactLoading
      type="bubbles"
      className="text-purple-400  dark:text-white"
      color="#7730db" height={200} width={120}
     />
     









    )
}

export default Loader;
