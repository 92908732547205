import React from "react";
import { createPopper } from "@popperjs/core";
import {AiOutlineInfoCircle, AiOutlineClockCircle, AiOutlineSearch } from "react-icons/ai";
import {BsBoxArrowInDownLeft,BsFolderSymlink, BsTelephoneForward, BsFillChatDotsFill, BsGlobe2,BsPersonCheck} from "react-icons/bs";
import {IoReloadOutline,IoPersonAddOutline} from 'react-icons/io5'
import { FiEdit, FiRefreshCcw } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import {VscFiles, VscFileSubmodule, VscGoToFile} from "react-icons/vsc"
import moment from "moment";
import { classNames } from "../../shared/Utils";
import { DotsHorizontalOutline } from "heroicons-react";
import {HiOutlineInformationCircle} from 'react-icons/hi'
import { ImMagicWand } from "react-icons/im";
import {IoTicketOutline} from 'react-icons/io5'
import { PlusSmIcon,BellIcon } from "@heroicons/react/outline";
import { BiListPlus, BiLockAlt, BiLockOpenAlt } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import {MdAttachFile} from "react-icons/md"
const Tooltip = ({ color, value, type,colorSLA ,hidden }) => {
  const [tooltipShow, setTooltipShow] = React.useState(false);
  const btnRef = React.createRef();
  const tooltipRef = React.createRef();
  const { t } = useTranslation();
  const openLeftTooltip = () => {
    createPopper(btnRef.current, tooltipRef.current, {
      placement: "bottom",
    });
    setTooltipShow(true);
  };
  const closeLeftTooltip = () => {
    setTooltipShow(false);
  };

  function getIcon() {
    switch (type) {
      case "show":
        return(
          <DotsHorizontalOutline className="h-6 w-10 text-gray-700  cursor-help  " />
        )
      case "global":
        return(

          <BsGlobe2 className="h-5 w-5 text-white  cursor-pointer  dark:text-gray-200" />

        )
        case "transfert":
          return(
              
             <VscGoToFile className="h-5 w-5 text-white  cursor-pointer  dark:text-gray-200" />

          )
      case "slaSpec":
        return (
            <AiOutlineInfoCircle className="h-5 w-5 text-gray-500 hover:text-gray-800 cursor-pointer  dark:text-gray-500" />
        );
      case "addCLient":
        return (
            <IoPersonAddOutline
                aria-selected="true"
                className="h-6 w-6 cursor-pointer  text-white  "
            />                  );
      case "validCLient":
        return (
            <BsPersonCheck
                aria-selected="true"
                className="h-6 w-6 cursor-pointer  text-white  "
            />                );
          case "fusion":
            return(

              <VscFiles className="h-5 w-5 text-white  cursor-pointer  dark:text-gray-200" />

            )

      case "Déplacer":
        return (
          <BsFolderSymlink className="h-7 w-7 text-indigo-500 hover:text-indigo-800 cursor-pointer  dark:text-indigo-500" />
          );

      case "sla":
        return (
          <AiOutlineClockCircle className={

            "text-" +
            colorSLA +
            "-400  h-6 w-6 cursor-help hover:text-"+colorSLA+"-500 dark:hover:text-"+colorSLA+"-300 dark:text-"+colorSLA+"-400"
          }
           />
        );

        case "slaNav":
          return (
            <AiOutlineClockCircle 
            className="h-8 w-8 text-gray-500  cursor-pointer"
             />
          );

        case "Relancer2":
        return (
          <FiRefreshCcw className="h-6 w-6 text-white hover:text-white cursor-pointer   dark:text-white" />
        );
      case "Relancer":
        return (
          <FiRefreshCcw className="h-6 w-6 text-indigo-500 hover:text-indigo-800 cursor-pointer   dark:text-indigo-500" />
        );
      case "Appeler":
        return (
          <BsTelephoneForward className="text-indigo-500 h-6 w-6  hover:text-indigo-800 cursor-pointer dark:text-indigo-500" />
        );
      case "Commenter":
        return (
          <BsFillChatDotsFill className="text-indigo-500 h-6 w-6  hover:text-indigo-800 cursor-pointer dark:text-indigo-500" />
        );
      case "Assigner":
        return (
          <BsBoxArrowInDownLeft className="h-6 w-6  text-indigo-500 hover:text-indigo-800 cursor-pointer dark:text-indigo-500" />
        );
      case "Modifier":
        return (
          <FiEdit
           className="h-6 w-6 text-indigo-500 hover:text-indigo-800 cursor-pointer  dark:text-indigo-500" />
        );
        case "Modifier3":
        return (
          <FiEdit className="h-7 w-7 text-gray-500 hover:text-gray-800 cursor-pointer  dark:text-gray-500" />
        );


        case "Modifier2":
        return (
          <FiEdit className="h-5 w-5 text-indigo-500 hover:text-indigo-800 cursor-pointer  dark:text-indigo-400" />
        );
        case "Remettre":
          return (
            <IoReloadOutline className="h-6 w-6 text-indigo-500 hover:text-indigo-800 cursor-pointer  dark:text-indigo-500" />
          );
          case "Delete":
            return (
              <RiDeleteBin6Line className="h-6 w-6 text-indigo-500 hover:text-indigo-800 cursor-pointer  dark:text-indigo-500" />
            );

            case "Delete2":
            return (
              <RiDeleteBin6Line className="h-5 w-5 text-red-500 hover:text-red-800 cursor-pointer  dark:text-red-400" />
            );

            case "Fusionner":
              return (
                <VscFileSubmodule className="h-6 w-6 text-indigo-500 hover:text-indigo-800 cursor-pointer  dark:text-indigo-500" />
              );
              case "search":
              return (
                <AiOutlineSearch className="h-6 w-6 text-gray-500 hover:text-gray-800 cursor-pointer  dark:text-gray-500" />
              );
              case "slaSpec":
                return (
                  <AiOutlineInfoCircle className="h-5 w-5 text-gray-500 hover:text-gray-800 cursor-pointer  dark:text-gray-500" />
                );
                // case "Rech":
                //   return (
                //     <HiOutlineFilter className="w-9 h-9 animate-pulse   leading-6 text-white cursor-pointer   font-semibold   " />
                //     );
      case "ConsulteTicket":
        return (
            <IoTicketOutline
                aria-selected="true"
                className="h-6 w-6 cursor-pointer   "
            />                );
      case "mailToTicket":
        return (
            <ImMagicWand className='h-5 w-5 cursor-pointer  text-gray-600 dark:text-gray-400 transform
                        hover:scale-150  hover:rotate-12 duration-75'
            />
        );
        case "notification":
          return (
              <BellIcon className='h-8 w-9 text-gray-500 cursor-pointer'aria-selected="true"
              />
          );
        
          case "addTicket":
            return (
              <PlusSmIcon 
              
              className="h-8 w-8  cursor-pointer  bg-indigo-600 hover:bg-indigo-700 focus:ring-offset-2 focus:ring-indigo-500 rounded-full  text-white  focus:outline-none focus:ring-2 " aria-hidden="true" />
              
            );  
            
            case "unautoriser":
              return(
                <BiLockAlt 

                className="w-8 h-6  text-red-400 cursor-pointer dark:text-red-500" aria-hidden="true" />

              )
              case "autoriser":
                return(
                  <BiLockOpenAlt className="w-7 h-6 ml-0.5 text-green-400 cursor-pointer  dark:text-green-500" aria-hidden="true" />

                )
                case "info": return(
                  <HiOutlineInformationCircle
                  className="h-7 w-7 text-indigo-500 hover:text-indigo-800 cursor-pointer  dark:text-indigo-500" aria-hidden="true"
/>
                )
                case "detail": return(
                  <BiListPlus
                  className="h-7 w-7 text-indigo-500 hover:text-indigo-800 cursor-pointer  dark:text-indigo-500" aria-hidden="true"
/>
                )
                
                case "PJ":
                  return(
                    <MdAttachFile
                  className="h-6 w-6 text-gray-500 hover:text-gray-800 cursor-pointer  dark:text-gray-500" aria-hidden="true"
                  />
                  )
                
      default:
        return;
    }
  }

  return (
    <>
      <div className="flex">
        <div className="w-full">
          <div
            className={classNames(hidden?"hidden":"flex items-center animate-none  justify-center z-50  cursor-default ")}
            onMouseEnter={openLeftTooltip}
            onMouseLeave={closeLeftTooltip}
            ref={btnRef}
          >
             
            {type==="show"|| type==="search"||   type==="fusion"||type==="Modifier2"||    type==="transfert"|| type==="global"|| type === "Déplacer"|| type === "Delete"||
            type === "Delete2"||   type === "Remettre" ||type==="detail"
            || type === "sla"||type==="Appeler"||type==="slaSpec" ||type==="slaNav"
            ||type==="Relancer"||type==="Relancer2"||type==="Commenter"||type==="mailToTicket"||type==="ConsulteTicket"
            ||type==="Assigner"||type==="Modifier"||type==="Fusionner"||type==="addCLient"||type==="validCLient"
            || type==="addTicket"||type==='info' ||type==="notification"||type==="Modifier3"||type==="autoriser"||type==="unautoriser"||type==="PJ"
              ? getIcon()
              : type === "client"
              ? `${value?.split("   ")[0]}`
              :
              type==="D_H_C" ?
              moment(value).format("LT")
              :
              type==="agent"?
              `${value?.length>15? value.substring(0, 10)+"..." : value}`
              :
              type==="sujet" ?
              `${value?.length>30? value.substring(0, 30)+"..." : value}`:
              type==="ticket" ?
              `Ticket`:
              type==="Export" ?
              `Export`:
              type==="admin"?
              "admin":
              type==="agent1"?
              "agent":
              type==="client1"?
              "client":
              type==="alerte"?
              "alerte":
              type==="canal"?
              (t('All.Canal')):
              type==="département"?
              t('Filtre.dépar'):
              type==="dossier"?
              t('All.Dossier'):
              type==="flux"?
              t('All.Flux'):
              type==="groupe"?
              t('All.Groupe'):
              type==="produit"?
              t('Ticket.Produit'):
              type==="log"?
              "log":
              type==="Famille de produit"?
              t('Parametrage.ListeProd.Fa'):
              type==="service"?
              t('All.Service'):
              type==="Sévérité"?
               t('All.Sévérité'):
              type==="source"?
              t('All.Source'):
              type==="statut"?
              t('All.Statut'):
              type==="type"?
               t('All.Type'):
              type==="addSLA"?
              "SLA":
              type==="clotureAUTO"?
              t('Parametrage.SLA.ClotureAUTO'):
              type==="Rech"?
              t('Bord.Filtrer'):
              type==="Configuration ws"?
              t('All.Configuration') :
              type==="configuration"?
              t('All.ConfigurationWS'):
              type==="Entreprise"?
              t('All.Entreprise'):
              type==="Sujet"?
              t('Client.Sujet'):
              type==="Solution"?
              t('All.Solution'):
              type==="ticketclient"?
              "Ticket":
             
              type==="Sous compte client"?
              t('Client.SousCompte.SousCompteClient'):
              type==="Question"?
              t('FAQ.Question')   
              :
              type==="créerTicket"?
              "créer un ticket":

              `${value?.length>50? value.substring(0, 40)+"..." : value}` }
          </div>

          <div
            className={
              (tooltipShow ? "" : "hidden ") +
              "bg-" +
              color +
              "-600  h-9 mt-5 block z-50 font-normal animate-none whitespace-nowrap break-words leading-normal text-sm    rounded-full dark:bg-gray-300"
            }
            ref={tooltipRef}
          >
            {value ? (
              <div className="text-white p-2 flex items-center dark:text-gray-800 font-light ">
                {type === "sla"||type === "D_H_C" ? moment(value).format("lll") : 
                
                type==="client" ? value.replace("   "," -- "):
                value}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
export default Tooltip;
