import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "multipart/form-data;application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      config.headers.Authorization = `Bearer ${user.token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    console.log("user", originalConfig);
    if (originalConfig.url !== "/login" && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          let user = JSON.parse(localStorage.getItem("user"));

          const res = await axios.post(
            process.env.REACT_APP_OAUTH2_REFRESH_TOKEN_URL,
            {
              refresh_token: user.refresh_token,
            }
          );
          if (!Object.keys(res.data).includes("error")) {
            const accessToken = res.data.access_token;
            const refreshToken = res.data.refresh_token;

            user = {
              ...user,
              token: accessToken,
              refresh_token: refreshToken,
            };
            console.log({ user });
            localStorage.removeItem("user");
            localStorage.setItem("user", JSON.stringify(user));
            console.log(err);

            originalConfig.headers["Authorization"] = `Bearer ${accessToken}`;
          }

          return axiosInstance(originalConfig);
        } catch (_error) {
          window.location.href = "/login";
          return Promise.reject(_error);
        }
      }
    } else {
      if (err.name === "CanceledError") return;
      console.log("error", err);
    }

    return Promise.reject(err);
  }
);

export default axiosInstance;
