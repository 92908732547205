import {useRef,useState} from "react"

function TextAreaComponent({nom,req,length,onChange,numbreRow,selected,type,inputType,simpleInput,sujetValidate,setSujetValidate,placeholder,resize}){
    const ref=useRef()
    const [state,setState]=useState(false)
    function classNames(...classes) {
      return classes.filter(Boolean).join(" ");
    }

    const onBlur=()=>{
       if(sujetValidate||ref.current.value==="")
       {
           setState(true)
       }
    }
    return(
        <div
        className="">
            <div>
            <label
          htmlFor={nom}
          className={classNames(sujetValidate||state&&type? "animate-bounce400 text-red-700": "text-gray-700"  ,"block text-sm dark:text-white   font-medium ")}
          >
          {type&&nom==="Commentaire"? "": nom}
        </label>
      {simpleInput?  <input
        type={inputType?inputType: 'text'}
        minLength={length??0}
        required={req}
        maxLength={nom==="Sujet *" ?60: length??ref.current}
        onChange={(e)=>onChange(e.target.value)}
        value={selected}
        placeholder={placeholder}
        ref={ref}
        onFocus={()=>{
              if (type)
             setSujetValidate(false)
            setState(false)}}
        onBlur={onBlur}
        className={classNames(sujetValidate||type&&state? "border-red-300 focus:ring-red-500  focus:border-red-500":  
        
        "border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        ,resize ? "": "resize-none", "border-solid dark:bg-gray-700 text-base dark:text-white border  min-h-12  pl-2 w-full mt-1  sm:text-sm rounded-lg dark:placeholder-gray-50 " 
         )}
        />:
        <textarea
        minLength={length??0}
        required={req}
        rows={numbreRow}
        maxLength={nom==="Sujet *" ?60: length??ref.current}
        onChange={(e)=>onChange(e.target.value)}
        value={selected}
        placeholder={placeholder}
        ref={ref}
        onFocus={()=>{
              if (type)
             setSujetValidate(false)
            setState(false)}}
        onBlur={onBlur}
        className={classNames(sujetValidate||type&&state? "border-red-300 focus:ring-red-500  focus:border-red-500":  
        
        "border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        ,resize ? "": "resize-none", "border-solid dark:bg-gray-700 text-base dark:text-white border  min-h-12  pl-2 w-full mt-1  sm:text-sm rounded-lg dark:placeholder-gray-50 " 
         )}
      />}
     </div>

  
      </div>
    )


  }
  export default TextAreaComponent
