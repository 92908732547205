// import axios from "axios";
// import store from "../../store/store";
// import axiosInstance from "../../axiosInstance";
// const getTickett = async (page, limit, id, idP, Id_D_A, mine, query) => {
//   // const userStore = await store.getState().auth.user;
//   var url;
//   switch (Id_D_A) {
//     case 1:
//       url = id > 0 ? `Dossier/${id}` : "ticket_limit";
//       break;
//     case 2:
//       url = id > 0 ? `Dossier/${id}` : `mesTicket/${idP}`;
//       break;
//     case 4:
//       url = !mine
//         ? id > 0
//           ? `Dossier/${id}`
//           : `ticket_limit`
//         : id > 0
//         ? `Dossier/${id}`
//         : `mesTicket/${idP}`;
//       break;
//     default:
//       break;
//   }

//   const response = await axiosInstance.post(
//     `${url}/${limit ?? 8}?page=${page}`,
//     { query }
//   );
//   if (response.data) {
//     return response.data;
//   }
// };

// const addTickett = async (data) => {
//   // const userStore = await store.getState().auth.user;
//   let url = "ticket/add";
//   // var status = "";

//   // var myHeaders = new Headers();
//   // myHeaders.append("Authorization", "Bearer " + userStore.token);
//   var formData = new FormData();
//   formData.append("code_client", data.code_client);
//   formData.append("Flux", data.Flux);
//   formData.append("Id_Pers", data.Id_Pers);
//   formData.append("Id_Priorite", data.Id_Priorite);
//   formData.append("Id_Status", data.Id_Status);
//   formData.append("Id_Cat", data.Id_Cat);
//   formData.append("Id_Type", data.Id_Type);
//   formData.append("Id_Sous_Cat", data.Id_Sous_Cat);
//   formData.append("Id_Voie", data.Id_Voie);
//   formData.append("Id_Source", data.Id_Source);
//   formData.append("Sujet", data.Sujet);
//   formData.append("sla", data.sla);
//   formData.append("Desc_Scis", data.Desc_Scis);
//   formData.append("Solution", data.Solution);
//   formData.append("Commentaires", data.Commentaires);
//   formData.append("Id_departement", data.Id_departement);
//   formData.append("Id_service", data.Id_service);
//   formData.append("Id_groupe", data.Id_groupe);
//   formData.append("type", data.type);
//   formData.append("filename", data.filename);
//   formData.append("filesize", data.filesize);
//   formData.append("file", data.uri);
//   formData.append("uri", data.uri);

//   try {
//     const response = await axiosInstance.post(url, formData);
//     if (response?.status === 200) {
//       return response.data;
//     }
//   } catch (error) {
//     console.error(`Error ${error}`);
//   }
// };
// const updateTickett = async (data) => {
//   let url = `ticket/edit/${data.Id_Tckt}`;

//   try {
//     const response = await axiosInstance.put(
//       url,
//       JSON.stringify({
//         code_client: data.code_client,
//         Id_Pers: data.Id_Pers,
//         Flux: data.Flux,
//         Id_Priorite: data.Id_Priorite,
//         Id_Status: data.Id_Status,
//         Id_Type: data.Id_Type,
//         Id_Cat: data.Id_Cat,
//         Id_Sous_Cat: data.Id_Sous_Cat,
//         Id_Voie: data.Id_Voie,
//         Id_Source: data.Id_Source,
//         Sujet: data.Sujet,
//         sla: data.sla,
//         Desc_Scis: data.Desc_Scis,
//         Solution: data.Solution,
//         Commentaires: data.Commentaires,
//         Id_departement: data.Id_departement,
//         Id_service: data.Id_service,
//         Id_groupe: data.Id_groupe,
//         type: data.type,
//       })
//     );

//     if (response?.status === 200) {
//       return response.data;
//     }
//   } catch (error) {
//     console.error(`Error ${error}`);
//   }
// };

// const updateListTickett = async (url, extra, ticket, user) => {
//   // const userStore = await store.getState().auth.user;
//   var raw;
//   var req;
//   var status;

//   switch (url) {
//     case "ticket/Relance":
//       // var myHeaders = new Headers();
//       // myHeaders.append("Authorization", "Bearer " + userStore.token);
//       // myHeaders.append("Content-Type", "application/json");
//       raw = JSON.stringify({
//         id: Number(user),
//         Id_Tckt: Number(ticket.Id_Tckt),
//       });
//       // req = "PUT";
//       break;
//     case "commentaire/store":
//       // var myHeaders = new Headers();
//       // myHeaders.append("Authorization", "Bearer " + user.token);

//       raw = new FormData();

//       raw.append("Commentaire", extra.text.trim());
//       raw.append("Id_T", Number(ticket.Id_Tckt));
//       raw.append("Id_A", Number(user.Id_Pers));
//       raw.append("Id_Pers", Number(user.Id_Pers));
//       raw.append("filename", extra.filename);
//       raw.append("filesize", extra.filesize);
//       raw.append("type", extra.type);
//       raw.append("file", extra.file);
//       raw.append("tags", "");

//       // req = "POST";
//       break;
//     case "ticket/Assigner":
//       // var myHeaders = new Headers();
//       // myHeaders.append("Authorization", "Bearer " + userStore.token);
//       // myHeaders.append("Content-Type", "application/json");
//       raw = JSON.stringify({
//         Id_Tckt: ticket.Id_Tckt,
//         id_traiteur: Number(user),
//         Id_Pers: Number(user),
//       });
//       // req = "PUT";
//       break;
//     case "ticket/transfert":
//       // var myHeaders = new Headers();
//       // myHeaders.append("Authorization", "Bearer " + userStore.token);
//       // myHeaders.append("Content-Type", "application/json");
//       raw = JSON.stringify({
//         Id_Tckt: ticket,
//         id_trt: Number(extra.personSelected.id),
//         id_rec: Number(user),
//         Inter_Eff: extra.raisonInput,
//         Marq_R_Trsf: extra.interventionInput,
//       });
//       // req = "PUT";
//       break;
//     default:
//       req = "PUT";
//       break;
//   }

//   // var requestOptions = {
//   //   method: req,
//   //   headers: myHeaders,
//   //   body: raw,
//   //   redirect: "follow",
//   // };

//   try {
//     const response = await axiosInstance.post(url, raw);
//     if (response?.status === 200) {
//       return response.data[0];
//     }
//   } catch (error) {
//     console.error(`Error ${error}`);
//   }

//   // const res = await fetch(
//   //   process.env.REACT_APP_BASE_URL + url,
//   //   requestOptions
//   // ).then((res) => {
//   //   status = res.statusText;
//   //   return res.json();
//   // });

//   // if (status === "OK") return res[0];
// };
// const groupeTickett = async (url, extra, file, Id_Tckt, user, type) => {
//   const userStore = await store.getState().auth.user;
//   var raw;
//   var req;
//   var status;
//   var myHeaders = new Headers();
//   myHeaders.append("Authorization", "Bearer " + userStore.token);

//   switch (type) {
//     case "Déplacer":
//       myHeaders.append("Content-Type", "application/json");
//       raw = JSON.stringify({
//         Id_Pers: Number(user),
//         tickets: Id_Tckt,
//         dossier: extra,
//       });
//       req = "POST";
//       break;
//     case "CommenterGroupe":
//       raw = new FormData();
//       raw.append("tickets", Id_Tckt);
//       raw.append("commentaire", extra);
//       raw.append("Id_Pers", Number(user));
//       raw.append("type", file?.type);
//       raw.append("filename", file?.filename);
//       raw.append("filesize", file?.filesize);
//       raw.append("file", file?.file);
//       req = "POST";

//       break;

//     case "AssingerGroupe":
//       myHeaders.append("Content-Type", "application/json");
//       raw = JSON.stringify({
//         tickets: Id_Tckt,
//         id_trt: Number(extra),
//         Id_Pers: Number(user),
//       });
//       req = "PUT";
//       break;
//     case "remettre":
//       myHeaders.append("Content-Type", "application/json");
//       raw = JSON.stringify({
//         tickets: Id_Tckt,
//         Id_Pers: Number(user),
//       });
//       req = "PUT";
//       break;
//     case "deleteticket":
//       myHeaders.append("Content-Type", "application/json");

//       raw = JSON.stringify({
//         tickets: Id_Tckt,
//         Id_Pers: Number(user),
//       });
//       req = "PUT";
//       break;

//     case "updateticket_block":
//       myHeaders.append("Content-Type", "application/json");
//       raw = JSON.stringify({
//         code_client: extra.code_client,
//         Flux: extra.Flux,
//         Id_Priorite: extra.Id_Priorite,
//         Id_Status: extra.Id_Status,
//         Id_Type: extra.Id_Type,
//         Id_Cat: extra.Id_Cat,
//         Id_Sous_Cat: extra.Id_Sous_Cat,
//         Id_Voie: extra.Id_Voie,
//         Id_Source: extra.Id_Source,
//         Sujet: extra.Sujet,
//         sla: extra.sla,
//         Desc_Scis: extra.Desc_Scis,
//         Solution: extra.Solution,
//         Commentaires: "",
//         Id_departement: "",
//         Id_service: "",
//         Id_groupe: "",
//         tickets: Id_Tckt,
//         Id_Pers: Number(user),
//       });
//       req = "PUT";

//       break;
//     case "Fusionner":
//       myHeaders.append("Content-Type", "application/json");
//       raw = JSON.stringify({
//         Id_Pers: Number(user),
//         tickets: Id_Tckt,
//         id_ticket: extra,
//       });
//       req = "PUT";
//       break;

//     default:
//       break;
//   }

//   var requestOptions = {
//     method: req,
//     headers: myHeaders,
//     body: raw,
//     redirect: "follow",
//   };
//   const res = await fetch(
//     process.env.REACT_APP_BASE_URL + url,
//     requestOptions
//   ).then((res) => {
//     status = res.statusText;
//     return res.json();
//   });

//   if (status === "OK") return res;
// };
// const filtreTickett = async (
//   dateD,
//   DateF,
//   client,
//   type,
//   priorite,
//   flux,
//   status,
//   agent,
//   page,
//   limit
// ) => {
//   // const userStore = await store.getState().auth.user;
//   // var myHeaders = new Headers();
//   // myHeaders.append("Authorization", "Bearer " + userStore.token);
//   var formdata = new FormData();
//   formdata.append("date_deb", dateD);
//   formdata.append("date_fin", DateF);
//   formdata.append("client", client === "all" ? "" : client);
//   formdata.append("type", type === "all" ? "" : type);
//   formdata.append("priorite", priorite === "all" ? "" : priorite);
//   formdata.append("flux", flux === "all" ? "" : flux);
//   formdata.append("statut", status === "all" ? "" : status);
//   formdata.append("agent", agent === "all" ? "" : agent);

//   // var requestOptions = {
//   //   method: "POST",
//   //   headers: myHeaders,
//   //   body: formdata,
//   //   redirect: "follow",
//   // };

//   try {
//     const response = await axiosInstance.post(
//       `ticket/Recherche/${limit ?? 8}?page=${page ?? 1}`,
//       formdata
//     );
//     if (response?.status === 200) {
//       return response.data;
//     }
//   } catch (error) {
//     console.error(`Error ${error}`);
//   }

//   // const res = await fetch(
//   //   `${process.env.REACT_APP_BASE_URL}ticket/Recherche/${limit ?? 8}?page=${
//   //     page ?? 1
//   //   }`,
//   //   requestOptions
//   // ).then((res) => {
//   //   status = res.statusText;
//   //   return res.json();
//   // });

//   // return res;
// };

// const getDetailTicket = async (ticket, Id_Pers) => {
//   const url = `commentaire/${ticket}/${Id_Pers}`;

//   axiosInstance
//     .get(`${url}`)
//     .then((result) => {
//       if (result.data) return result.data;
//     })
//     .catch((error) => console.log("error", error));

//   // const response = await axios.get(process.env.REACT_APP_BASE_URL + `${url}`, {
//   //   headers: {
//   //     Authorization: "Bearer " + userStore.token,
//   //   },
//   // });
//   // if (response.data) return response.data;
// };
// export default {
//   getDetailTicket,
//   filtreTickett,
//   getTickett,
//   addTickett,
//   updateTickett,
//   updateListTickett,
//   groupeTickett,
// };

import store from "../../store/store";
import axiosInstance from "../../axiosInstance";

const getTickett = async (page, limit, id, idP, Id_D_A, mine, query) => {
  // const userStore = await store.getState().auth.user;
  var url;
  switch (Id_D_A) {
    case 1:
      url = id > 0 ? `Dossier/${id}` : "ticket_limit";
      break;
    case 2:
      url = id > 0 ? `Dossier/${id}` : `mesTicket/${idP}`;
      break;
    case 4:
      url = !mine
        ? id > 0
          ? `Dossier/${id}`
          : `ticket_limit`
        : id > 0
        ? `Dossier/${id}`
        : `mesTicket/${idP}`;
      break;
    default:
      break;
  }

  const response = await axiosInstance.post(
    `${url}/${limit ?? 8}?page=${page}`,
    { query }
  );
  if (response.data) {
    return response.data;
  }
};

const addTickett = async (data) => {
  const userStore = await store.getState().auth.user;
  let url = "ticket/add";
  var status = "";

  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + userStore.token);
  var formData = new FormData();
  formData.append("code_client", data.code_client);
  formData.append("Flux", data.Flux);
  formData.append("Id_Pers", data.Id_Pers);
  formData.append("Id_Priorite", data.Id_Priorite);
  formData.append("Id_Status", data.Id_Status);
  formData.append("Id_Cat", data.Id_Cat);
  formData.append("Id_Type", data.Id_Type);
  formData.append("Id_Sous_Cat", data.Id_Sous_Cat);
  formData.append("Id_Voie", data.Id_Voie);
  formData.append("Id_Source", data.Id_Source);
  formData.append("Sujet", data.Sujet);
  formData.append("sla", data.sla);
  formData.append("Desc_Scis", data.Desc_Scis);
  formData.append("Solution", data.Solution);
  formData.append("Commentaires", data.Commentaires);
  formData.append("Id_departement", data.Id_departement);
  formData.append("Id_service", data.Id_service);
  formData.append("Id_groupe", data.Id_groupe);
  formData.append("type", data.type);
  formData.append("filename", data.filename);
  formData.append("filesize", data.filesize);
  formData.append("file", data.uri);
  formData.append("uri", data.uri);

  try {
    const response = await axiosInstance.post(url, formData);
    if (response?.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(`Error ${error}`);
  }
};

const updateTickett = async (data) => {
  let url = `ticket/edit/${data.Id_Tckt}`;

  try {
    const response = await axiosInstance.put(
      url,
      JSON.stringify({
        code_client: data.code_client,
        Id_Pers: data.Id_Pers,
        Flux: data.Flux,
        Id_Priorite: data.Id_Priorite,
        Id_Status: data.Id_Status,
        Id_Type: data.Id_Type,
        Id_Cat: data.Id_Cat,
        Id_Sous_Cat: data.Id_Sous_Cat,
        Id_Voie: data.Id_Voie,
        Id_Source: data.Id_Source,
        Sujet: data.Sujet,
        sla: data.sla,
        Desc_Scis: data.Desc_Scis,
        Solution: data.Solution,
        Commentaires: data.Commentaires,
        Id_departement: data.Id_departement,
        Id_service: data.Id_service,
        Id_groupe: data.Id_groupe,
        type: data.type,
      })
    );

    if (response?.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(`Error ${error}`);
  }
};

const updateListTickett = async (url, extra, ticket, user) => {
  var raw;
  var req;
  var status;

  if (url === "commentaire/store") {
    raw = new FormData();

    raw.append("Commentaire", extra.text.trim());
    raw.append("Id_T", Number(ticket.Id_Tckt));
    raw.append("Id_A", Number(user.Id_Pers));
    raw.append("Id_Pers", Number(user.Id_Pers));
    raw.append("filename", extra.filename);
    raw.append("filesize", extra.filesize);
    raw.append("type", extra.type);
    raw.append("file", extra.file);
    raw.append("tags", "");

    try {
      const response = await axiosInstance.post(url, raw);
      if (response?.status === 200) {
        return response.data[0];
      }
    } catch (error) {
      console.error(`Error ${error}`);
    }
  } else {
    switch (url) {
      case "ticket/Relance":
        raw = JSON.stringify({
          id: Number(user),
          Id_Tckt: Number(ticket.Id_Tckt),
        });
        req = "PUT";
        break;

      case "ticket/Assigner":
        raw = JSON.stringify({
          Id_Tckt: ticket.Id_Tckt,
          id_traiteur: Number(user),
          Id_Pers: Number(user),
        });
        req = "PUT";
        break;
      case "ticket/transfert":
        raw = JSON.stringify({
          Id_Tckt: ticket,
          id_trt: Number(extra.personSelected.id),
          id_rec: Number(user),
          Inter_Eff: extra.raisonInput,
          Marq_R_Trsf: extra.interventionInput,
        });
        req = "PUT";
        break;
      default:
        req = "PUT";
        break;
    }

    try {
      const response = await axiosInstance.put(url, raw);
      if (response?.status === 200) {
        return response.data[0];
      }
    } catch (error) {
      console.error(`Error ${error}`);
    }
  }
};

const groupeTickett = async (url, extra, file, Id_Tckt, user, type) => {
  const userStore = await store.getState().auth.user;
  var raw;
  var req;
  var status;
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + userStore.token);

  switch (type) {
    case "Déplacer":
      myHeaders.append("Content-Type", "application/json");
      raw = JSON.stringify({
        Id_Pers: Number(user),
        tickets: Id_Tckt,
        dossier: extra,
      });
      req = "POST";
      break;
    case "CommenterGroupe":
      raw = new FormData();
      raw.append("tickets", Id_Tckt);
      raw.append("commentaire", extra);
      raw.append("Id_Pers", Number(user));
      raw.append("type", file?.type);
      raw.append("filename", file?.filename);
      raw.append("filesize", file?.filesize);
      raw.append("file", file?.file);
      req = "POST";

      break;

    case "AssingerGroupe":
      myHeaders.append("Content-Type", "application/json");
      raw = JSON.stringify({
        tickets: Id_Tckt,
        id_trt: Number(extra),
        Id_Pers: Number(user),
      });
      req = "PUT";
      break;
    case "remettre":
      myHeaders.append("Content-Type", "application/json");
      raw = JSON.stringify({
        tickets: Id_Tckt,
        Id_Pers: Number(user),
      });
      req = "PUT";
      break;
    case "deleteticket":
      myHeaders.append("Content-Type", "application/json");

      raw = JSON.stringify({
        tickets: Id_Tckt,
        Id_Pers: Number(user),
      });
      req = "PUT";
      break;

    case "updateticket_block":
      myHeaders.append("Content-Type", "application/json");
      raw = JSON.stringify({
        code_client: extra.code_client,
        Flux: extra.Flux,
        Id_Priorite: extra.Id_Priorite,
        Id_Status: extra.Id_Status,
        Id_Type: extra.Id_Type,
        Id_Cat: extra.Id_Cat,
        Id_Sous_Cat: extra.Id_Sous_Cat,
        Id_Voie: extra.Id_Voie,
        Id_Source: extra.Id_Source,
        Sujet: extra.Sujet,
        sla: extra.sla,
        Desc_Scis: extra.Desc_Scis,
        Solution: extra.Solution,
        Commentaires: "",
        Id_departement: "",
        Id_service: "",
        Id_groupe: "",
        tickets: Id_Tckt,
        Id_Pers: Number(user),
      });
      req = "PUT";

      break;
    case "Fusionner":
      myHeaders.append("Content-Type", "application/json");
      raw = JSON.stringify({
        Id_Pers: Number(user),
        tickets: Id_Tckt,
        id_ticket: extra,
      });
      req = "PUT";
      break;

    default:
      break;
  }

  var requestOptions = {
    method: req,
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  const res = await fetch(
    process.env.REACT_APP_BASE_URL + url,
    requestOptions
  ).then((res) => {
    status = res.statusText;
    return res.json();
  });

  if (status === "OK") return res;
};
const filtreTickett = async (
  dateD,
  DateF,
  client,
  type,
  priorite,
  flux,
  status,
  agent,
  page,
  limit
) => {
  var formdata = new FormData();
  formdata.append("date_deb", dateD);
  formdata.append("date_fin", DateF);
  formdata.append("client", client === "all" ? "" : client);
  formdata.append("type", type === "all" ? "" : type);
  formdata.append("priorite", priorite === "all" ? "" : priorite);
  formdata.append("flux", flux === "all" ? "" : flux);
  formdata.append("statut", status === "all" ? "" : status);
  formdata.append("agent", agent === "all" ? "" : agent);

  try {
    const response = await axiosInstance.post(
      `ticket/Recherche/${limit ?? 8}?page=${page ?? 1}`,
      formdata
    );
    if (response?.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(`Error ${error}`);
  }
};

const getDetailTicket = async (ticket, Id_Pers) => {
  const url = `commentaire/${ticket}/${Id_Pers}`;
  const response = await axiosInstance.get(`${url}`);
  if (response.data) return response.data;
};

export default {
  getDetailTicket,
  filtreTickett,
  getTickett,
  addTickett,
  updateTickett,
  updateListTickett,
  groupeTickett,
};
