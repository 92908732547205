import {
  Get_CLIENT,
  Get_CLIENT_Failed,
  FETCH_CLIENT,
  ADD_CLIENT_FAILED,
  ADD_CLIENT,
  UPDATE_CLIENT,
  UPDATE_CLIENT_FAILED,
  FETCH_CLIENT_ACTION,
  SUCCESS_CLIENT_END,
} from "../actions/types";
import { t } from "i18next";
const initialState = {
  error: "",
  errorAction: [],
  client: [],
  page: 1,
  pageLast: 1,
  firstItem: 1,
  lastItem: 1,
  total: 1,
  loading: false,
  loadingB: false,
  success: "",
  perPage: 12,
};

export default function clientR(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CLIENT:
      return {
        ...state,
        error: "",
        loading: true,
        errorAction: [],
      };
    case FETCH_CLIENT_ACTION:
      return {
        ...state,
        error: "",
        loadingB: true,
        errorAction: [],
      };

    case Get_CLIENT:
      return {
        ...state,
        error: "",
        client: payload.client,
        page: payload.page,
        lastPage: payload.lastPage,
        firstItem: payload.firstItem,
        lastItem: payload.lastItem,
        total: payload.total,
        loading: false,
        loadingB: false,
        perPage: payload.perPage,
        errorAction: [],
      };
    case Get_CLIENT_Failed:
      return {
        ...state,
        client: [],
        loading: false,
        error: payload,
        errorAction: [],
      };
    case ADD_CLIENT:
      return {
        ...state,
        loadingB: false,
        page: 1,
        client: [payload, ...state.client],
        error: "",
        errorAction: [],
        success: "Le client a été ajouté avec succès.",
      };
    case ADD_CLIENT_FAILED:
      return {
        ...state,
        loadingB: false,
        error: "",
        errorAction: payload,

        success: "",
      };

    case UPDATE_CLIENT:
      console.log("payload", payload);
      return {
        ...state,
        loadingB: false,
        page: 1,
        client: state.client.map((c) =>
          c.Id_Client == payload.Id_Client ? payload : c
        ),
        error: "",
        errorAction: [],

        success: `Le client avec le code ${payload.code_client} a été modifié avec succès.`,
      };
    case SUCCESS_CLIENT_END:
      return {
        ...state,
        loadingB: false,
        error: "",
        errorAction: [],
        success: "",
      };

    case UPDATE_CLIENT_FAILED:
      return {
        ...state,
        loadingB: false,
        errorAction: payload,

        error: "",
        success: "",
      };
    default:
      return state;
  }
}
