import React, { useState} from "react";
import {
    useTable,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
} from "react-table";


import { classNames } from "../shared/Utils";
import { SortIcon, SortUpIcon, SortDownIcon } from "../shared/Icons";
import moment from "moment";
import "moment/locale/fr";
import { ToolTip } from "../listeTickets/utils";
import VarHome from "../listeTickets/VAR/VarHome";
import { BsInfoLg } from "react-icons/bs";



//Filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
                                       column: { filterValue, setFilter, preFilteredRows, id, render },
                                   }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
        <div className="flex items-center justify-between">
            <label className="flex gap-x-2 items-baseline">
        <span className="text-gray-700 dark:text-white">
          {render("Header")}
        </span>
                <select
                    className="rounded-md border-gray-300 dark:text-white dark:bg-gray-800 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    name={id}
                    id={id}
                    value={filterValue}
                    onChange={(e) => {
                        setFilter(e.target.value || undefined);
                    }}
                >
                    <option value="">All</option>
                    {options.map((option, i) => (
                        <option key={i} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </label>
        </div>
    );
}

// color inside status :
export function StatusPill({ value }) {
    const status = value ? value.toLowerCase() : "unknown";

    return (
        <span
            className={classNames(
                "px-3 py-1 uppercase leading-wide font-bold  rounded-full shadow-sm",
                status.includes("créé") ? "bg-green-400 text-white" : null,
                status.includes("consulté") ? "bg-purple-400 text-white" : null,
                status.includes("en cours") ? "bg-blue-400 text-white" : null,
                status.includes("en attente") ? "bg-yellow-400 text-white" : null,
                status.includes("résolu") ? "bg-green-400 text-white" : null,
                status.includes("clôturé") ? "bg-violet-400 text-white" : null,
            )}
        >
      {status}
    </span>
    );
}

// color inside sévérité :
export function SévPill({ value }) {
    const status = value ? value.toLowerCase() : "unknown";

    return (
        <span
            className={classNames(
                "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
                status.includes("elevée") ? "bg-gray-500 text-white" : null,
                status.includes("normale") ? "bg-gray-400 text-white" : null,
                status.includes("faible") ? "bg-gray-300 text-white" : null
            )}
        >
      {status}
    </span>
    );
}

export function AvatarCell({value,row},setArray,array) {

    const [hover,setHover] =useState(false)
    const [open,setOpen] =useState(false)
    return (
        <>
            <div
                onMouseEnter={()=>setHover(true)}
                onMouseLeave={()=>setHover(false)}
                id="div"
                className="flex relative items-center">

                <div
                    className={classNames(!hover?"text-sm text-gray-900  dark:text-white":"text-sm text-gray-900 opacity-25  dark:text-white")}
                >
                    {value}
                </div>
                {  hover&&<BsInfoLg onClick={()=>setOpen(!open)} id="id"  className="h-5 w-5  absolute  left-6 cursor-pointer text-indigo-300 dark:text-indigo-500" />}

            </div>

            <div
            >
                {open&& <div>
                    <div className="justify-center items-center overflow-y-auto h-screen
            flex fixed inset-0  z-50 outline-none focus:outline-none">
                        <div className="relative  my-6 ">
                            <div className="dark:bg-gray-900 h-full  rounded-xl shadow-lg bg-white outline-none focus:outline-none">

                                <div className="rounded-lg  flex items-center justify-center  bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 ">
                                    <div

                                        className="flex h-5/6 w-full">

                                        <VarHome setArray={setArray} array={array} sideBar={true} onClose={setOpen} ticket={row.original}/>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                    <div
                        onClick={()=>setOpen(!open)}

                        className="opacity-25 fixed inset-0 z-40 bg-black" />
                </div>}
            </div>


        </>
    );
}


export function CellType({ value, column, row }) {
    if (value)
        return (
            <div className="flex items-center   ">
                <div className="text-justify max-w-sm truncate  font-medium px-3 py-1 leading-wide text-gray-900  bg-white border border-gray-400 rounded-full dark:bg-gray-800  shadow dark:text-white">
                    {value}
                </div>
            </div>
        );
    else {
        return <div />;
    }
}

export function MomentCell({ value, column, row }) {
    return (
        <div className="flex items-center ml-2">
            <div className="text-sm text-gray-900 dark:text-white">
                {moment(value).format("lll")}
            </div>
        </div>
    );
}

export function NonAssign({ value, column, row }) {
    if (!value || value === "--") {
        return (
            <div className="text-sm  ml-3 text-red-800 font-semibold dark:text-red-300 e capitalize">
                Non Assigné
            </div>
        );
    } else if (value?.length > 15) {
        return (
            <div className="flex items-center ml-3">
                <div className="text-sm  text-gray-900 dark:text-white">
                    <ToolTip type="agent" value={value} color="gray" />
                </div>
            </div>
        );
    } else {
        return (
            <div className="text-sm text-gray-900 ml-3 dark:text-white capitalize">
                {value}
            </div>
        );
    }
}
export function Character({ value, column, row }) {
    if (value?.length > 60) {
        return (
            <div className="flex items-center ">
                <div className="text-sm cursor-help text-gray-900 dark:text-white">
                    <ToolTip value={value} color="gray" />
                </div>
            </div>
        );
    } else {
        return (
            <div className="text-sm text-gray-900 dark:text-white capitalize">
                {value}
            </div>
        );
    }
}

export function CharacterSLA({ value, column, row }) {
    var colorSLA=""
    switch (Number(value.substring(0,1))) {
        case 1:
            colorSLA="red"
            break;
        case 3:
            colorSLA="yellow"
            break;
        case 2:
            colorSLA="green"
            break;
        default:
            colorSLA="yellow"

            break;
    }
    return (
        <div className="flex  ">
            <div className="text-sm text-gray-900 dark:text-white">
                <ToolTip value={value.substring(2,value.length)} type="sla" color="gray" colorSLA={colorSLA} />
            </div>
        </div>
    );
}

export function CharacterCLient({ value, column, row }) {
    return (
        <div className="flex items-center ml-1">
            <div className="text-sm text-gray-900 cursor-help dark:text-white">
                <ToolTip value={value} type="client" color="gray"  />
            </div>
        </div>
    );
}

export function Tool({ value, column, row }) {
    return (
        <div className="flex items-center ml-1">
            <div className="text-sm text-gray-900 dark:text-white">
                <ToolTip value={value} color="gray" />
            </div>
        </div>
    );
}

export function Space({ value }) {
    return (
        <div className="flex items-center  dark:text-white whitespace-normal">
            {value}
        </div>
    );
}



function Table({
                   columns,
                   data,
                   loadingg,


               }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,

    } = useTable(
        {
            columns,
            data,

        },

        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination, // new
        useRowSelect,

    );


    return (
        <div>




            {/* table */}
            <div className="mt-4 px-6 lg:px-8   flex flex-col   ">
                <div className="-my-2 overflow-x-scroll -mx-4 xs:-mx-10 sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle  inline-block sm:px-6 lg:px-5">
                        <div className="shadow border-b border-gray-200 sm:rounded-lg">
                            <table {...getTableProps()} className=" divide-y divide-gray-200">
                                <thead className="bg-gray-50   dark:bg-gray-800">
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            // Add the sorting props to control sorting. For this example
                                            // we can add them into the header props
                                            <th
                                                scope="col"
                                                className="group
                          px-3 py-3 w-screen text-left  text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider"
                                                {...column.getHeaderProps(
                                                    column.getSortByToggleProps()
                                                )}
                                            >
                                                <div className="flex items-center justify-between">
                                                    {column.render("Header")}
                                                    <span>
                              {column.isSorted ? (
                                  column.isSortedDesc ? (
                                      <SortDownIcon className="w-4 h-4 dark:text-white text-gray-400" />
                                  ) : (
                                      <SortUpIcon className="w-4 h-4 dark:text-white text-gray-400" />
                                  )
                              ) : (
                                  <SortIcon className="w-4 h-4 dark:text-white text-gray-400 opacity-0 group-hover:opacity-100" />
                              )}
                            </span>
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                                </thead>

                                <tbody
                                    {...getTableBodyProps()}
                                    className="bg-white dark:bg-gray-800 divide-y divide-gray-200"
                                >
                                {loadingg ? (
                                    <tr>
                                        <td />
                                        <td
                                            rowSpan={columns.length * 20}
                                            colSpan={columns.length * 20}
                                        >
                                            <div className="flex justify-center items-center text-5xl m-4 text-gray-500">
                                                Chargement...
                                            </div>
                                        </td>
                                    </tr>
                                ) : page.length === 0 ? (
                                    <tr>
                                        <td />
                                        <td
                                            rowSpan={columns.length * 20}
                                            colSpan={columns.length * 20}
                                        >
                                            <div className="flex justify-center  items-center text-2xl my-2 text-gray-500">
                                                vide...
                                            </div>
                                        </td>
                                    </tr>
                                ) : (
                                    page.map((row, i) => {
                                        // new
                                        prepareRow(row);

                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        className="px-2 py-4 w-screen whitespace-nowrap"
                                                        role="cell"
                                                    >
                                                        {cell.column.Cell.name === "defaultRenderer" ? (
                                                            <div className="text-sm dark:text-white text-gray-500">
                                                                {cell.render("Cell")}
                                                            </div>
                                                        ) : (
                                                            cell.render("Cell")
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {/* Pagination */}
            {/* <div className="py-3">
        <div className="flex items-center md:justify-between sm:justify-center   sm:flex-1 sm:flex sm:items-center">

          <div className="flex">
            <div className="text-lg dark:text-white text-gray-600 ">Affichage de l'élément <i className="font-bold"> {firstItem}</i> à <i className="font-bold">{lastItem}</i> sur <i className="font-bold">{total}</i> éléments</div>

          </div>
          {/* <nav
            className="relative z-0 inline-flex  rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <PageButton
              className="rounded-l-md "
              onClick={() => {
                setPage(pageLast - pageLast + 1);
                //  gotoPage(0)
              }}
              disabled={!canPreviousPage}
            >
              <span className="sr-only">First</span>
              <ChevronDoubleLeftIcon
                className="h-7 w-7 text-gray-400 dark:text-white"
                aria-hidden="true"
              />
            </PageButton>
            <PageButton
              onClick={() => {
                setPage((current) => (current > 1 ? current - 1 : current));

                previousPage();
              }}
              disabled={!canPreviousPage}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon
                className="h-7 w-7 text-gray-400 dark:text-white"
                aria-hidden="true"
              />
            </PageButton>
            <PageButton
              onClick={() => {
                setPage(pageNumber + 1);
                nextPage();
              }}
              disabled={!canNextPage}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon
                className="h-7 w-7 text-gray-400 dark:text-white"
                aria-hidden="true"
              />
            </PageButton>
            <PageButton
              className="rounded-r-md"
              onClick={() => {
                setPage(pageLast);

                gotoPage(pageCount - 1);
              }}
              disabled={!canNextPage}
            >
              <span className="sr-only">Last</span>
              <ChevronDoubleRightIcon
                className="h-7 w-7 text-gray-400 dark:text-white  "
                aria-hidden="true"
              />
            </PageButton>
          </nav>
        </div>
      </div>
                 */}
        </div>
    );
}

export default Table;
