import React from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./editorCSS.css";

export const Editor = ({
  fullScreen,
  setFullScreen,
  valueEditor,
  setValueEditor,
}) => {
  return (
    // <div className={fullScreen ? "text-editor-full" : "text-editor"}>
    <div className={"text-editor"}>
      <EditorToolbar fullScreen={fullScreen} setFullScreen={setFullScreen} />
      <ReactQuill
        placeholder="veuillez saisir votre commentaire..."
        // theme="snow"
        // readOnly={true}

        value={valueEditor}
        onChange={setValueEditor}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default Editor;
