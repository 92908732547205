import React, { Fragment, useEffect, useRef, useState } from "react";

import { RiCloseFill } from "react-icons/ri";
import Datech from "../../../date/Datech";
import FilterData from "../FilterType";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Menu, Transition } from "@headlessui/react";
import { BsDownload } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { filtreTicket } from "../../../JS/Ticket/actions/TicketA";
import { classNames } from "../../../shared/Utils";
import useFuncSearch from "../../../function/useClientSearch";
import { MultiSelectComponent, ToolTip } from "..";
import moment from "moment";
import { SET_STATUS_TO_FILTER } from "../../../JS/Ticket/actions/types";
import jsPDF from "jspdf";
import { CSVLink } from "react-csv";
const headersPDF = [
  "code_ticket",
  "agent",
  "code_client",
  "client",
  "perimetre",
  "severite",
  "statut",
  "type",
  "familles_produits",
  "produits",
  "voie",
  "source",
  "date_creation",
];
const headersCSV = [
  { label: "Code Ticket", key: "code_ticket" },
  { label: "agent", key: "agent" },
  { label: "Code Client", key: "code_client" },
  { label: "Client", key: "client" },
  { label: "Perimetre", key: "perimetre" },
  { label: "Severite", key: "severite" },
  { label: "Statut", key: "statut" },
  { label: "Type", key: "type" },
  { label: "Familles de produits", key: "familles_produits" },
  { label: "Produits", key: "produits" },
  { label: "Voie", key: "voie" },
  { label: "Source", key: "source" },
  { label: "date de création", key: "date_creation" },
];

const RechercheTicket = ({ setData, showModal, setShowModal, canAgent }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = React.useState(true);
  const [state, setState] = useState({
    dateD: "",
    dateF: "",
    client: "",
    type: "",
    priorite: "",
    flux: "",
    statut: "",
    agent: "",
  });
  const [selectedValidateF, setSelectedValidateF] = useState(false);
  const [selectedValidateD, setSelectedValidateD] = useState(false);
  const [typeExport, setTypeExport] = useState("csv");
  const [delimiter, setDelimiter] = useState(";");
  const [paramsString, setParamsString] = useState("");
  const perPage = useSelector((state) => state.ticketR.limit);
  const page = useSelector((state) => state.ticketR.page);
  const filterStatus = useSelector((state) => state.ticketR.filterStatus);
  const ticketFiltred = useSelector((state) => state.ticketR.ticket);
  const csvlink = useRef(null);
  const closeModal = () => {
    setState({
      ...state,
      dateD: "",
      dateF: "",
      client: "",
      type: "",
      priorite: "",
      flux: "",
      statut: "",
      agent: "",
    });
    setShowModal(false);
  };
  const disabledFunction = () => {
    return (
      (state.dateD !== "" && state.dateF === "") ||
      (state.dateF !== "" && state.dateD === "") ||
      (state.client === "" &&
        state.dateD !== undefined &&
        state.dateF !== undefined &&
        state.dateD === "" &&
        state.dateF === "" &&
        state.type === "" &&
        state.priorite === "" &&
        state.flux === "" &&
        state.agent === "" &&
        state.statut === "")
    );
  };
  const searchTicket = (typeExport) => {
    dispatch(
      filtreTicket(
        state.dateD,
        state.dateF,
        state.client,
        state.type,
        state.priorite,
        state.flux,
        state.statut,
        state.agent,
        page,
        typeExport ? 0 : perPage,
        typeExport ?? false
      )
    );
    if (!typeExport) closeModal();

    setData(state);
  };

  var array = [];

  useEffect(() => {
    console.log("dev", filterStatus, typeExport);
    if (filterStatus && typeExport !== null) {
      if (typeExport === "pdf") {
        var doc = new jsPDF("l", "px", "A3");
        ticketFiltred.map((r) => {
          array.push([
            r.code_ticket,
            r.agent,
            r.code_client,
            r.client,
            r.perimetre,
            r.severite,
            r.statut,
            r.type,
            r.familles_produits,
            r.produits,
            r.voie,
            r.source,
            r.date_creation,
          ]);
        });
        doc.text(30, 20, `Helpdesk Tickets :  `, doc.lastAutoTable.finalY + 10);
        doc.autoTable({
          head: [headersPDF],
          body: array,
          startY: 25,
          styles: {
            overflow: "linebreak",
            cellWidth: "nowrap",
            font: "arial",
          },
          columnStyles: { text: { cellWidth: "auto" } },
        });
        doc.save(`export pdf_${moment().format("MMMM Do YYYY, h:mm")}.pdf`);
        dispatch({
          type: SET_STATUS_TO_FILTER,
          payload: false,
        });
      } else if (
        typeExport === "csv" &&
        delimiter &&
        ticketFiltred.length > 0
      ) {
        setTimeout(() => {
          csvlink.current?.link.click();
          dispatch({
            type: SET_STATUS_TO_FILTER,
            payload: false,
          });
        }, 3000);
      }
      // export completed
    }
  }, [filterStatus, typeExport]);

  const handleInputChange = (q) => {
    if (q === null || q === "" || q === undefined) {
      setParamsString("");
    } else setParamsString(q);
  };
  const { data } = useFuncSearch(paramsString, "Client/recherche_simple");

  return (
    <>
      {/* <div
        className="cursor-pointer  rounded-l-lg w-10 h-11 flex justify-center items-center dark:bg-purple-800 bg-purple-500 hover:bg-purple-400 "

        onClick={() => setShowModal(true)}
      >      
        <ToolTip value="Recherche avancée" type="Rech" color="gray" />

      </div> */}

      <Transition.Root show={showModal} as={Fragment}>
        <div className={"absolute z-50  inset-0  mt-14"}>
          <div className="absolute  h-screen overflow-y-auto  inset-0 overflow-hidden">
            <div
              style={{ zIndex: 60 }}
              className="fixed inset-y-0  top-16 pl-16 max-w-full right-0 flex"
            >
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-100 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0 "
                leave="transform transition ease-in-out duration-100 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div
                  style={{ zIndex: 60 }}
                  className="w-screen relative  max-w-md"
                >
                  <form className="h-full divide-y divide-gray-200 flex flex-col bg-white dark:bg-gray-700 shadow-xl">
                    <div className="flex-1  overflow-y-auto">
                      <div className="py-2  px-4 bg-purple-700 sm:px-6">
                        <div className="flex py-1 items-center justify-between">
                          <div className="text-lg  font-medium capitalize text-white">
                            {t("Client.Recherche")}
                          </div>
                          <button
                            type="button"
                            onClick={() => closeModal()}
                            className="ml-3  h-8 w-8 flex justify-center cursor-pointer   items-center hover:bg-purple-500 rounded-full"
                          >
                            <div className="bg-purple-700 rounded-full  text-purple-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                              <RiCloseFill
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </div>
                          </button>
                        </div>
                        <div className="">
                          <p className="text-sm text-purple-300">
                            {t("Ticket.PersonnaliserRecherche")}
                          </p>
                        </div>
                      </div>
                      <div className="flex-1 flex flex-col justify-between">
                        <div className="px-4 divide-y divide-gray-200 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div onFocus={() => setSelectedValidateD(false)}>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900 dark:text-white"
                              >
                                {t("Client.Début")}
                              </label>
                              <div className="mt-1">
                                <Datech
                                  dateDebut={true}
                                  defaultValues={state.dateD}
                                  disbaledDate={true}
                                  mode={false}
                                  setValues={(e) => {
                                    if (
                                      (e === "" || e === "Invalid date") &&
                                      state.dateF !== ""
                                    ) {
                                      setSelectedValidateD(true);
                                      setSelectedValidateF(false);

                                      setState({ ...state, dateD: "" });
                                    } else if (
                                      (e === "" || e === "Invalid date") &&
                                      state.dateF === ""
                                    ) {
                                      setSelectedValidateD(false);

                                      setSelectedValidateF(false);
                                      setState({ ...state, dateD: "" });
                                    } else if (e !== "" && state.dateF === "") {
                                      setSelectedValidateF(true);
                                      setState({ ...state, dateD: e });
                                    } else {
                                      setState({ ...state, dateD: e });
                                    }
                                  }}
                                />
                              </div>
                              {selectedValidateD && (
                                <span className="inline-flex text-sm text-red-700 dark:text-red-300">
                                  {t("All.Required")}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-6 h-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </span>
                              )}
                            </div>
                            <div onFocus={() => setSelectedValidateF(false)}>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900 dark:text-white"
                              >
                                {t("Client.Fin")}
                              </label>
                              <div className="mt-1">
                                <Datech
                                  dateDebut={false}
                                  values={state.dateD}
                                  mode={false}
                                  defaultValues={state.dateF}
                                  setValues={(e) => {
                                    if (state.dateD === "" && e === "") {
                                      setSelectedValidateD(false);
                                      setSelectedValidateF(false);
                                      setState({ ...state, dateF: "" });
                                    } else if (state.dateD !== "" && e === "") {
                                      setSelectedValidateD(false);
                                      setSelectedValidateF(true);
                                      setState({ ...state, dateF: "" });
                                    } else if (e !== "" && state.dateD === "") {
                                      setSelectedValidateF(false);
                                      setSelectedValidateD(true);
                                      setState({ ...state, dateF: e });
                                    } else {
                                      setState({ ...state, dateF: e });
                                    }
                                  }}
                                />
                              </div>
                              {selectedValidateF && (
                                <span className="inline-flex text-sm text-red-700 dark:text-red-300">
                                  {t("All.Required")}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-6 h-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="pt-4 pb-6">
                            <div>
                              <label
                                htmlFor=" Client"
                                className="block text-sm ml-2 mt-2  font-medium capitalize text-gray-900 dark:text-white"
                              >
                                {t("Ticket.CodeClient")}
                              </label>
                              <div className="p-2">
                                <MultiSelectComponent
                                  prefix="CL"
                                  setSelected={(e) => {
                                    setState({
                                      ...state,
                                      client: e,
                                    });
                                  }}
                                  onChange={(e) => {
                                    handleInputChange(e);
                                  }}
                                  data={data.map((d) => ({
                                    id: `${[Object.values(d)[0]]}`,
                                    value: `${[Object.values(d)[1]]}`,
                                    label: `${[Object.values(d)[1]]}`,
                                    labelD: `${[Object.values(d)[2]]}`,
                                  }))}
                                />
                              </div>
                            </div>
                            {canAgent && (
                              <div>
                                <label
                                  htmlFor="agent"
                                  className="block text-sm ml-2 mt-2    font-medium text-gray-900 dark:text-white"
                                >
                                  Agent
                                </label>
                                <div className=" p-2">
                                  <FilterData
                                    prefix="Agent"
                                    name="agentsall"
                                    onChange={(e) =>
                                      setState({
                                        ...state,
                                        agent: e,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            <div>
                              <label
                                htmlFor="Type"
                                className="block ml-2 mt-2  text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Type
                              </label>
                              <div className="p-2">
                                <FilterData
                                  prefix="Type"
                                  name="Type"
                                  onChange={(e) => {
                                    setState({
                                      ...state,
                                      type: e,
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="flux"
                                className="block text-sm ml-2 mt-2  font-medium text-gray-900 dark:text-white"
                              >
                                {t("Bord.Périmètre")}
                              </label>
                              <div className=" p-2">
                                <FilterData
                                  prefix="Flux"
                                  name="Flux"
                                  onChange={(e) =>
                                    setState({
                                      ...state,
                                      flux: e,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="Priorite"
                                className="block text-sm ml-2 mt-2 font-medium text-gray-900 dark:text-white"
                              >
                                {t("All.Sévérité")}
                              </label>
                              <div className="p-2">
                                <FilterData
                                  prefix="Priorite"
                                  name="Priorite"
                                  onChange={(e) =>
                                    setState({
                                      ...state,
                                      priorite: e,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="Status"
                                className="block text-sm ml-2 mt-2    font-medium text-gray-900 dark:text-white"
                              >
                                {t("All.Statut")}
                              </label>
                              <div className=" p-2">
                                <FilterData
                                  prefix="Status"
                                  name="Status"
                                  onChange={(e) =>
                                    setState({
                                      ...state,
                                      statut: e,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-shrink-0 px-4 py-4 flex justify-around">
                      <div
                        className="text-white cursor-pointer hover:bg-gray-600 hover:translate-x-8  w-28 h-10 text-lg bg-gray-500 items-center flex justify-center py-2 px-4 border  rounded-full shadow"
                        onClick={() => closeModal()}
                      >
                        {t("All.Annuler")}
                      </div>
                      <button
                        disabled={disabledFunction()}
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          searchTicket();
                        }}
                        className={classNames(
                          disabledFunction()
                            ? "text-white     w-28 h-10 text-lg cursor-not-allowed bg-gray-400 items-center flex justify-center py-2 px-4 border  rounded-full shadow"
                            : "text-white cursor-pointer hover:bg-green-200 hover:translate-x-8  w-28 h-10 text-lg bg-green-400 items-center flex justify-center py-2 px-4 border  rounded-full shadow"
                        )}
                      >
                        {t("All.Valider")}
                      </button>

                      <div>
                        <Menu as="div" className="  relative">
                          <Menu.Button>
                            <BsThreeDotsVertical className="w-6 h-6 hover:text-gray-300 dark:text-white " />
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-500"
                            enterFrom="transform opacity-0 scale-20 "
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-out duration-200"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-20"
                          >
                            <Menu.Items className="z-10  absolute flex flex-row w-96  items-center text-center right-8 border border-purple-700  bottom-4 rounded-lg cursor-pointer shadow-lg bg-white dark:bg-gray-300  divide-x divide-gray-200 focus:outline-none">
                              <Menu.Item>
                                {({ active }) => (
                                  <div className="flex items-center space-x-2  ">
                                    <p className="w-full mt-4">
                                      {t("Ticket.ExportDonnée")}
                                    </p>

                                    <select
                                      name="délimiteur"
                                      className=" block  rounded-md border-0 py-1.5 w-12 h-8 pl-3 pr-5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      // className=" dark:text-white  w-1/5 text-base dark:bg-gray-800 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                      onClick={(e) => e.stopPropagation()}
                                      onChange={(e) => {
                                        e.stopPropagation();
                                        setDelimiter(e.target.value);
                                      }}
                                    >
                                      <option
                                        value=";"
                                        className="dark:text-white"
                                      >
                                        ;
                                      </option>
                                      <option
                                        value=","
                                        className="dark:text-white"
                                      >
                                        ,
                                      </option>
                                      <option
                                        value="\t"
                                        className="dark:text-white"
                                      >
                                        tab
                                      </option>
                                    </select>

                                    <select
                                      // id={typeE}
                                      // name={typeE}
                                      onClick={(e) => e.stopPropagation()}
                                      onChange={(e) => {
                                        console.log("val o9", e.target.value);
                                        setTypeExport(e.target.value);
                                      }}
                                      className=" block  rounded-md border-0 py-1.5 w-16 h-8 pl-3 pr-5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"

                                      // className=" dark:text-white    text-base dark:bg-gray-800 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                      // defaultValue={typeE}
                                      // onChange={(p) => setTypeE(p.target.value)}
                                    >
                                      <option
                                        value="csv"
                                        className="dark:text-white"
                                      >
                                        CSV
                                      </option>
                                      <option
                                        value="pdf"
                                        className="dark:text-white"
                                      >
                                        PDF
                                      </option>
                                    </select>

                                    <BsDownload
                                      className="h-12 w-12 text-red-600"
                                      onClick={(e) => {
                                        console.log("clickeddd");
                                        searchTicket(true);
                                      }}
                                    />
                                  </div>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                    {ticketFiltred.length >= 0 && typeExport === "csv" && (
                      <CSVLink
                        separator={delimiter}
                        headers={headersCSV}
                        filename={`${moment().format(
                          "MMMM Do YYYY, h:mm"
                        )}.csv`}
                        data={ticketFiltred ?? []}
                        ref={csvlink}
                      />
                    )}
                  </form>
                </div>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Transition.Root>
      {showModal && <div className="opacity-25 fixed inset-0 z-40 bg-black" />}
    </>
  );
};

export default RechercheTicket;
