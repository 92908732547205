import { useState, useEffect } from "react";
import axios from "axios";
import useDebounce from "./useDebounce";
import store from "../JS/store/store";
import { useSelector } from "react-redux";
const useFuncSearch = (queryString,url) => {
  
  const [data, setData] = useState([]);
  const [saved, setDataSaved] = useState([]);
  const [loading, setLoading] = useState(false);
  const debounceTerm= useDebounce(queryString,400)
const userStore=useSelector(state=>state.auth.user)
    useEffect(() => {            
      if (debounceTerm&&debounceTerm==="") 
       { setData(saved);
      }
        }, [debounceTerm]);
    useEffect(() => {
    let isMounted = true;
    const source = axios.CancelToken.source(); 
      const getData=async()=>{
        setLoading(true);
        await  axios({
          method: "POST",
          url: process.env.REACT_APP_BASE_URL + url ,
          headers: {
            Authorization:"Bearer "+userStore.token,
          },
          data: {
            query: debounceTerm,
          
          },
          cancelToken: source.token,
        })
          .then((res) => {
            if (isMounted) 
            { 
              console.log(res)
              setLoading(false);
            setData(res.data);
            if (debounceTerm==="")
             setDataSaved(res.data)


            }
          })
          .catch((e) => {
            if (axios.isCancel(e)) return;
          });
      }
      
      if(url==="Client/recherche_simple")
             getData();

     else if(queryString.length>0)
         getData();
           
    return () => {
      isMounted = false 
    source.cancel()
    };
  }, [debounceTerm]);

  return { data, loading,setData };
};

export default useFuncSearch;
