import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  AiOutlineFileAdd,
  AiOutlineCloseCircle,
  AiOutlineEdit,
} from "react-icons/ai";
import { MdAttachFile } from "react-icons/md";
import { useSelector, useDispatch, connect } from "react-redux";
import Datech from "../../../date/Datech";
import {
  SelectComponent,
  TextAreaComponent,
  Select2,
  MultiSelectComponent,
} from "../../utils";
import { classNames } from "../../Table/ListTable";
import { addTicket, updateTicket } from "../../../JS/Ticket/actions/TicketA";
import { CgSpinner } from "react-icons/cg";
import useFuncSearch from "../../../function/useClientSearch";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../JS/axiosInstance";

const FormTicket = ({
  close,
  dataSelected,
  setDataSelected,
  setDataGroupe,
  edited,
  stateBlocForm,
  general,
  generalBorn,
}) => {
  const dispatch = useDispatch();
  const [paramsString, setParamsString] = useState("");

  const { data, loading } = useFuncSearch(
    paramsString,
    "Client/recherche_simple"
  );

  const [selectedValidate, setSelectedValidate] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [state, setState] = useState({
    selectedCLient: {
      id: dataSelected?.id_client ?? 0,
      label: dataSelected?.code_client ?? "",
      labelExtra: dataSelected?.agence ?? "",
    },
    perimetreValidate: false,
    statusValidate: false,
    typeValidate: false,
    loading: true,
    voieValidate: false,
    sujetValidate: false,
    severiteValidate: false,
    selectedSeverite: dataSelected?.Id_Priorite ?? "",
    perimetre: dataSelected?.Flux ? dataSelected.Flux : "",
    seelectedStatut: dataSelected?.Id_Status
      ? dataSelected?.Id_Status
      : edited
      ? ""
      : "1",
    selectedType: dataSelected?.Id_Type ?? "",
    selectedProduit: {
      id: dataSelected?.Id_Cat ?? "",
      label: dataSelected?.familles_produits ?? "",
    },
    sousProduitSelected: {
      id: dataSelected?.Id_Sous_Cat ?? "",
      label: dataSelected?.produits ?? "",
    },
    selectedSource: dataSelected?.Id_Source ?? "",
    selectedVoie: dataSelected?.Id_Voie ? dataSelected?.Id_Voie : "",
    Sujet: dataSelected?.Sujet ?? "",
    Description: dataSelected?.Desc_Scis ?? "",
    solution: dataSelected?.Solution ?? "",
    commentaire: "",
    sla: dataSelected?.sla ?? "",
    selectedDep: dataSelected?.id_departement
      ? dataSelected?.id_departement
      : "",

    serviceSelected: dataSelected?.id_service ? dataSelected?.id_service : "",

    selectedGroupe: dataSelected?.id_groupe ? dataSelected?.id_groupe : "",
    uploadFile: {},
    openSelect2: false,
  });
  const inputFileRef = useRef(null);
  const [severite, setSeverite] = useState([]);
  const [status, setStatus] = useState([]);
  const [type, setType] = useState([]);
  const [Source, setSource] = useState([]);
  const [Voie, setVoie] = useState([]);
  const [Produit, setProduit] = useState([]);
  const [sousProduit, setsousProduit] = useState([]);
  const [groupe, setGroupe] = useState([]);
  const [Dep, setDep] = useState([]);
  const [service, setService] = useState([]);
  const [flux, setFLux] = useState([]);
  const success = useSelector((state) => state.ticketR.success);
  const successG = useSelector((state) => state.ticketR.successG);

  const error = useSelector((state) => state.ticketR.error);
  const { user } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const getVoie = useCallback((controll) => {
    setState({ ...state, loading: true });

    axiosInstance
      .get("Voie")
      .then((result) => {
        setState({ ...state, loading: false });
        setVoie(result.data);
      })
      .catch((error) => {
        if (error.name === "AbortError") return;
        setState({ ...state, loading: false });
      });
  }, []);
  const getFlux = useCallback((controll) => {
    setState({ ...state, loading: true });

    axiosInstance
      .get("Flux")
      .then((result) => {
        setState({ ...state, loading: false });
        setFLux(result.data);
      })
      .catch((error) => {
        setState({ ...state, loading: false });
        console.log("error", error);
      });
  }, []);

  const getType = useCallback((controll) => {
    setState({ ...state, loading: true });

    axiosInstance
      .get("Type")
      .then((result) => {
        setState({ ...state, loading: false });

        setType(result.data);
      })
      .catch((error) => {
        setState({ ...state, loading: false });
        console.log("error", error);
      });
  }, []);

  const getSource = useCallback((controll) => {
    axiosInstance
      .get("Source")
      .then((result) => {
        setSource(result.data);
      })
      .catch((error) => {
        setState({ ...state, loading: false });
        console.log("error", error);
      });
  }, []);

  const getStatus = useCallback((controll) => {
    setState({ ...state, loading: true });
    axiosInstance
      .get("Status")
      .then((result) => {
        setState({ ...state, loading: false });
        if (Object.entries(dataSelected).length > 5) {
          if (dataSelected.Id_Status === 6) {
            setStatus(result.data);
          } else setStatus(result.data.filter((item) => item.Id_Status !== 6));
        } else {
          if (edited) {
            setStatus(result.data.filter((item) => item.Id_Status !== 6));
          } else {
            setStatus(
              result.data.filter(
                (item) => item.Id_Status !== 5 && item.Id_Status !== 6
              )
            );
          }
        }
      })
      .catch((error) => {
        setState({ ...state, loading: false });
        console.log("error", error);
      });
  }, []);

  const getPriorite = useCallback((controll) => {
    setState({ ...state, loading: true });

    axiosInstance
      .get("Priorite")
      .then((result) => {
        setState({ ...state, loading: false });

        setSeverite(result.data);
      })
      .catch((error) => {
        setState({ ...state, loading: false });
        console.log("error", error);
      });
  }, []);

  const getCategorie = useCallback((controll) => {
    axiosInstance
      .get("Categorie")
      .then((result) => {
        setProduit(result.data);
      })
      .catch((error) => {
        setState({ ...state, loading: false });
        console.log("error", error);
      });
  }, []);

  const getSousCategorie = useCallback(() => {
    axiosInstance
      .get(`SousCat/${Number(state.selectedProduit.id)}`)
      .then((result) => {
        setsousProduit(result.data);
      })
      .catch((error) => {
        setState({ ...state, loading: false });
        console.log("error", error);
      });
  }, [state.selectedProduit.id]);

  const getGroupe = useCallback(() => {
    setState({ ...state, loading: true });

    axiosInstance
      .get(`groupe/${state.serviceSelected}`)
      .then((result) => {
        setState({ ...state, loading: false });

        setGroupe(result.data);
      })
      .catch((error) => {
        setState({ ...state, loading: false });
        console.log("error", error);
      });
  }, [state.serviceSelected]);

  const getDep = useCallback(() => {
    setState({ ...state, loading: true });

    axiosInstance
      .get("departement")
      .then((result) => {
        setState({ ...state, loading: false });
        setDep(result.data);
      })
      .catch((error) => {
        setState({ ...state, loading: false });
        console.log("error", error);
      });
  }, []);

  const getService = useCallback(() => {
    axiosInstance
      .get(`Service_departement/${state.selectedDep}`)
      .then((result) => {
        setService(result.data);
      })
      .catch((error) => {
        setState({ ...state, loading: false });
        console.log("error", error);
      });
  }, [state.selectedDep, dataSelected?.id_service]);

  useEffect(() => {
    const controll = new AbortController();
    if (state.selectedProduit.id > 0 || dataSelected?.Id_Cat) {
      getSousCategorie(controll);
    }
    return () => {
      controll.abort();
    };
  }, [state.selectedProduit.id]);
  useEffect(() => {
    const controll = new AbortController();
    if (state.selectedDep > 0 || dataSelected?.id_service) {
      getService(controll);
      return () => {
        controll.abort();
      };
    }
  }, [state.selectedDep]);

  useEffect(() => {
    const controll = new AbortController();
    if (state.serviceSelected > 0 || dataSelected?.id_groupe) {
      getGroupe(controll);

      return () => {
        controll.abort();
      };
    }
  }, [state.serviceSelected]);

  useEffect(() => {
    const controll = new AbortController();
    getVoie(controll);
    getSource(controll);
    getType(controll);
    getStatus(controll);
    getCategorie(controll);
    getPriorite(controll);
    getDep(controll);
    getFlux(controll);
    return () => {
      controll.abort();
    };
  }, []);
  useEffect(() => {
    console.log(
      "dataSelected",
      dataSelected,
      "general",
      general,
      "success",
      success,
      "successG",
      successG
    );
    if (
      success.length > 0 ||
      (dataSelected && general && successG.length > 0)
    ) {
      close(false);
    }
  }, [success, successG]);

  const handleInputChange = (q) => {
    if (q === null || q === "" || q === undefined) setParamsString("");
    else setParamsString(q);
  };

  const onClickValidate = () => {
    if (
      state.selectedCLient.label.length === 0 ||
      state.selectedSeverite.length === 0 ||
      state.Sujet.length === 0 ||
      state.perimetre.length === 0 ||
      state.selectedVoie.length === 0 ||
      (state.selectedType.length === 0 && edited) ||
      (state.seelectedStatut.length === 0 && edited)
    ) {
      setSelectedValidate(
        state.selectedCLient.label.length === 0 ? true : false
      );

      setState({
        ...state,
        perimetreValidate: state.perimetre.length === 0 ? true : false,
        statusValidate:
          state.seelectedStatut.length === 0 && edited ? true : false,
        typeValidate: state.selectedType.length === 0 && edited ? true : false,
        severiteValidate: state.selectedSeverite.length === 0 ? true : false,
        sujetValidate: state.Sujet.length === 0 ? true : false,
        voieValidate: state.selectedVoie.length === 0 ? true : false,
      });
    } else {
      setSelectedValidate(false);
      setState({
        ...state,
        perimetreValidate: false,
        sujetValidate: false,
        severiteValidate: false,
        voieValidate: false,
        statusValidate: false,
        typeValidate: false,
      });
      if (Object.entries(dataSelected).length > 5) {
        setClicked(!clicked);
        //else {
        dispatch(
          updateTicket({
            Id_Tckt: dataSelected?.Id_Tckt,
            code_client: state.selectedCLient.label,
            Id_Pers: user.Id_Pers,
            Flux: Number(state.perimetre) > 0 ? Number(state.perimetre) : "",
            Id_Priorite:
              Number(state.selectedSeverite) > 0
                ? Number(state.selectedSeverite)
                : "",
            Id_Status:
              Number(state.seelectedStatut) > 0
                ? Number(state.seelectedStatut)
                : "",
            Id_Type:
              Number(state.selectedType) > 0 ? Number(state.selectedType) : "",
            Id_Cat:
              Number(state.selectedProduit.id) > 0
                ? Number(state.selectedProduit.id)
                : "",
            Id_Sous_Cat:
              Number(state.sousProduitSelected.id) > 0
                ? Number(state.sousProduitSelected.id)
                : "",
            Id_Voie:
              Number(state.selectedVoie) > 0 ? Number(state.selectedVoie) : "",
            Id_Source:
              Number(state.selectedSource) > 0
                ? Number(state.selectedSource)
                : "",
            Sujet: state.Sujet,
            sla: state.sla,
            Desc_Scis: state.Description,
            Solution: state.solution,
            Commentaires: state.commentaire,
            Id_departement:
              Number(state.selectedDep) > 0 ? Number(state.selectedDep) : "",
            Id_service:
              Number(state.serviceSelected) > 0
                ? Number(state.serviceSelected)
                : "",
            Id_groupe:
              Number(state.selectedGroupe) > 0
                ? Number(state.selectedGroupe)
                : "",
            type: state.uploadFile.type,
            filename: state.uploadFile.name,
            filesize: state.uploadFile.size,
            uri: state.uploadFile.file,
            general: general,
          })
        );
        //}

        setDataSelected(dataSelected);
      } else {
        setClicked(!clicked);
        dispatch(
          addTicket({
            code_client: state.selectedCLient.label,
            Id_Pers: user.Id_Pers,
            Flux: Number(state.perimetre) > 0 ? Number(state.perimetre) : "",
            Id_Priorite:
              Number(state.selectedSeverite) > 0
                ? Number(state.selectedSeverite)
                : "",
            Id_Status:
              Number(state.seelectedStatut) > 0
                ? Number(state.seelectedStatut)
                : "",
            Id_Type:
              Number(state.selectedType) > 0 ? Number(state.selectedType) : "",
            Id_Cat:
              Number(state.selectedProduit.id) > 0
                ? Number(state.selectedProduit.id)
                : "",
            Id_Sous_Cat:
              Number(state.sousProduitSelected.id) > 0
                ? Number(state.sousProduitSelected.id)
                : "",
            Id_Voie:
              Number(state.selectedVoie) > 0 ? Number(state.selectedVoie) : "",
            Id_Source:
              Number(state.selectedSource) > 0
                ? Number(state.selectedSource)
                : "",
            Sujet: state.Sujet,
            sla: state.sla,
            Desc_Scis: state.Description,
            Solution: state.solution,
            Commentaires: state.commentaire,
            Id_departement:
              Number(state.selectedDep) > 0 ? Number(state.selectedDep) : "",
            Id_service:
              Number(state.serviceSelected) > 0
                ? Number(state.serviceSelected)
                : "",
            Id_groupe:
              Number(state.selectedGroupe) > 0
                ? Number(state.selectedGroupe)
                : "",
            type: state.uploadFile.type,
            filename: state.uploadFile.name,
            filesize: state.uploadFile.size,
            uri: state.uploadFile.file,
            general: general ? true : false,
          })
        );

        setDataSelected({});
      }
    }
  };

  console.log("3o9eu", dataSelected, edited);
  return (
    <div className="">
      {!generalBorn && !edited && (
        <div className="flex items-center justify-between ">
          <div className="flex items-center pt-2 justify-around">
            <div
              className="text-gray-500 dark:text-white pl-5
            font-light text-lg flex items-center justify-center"
            >
              {dataSelected?.code_ticket ? (
                <AiOutlineEdit
                  className="mr-2 text-green-400 font-semibold  dark:text-green-500"
                  size={30}
                />
              ) : (
                <AiOutlineFileAdd
                  className="mr-2 text-green-400 font-semibold  dark:text-green-500"
                  size={30}
                />
              )}
              {dataSelected?.code_ticket
                ? t("All.ModifierTick") + `${dataSelected.code_ticket}`
                : t("All.Nouveau")}
            </div>

            {
              <div className="flex ml-12 text-lg text-red-500 animate-pulse font-semibold">
                {error}
              </div>
            }
          </div>

          {/*error.length>0&&*/}
          <AiOutlineCloseCircle
            onClick={() => {
              close(false);
              setDataSelected({});
            }}
            className="mr-2 text-red-500 mt-2  hover:text-red-300 cursor-pointer font-semibold  dark:text-red-800"
            size={30}
          />
        </div>
      )}
      {
        <div className="grid grid-cols-6 gap-6 w-full p-4  ">
          {/*code first row  */}

          <div className="col-span-6 cursor-pointer lg:col-span-2 xl:col-span-2 2xl:col-span-2  ">
            <div>
              <div className="max-w-xl ">
                {Object.keys(dataSelected).includes("Id_Tckt") || edited ? (
                  <Select2
                    dark="900"
                    elementValidate={selectedValidate}
                    setElementValidate={setSelectedValidate}
                    required={true}
                    update={true}
                    onChnageSearch={(e) => {
                      handleInputChange(e);
                    }}
                    loading={loading}
                    extra={true}
                    data={data}
                    selectPlaceholder={t("Ticket.SelectionnerCode")}
                    name={
                      edited
                        ? t("Ticket.CodeClient")
                        : t("Ticket.CodeClient") + "*"
                    }
                    defaultItem={
                      Object.values(state.selectedCLient)[0].length === 0 ||
                      Object.values(state.selectedCLient)[1].length === 0 ||
                      Object.values(state.selectedCLient)[2].length === 0
                        ? null
                        : state.selectedCLient ?? null
                    }
                    setSelectItem={(e) => {
                      if (e === "" || e === undefined) {
                        edited &&
                          setDataGroupe({
                            ...stateBlocForm,
                            code_client: "",
                          });

                        setState({
                          ...state,
                          selectedCLient: { id: 0, label: "", labelExtra: "" },
                        });
                      } else {
                        edited &&
                          setDataGroupe({
                            ...stateBlocForm,
                            code_client: e.label,
                            labelExtra: e.labelExtra,
                          });

                        setState({
                          ...state,
                          selectedCLient: {
                            id: Number(e.id),
                            label: e.label,
                            labelExtra: e.labelExtra,
                          },
                        });
                      }
                    }}
                  />
                ) : (
                  <>
                    <label
                      htmlFor=" Client"
                      className="block text-sm ml-2 mt-2  font-medium capitalize text-gray-900 dark:text-white"
                    >
                      {t("Ticket.CodeClient")}*
                    </label>
                    <MultiSelectComponent
                      prefix="CL"
                      setSelected={(e) => {
                        if (e === "" || e === undefined) {
                          setState({
                            ...state,
                            selectedCLient: {
                              id: 0,
                              label: "",
                              labelExtra: "",
                            },
                          });
                        } else {
                          setState({
                            ...state,
                            selectedCLient: {
                              id: null,
                              label: e,
                              labelExtra: null,
                            },
                          });
                        }
                      }}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                      data={data.map((d) => ({
                        id: `${[Object.values(d)[0]]}`,
                        value: `${[Object.values(d)[1]]}`,
                        label: `${[Object.values(d)[1]]}`,
                        labelD: `${[Object.values(d)[2]]}`,
                      }))}
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="col-span-6 cursor-pointer lg:col-span-2 xl:col-span-2 2xl:col-span-2   flex mt-1.5 ">
            <div className="  w-1/2 ">
              <SelectComponent
                severiteValidate={state.perimetreValidate}
                setSeveriteValidate={(e) =>
                  setState({ ...state, perimetreValidate: e })
                }
                defaultValue={state.perimetre}
                required={edited ? false : true}
                data={flux}
                nom={edited ? t("Bord.Périmètre") : t("Bord.Périmètre") + "*"}
                selected={state.perimetre}
                onChange={(e) => {
                  {
                    edited && setDataGroupe({ ...stateBlocForm, Flux: e });
                  }
                  setState({ ...state, perimetre: e });
                }}
              />
            </div>

            <div className=" ml-2 w-1/2  ">
              <SelectComponent
                severiteValidate={state.statusValidate}
                setSeveriteValidate={(e) =>
                  setState({ ...state, statusValidate: e })
                }
                defaultValue={state.seelectedStatut}
                required={false}
                data={status}
                nom="Statut"
                selected={state.seelectedStatut}
                onChange={(e) => {
                  {
                    edited && setDataGroupe({ ...stateBlocForm, Id_Status: e });
                  }

                  setState({ ...state, seelectedStatut: e });
                }}
              />
            </div>
          </div>

          <div className="col-span-6 cursor-pointer lg:col-span-2 xl:col-span-2 2xl:col-span-2 mt-1.5  ">
            <SelectComponent
              defaultValue={state.selectedSeverite}
              severiteValidate={state.severiteValidate}
              setSeveriteValidate={(e) =>
                setState({ ...state, severiteValidate: e })
              }
              required={edited ? false : true}
              data={severite}
              nom={edited ? t("All.Sévérité") : t("All.Sévérité") + "*"}
              selected={state.selectedSeverite}
              onChange={(e) => {
                {
                  edited && setDataGroupe({ ...stateBlocForm, Id_Priorite: e });
                }

                setState({ ...state, selectedSeverite: e });
              }}
            />
          </div>

          {/*code second row debut */}

          <div className="col-span-6 cursor-pointer lg:col-span-2 xl:col-span-2 2xl:col-span-2 mt-1 ">
            <SelectComponent
              defaultValue={state.selectedSource}
              required={false}
              data={Source}
              nom={t("All.Source")}
              selected={state.selectedSource}
              onChange={(e) => {
                {
                  edited && setDataGroupe({ ...stateBlocForm, Id_Source: e });
                }

                setState({ ...state, selectedSource: e });
              }}
            />
          </div>

          <div className="col-span-6 cursor-pointer lg:col-span-2 xl:col-span-2 2xl:col-span-2   ">
            <Select2
              dark="900"
              extra={false}
              data={Produit}
              selectPlaceholder={t("Ticket.SelectionnerFamille")}
              name={t("Ticket.FamilleProd")}
              defaultItem={
                Object.values(state.selectedProduit)[0].length === 0 ||
                Object.values(state.selectedProduit)[1].length === 0
                  ? null
                  : state.selectedProduit ?? null
              }
              setSelectItem={(e) => {
                if (e === "" || e === undefined) {
                  setState({
                    ...state,
                    selectedProduit: { id: "", label: "" },
                    sousProduitSelected: { id: "", label: "" },
                  });
                  {
                    edited &&
                      setDataGroupe({
                        ...stateBlocForm,
                        Id_Cat: "",
                        Id_Sous_Cat: "",
                      });
                  }
                } else {
                  {
                    edited &&
                      setDataGroupe({
                        ...stateBlocForm,
                        Id_Cat: e.id,
                        Id_Sous_Cat: "",
                      });
                  }

                  setState({
                    ...state,
                    selectedProduit: {
                      id: Number(e.id),
                      label: e.label,
                      sousProduitSelected: { id: "", label: "" },
                    },
                  });
                }
              }}
            />
          </div>

          <div className="col-span-6  lg:col-span-2 xl:col-span-2 2xl:col-span-2 z-30 cursor-pointer ">
            <Select2
              dark="900"
              extra={false}
              update={true}
              disabled={
                Object.values(state.selectedProduit)[0].length === 0
                  ? true
                  : false
              }
              data={sousProduit}
              name={t("Ticket.Produit")}
              selectPlaceholder={t("Ticket.SelectionnerProduit")}
              defaultItem={
                Object.values(state.selectedProduit)[0].length === 0 ||
                Object.values(state.selectedProduit)[1].length === 0 ||
                Object.values(state.sousProduitSelected)[0].length === 0 ||
                Object.values(state.sousProduitSelected)[1].length === 0
                  ? null
                  : state.sousProduitSelected
              }
              setSelectItem={(e) => {
                if (e === "" || e === undefined) {
                  setState({
                    ...state,
                    sousProduitSelected: { id: "", label: "" },
                  });
                  {
                    edited &&
                      setDataGroupe({ ...stateBlocForm, Id_Sous_Cat: "" });
                  }
                } else {
                  {
                    edited &&
                      setDataGroupe({ ...stateBlocForm, Id_Sous_Cat: e.id });
                  }

                  setState({
                    ...state,
                    sousProduitSelected: { id: Number(e.id), label: e.label },
                  });
                }
              }}
            />
          </div>

          {/*code third row debut */}
          <div className="col-span-6 cursor-pointer lg:col-span-2 xl:col-span-2 2xl:col-span-2 ">
            <SelectComponent
              severiteValidate={state.typeValidate}
              setSeveriteValidate={(e) =>
                setState({ ...state, typeValidate: e })
              }
              defaultValue={state.selectedType}
              required={false}
              data={type}
              nom={t("All.Type")}
              selected={state.selectedType}
              onChange={(e) => {
                {
                  edited && setDataGroupe({ ...stateBlocForm, Id_Type: e });
                }

                setState({ ...state, selectedType: e });
              }}
            />
          </div>

          <div className="col-span-6 cursor-pointer lg:col-span-2 xl:col-span-2 2xl:col-span-2">
            <div>
              <SelectComponent
                defaultValue={state.selectedVoie}
                severiteValidate={state.voieValidate}
                setSeveriteValidate={(e) => {
                  setState({ ...state, voieValidate: e });
                }}
                required={edited ? false : true}
                data={Voie}
                nom={edited ? t("Ticket.Voie") : t("Ticket.Voie") + "*"}
                selected={state.selectedVoie}
                onChange={(e) => {
                  {
                    edited && setDataGroupe({ ...stateBlocForm, Id_Voie: e });
                  }

                  setState({ ...state, selectedVoie: e });
                }}
              />
            </div>
          </div>

          <div className="col-span-6 cursor-pointer lg:col-span-2 xl:col-span-2 2xl:col-span-2  ">
            <label
              htmlFor="SLA"
              className=" text-sm ml-1 mb-1  capitalize dark:text-white font-medium text-gray-700"
            >
              {t("All.SLA")}
            </label>

            <Datech
              defaultValues={state.sla}
              mode={true}
              setValues={(e) => {
                {
                  edited && setDataGroupe({ ...stateBlocForm, sla: e });
                }

                setState({ ...state, sla: e });
              }}
            />
          </div>

          {/*code fourth row debut */}

          <div className=" col-span-3 cursor-pointer    ">
            <TextAreaComponent
              resize={false}
              numbreRow={2}
              setSujetValidate={(e) => {
                setState({ ...state, sujetValidate: e });
              }}
              sujetValidate={state.sujetValidate}
              type={edited ? false : true}
              nom={edited ? t("Client.Sujet") : t("Client.Sujet") + "*"}
              placeholder={t("Ticket.SujetTick") + "..."}
              onChange={(e) => {
                {
                  edited && setDataGroupe({ ...stateBlocForm, Sujet: e });
                }

                setState({ ...state, Sujet: e });
              }}
              selected={state.Sujet}
            />
          </div>

          <div className=" col-span-3    ">
            <TextAreaComponent
              resize={true}
              numbreRow={2}
              type={false}
              nom="Description"
              placeholder={t("Ticket.DescriptionTicket") + "..."}
              onChange={(e) => {
                {
                  edited && setDataGroupe({ ...stateBlocForm, Desc_Scis: e });
                }

                setState({ ...state, Description: e });
              }}
              selected={state.Description}
            />
          </div>

          {!edited || general ? (
            <div className=" col-span-3 ">
              <TextAreaComponent
                resize={true}
                numbreRow={2}
                type={false}
                nom="Intervention / Solution"
                placeholder="Intervention / Solution..."
                onChange={(e) => {
                  setState({ ...state, solution: e });

                  {
                    edited && setDataGroupe({ ...stateBlocForm, Solution: e });
                  }
                }}
                selected={state.solution}
              />
            </div>
          ) : (
            <div className=" col-span-6 ">
              <TextAreaComponent
                resize={true}
                numbreRow={2}
                type={false}
                nom="Intervention / Solution"
                placeholder="Intervention / Solution..."
                onChange={(e) => {
                  setState({ ...state, solution: e });
                }}
                selected={state.solution}
              />
            </div>
          )}

          {(!edited || general) && (
            <div className=" col-span-3 ">
              <TextAreaComponent
                resize={true}
                numbreRow={2}
                type={false}
                nom={t("Ticket.Commentaire")}
                placeholder={t("Ticket.EcrireCom") + "..."}
                onChange={(e) => {
                  setState({ ...state, commentaire: e });
                  {
                    edited &&
                      setDataGroupe({ ...stateBlocForm, Commentaires: e });
                  }
                }}
                selected={state.commentaire}
              />
            </div>
          )}

          {(!edited || general) && (
            <div className=" border-gray-200 border col-span-6 " />
          )}
          {(!edited || general) && (
            <div className="col-span-6 cursor-pointer lg:col-span-2 xl:col-span-2 2xl:col-span-2">
              <SelectComponent
                defaultValue={state.selectedDep}
                data={Dep}
                nom={t("Ticket.DéparConcerné")}
                selected={state.selectedDep}
                onChange={(e) => {
                  if (e === "") {
                    setState({ ...state, selectedDep: e, serviceSelected: "" });
                  } else {
                    setState({ ...state, selectedDep: e });

                    {
                      edited &&
                        setDataGroupe({ ...stateBlocForm, Id_departement: e });
                    }
                  }
                }}
              />
            </div>
          )}

          {(!edited || general) && (
            <div className="col-span-6 cursor-pointer lg:col-span-2 xl:col-span-2 2xl:col-span-2 ">
              <SelectComponent
                disabled={state.selectedDep > 0 ? false : true}
                defaultValue={state.serviceSelected}
                data={service}
                nom={t("Ticket.ServiceConcerné")}
                selected={state.serviceSelected}
                onChange={(e) => {
                  if (e === "") {
                    setState({
                      ...state,
                      serviceSelected: e,
                      selectedGroupe: "",
                    });
                  } else {
                    setState({ ...state, serviceSelected: e });
                    {
                      edited &&
                        setDataGroupe({ ...stateBlocForm, Id_service: e });
                    }
                  }
                }}
              />
            </div>
          )}
          {(!edited || general) && (
            <div className="col-span-6 cursor-pointer lg:col-span-2 xl:col-span-2 2xl:col-span-2  ">
              <SelectComponent
                disabled={state.serviceSelected > 0 ? false : true}
                defaultValue={state.selectedGroupe}
                data={groupe}
                nom={t("Ticket.GroupeConcerné")}
                selected={state.selectedGroupe}
                onChange={(e) => {
                  setState({ ...state, selectedGroupe: e });
                  {
                    edited && setDataGroupe({ ...stateBlocForm, Id_groupe: e });
                  }
                }}
              />
            </div>
          )}
          {/* end grid */}
        </div>
        //)
      }

      {!generalBorn && !edited && (
        <div
          className={classNames(
            Object.entries(dataSelected).length === 0
              ? "px-4  space-x-4  dark:bg-gray-900 xl:flex justify-between  sm:px-6 "
              : "px-4  space-x-4  dark:bg-gray-900 xl:flex justify-center sm:px-6"
          )}
        >
          {Object.entries(dataSelected).length === 0 ? (
            <div className="max-w-md flex items-center">
              <label
                className={classNames(
                  state.uploadFile ? "" : "hover:bg-gray-300 ",
                  "bg-gray-400  items-center  rounded-full shadow  cursor-pointer text-lg   border border-transparent   py-2 px-4 flex justify-center  font-medium text-white "
                )}
              >
                <MdAttachFile size={20} className="text-white " />

                <input
                  ref={inputFileRef}
                  type="file"
                  className="hidden"
                  onChange={(e) => {
                    let reader = new FileReader();
                    let file = e.target.files[0];
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                      if (file.size > 4e6) {
                        window.alert("Please upload a file smaller than 4 MB");
                        return false;
                      }
                      setState({
                        ...state,
                        uploadFile: {
                          name: file.name,
                          type: file.type,
                          size: file.size,
                          file: file,
                        },
                      });
                    };
                    reader.onerror = function () {
                      setState({ ...state, uploadFile: {} });
                    };
                    inputFileRef.current.value = null;
                  }}
                />
              </label>

              <div className="ml-2 italic max-w-16 text-base  font-light text-gray-500 overflow-x-visible">
                {state.uploadFile?.name?.length > 0 ? (
                  <div className="underline ">{state.uploadFile?.name}</div>
                ) : (
                  <div>{t("Client.Pièce")}</div>
                )}
              </div>

              {state.uploadFile.name && (
                <AiOutlineCloseCircle
                  size={25}
                  onClick={() => setState({ ...state, uploadFile: {} })}
                  className=" text-gray-500 ml-2 over:opacity-50 cursor-pointer "
                />
              )}
            </div>
          ) : null}
          <div className="px-4 py-3 space-x-4  dark:bg-gray-900 justify-center flex sm:px-6 ">
            <button
              className="text-white  hover:bg-gray-200 hover:translate-x-8  w-28 h-10 text-lg bg-gray-400 items-center flex justify-center py-2 px-4 border  rounded-full "
              onClick={() => {
                close(false);
                setDataSelected({});
              }}
            >
              {t("All.Annuler")}
            </button>

            <button
              onClick={() => {
                onClickValidate();
              }}
              disabled={
                state.selectedCLient.id === 0 ||
                state.selectedSeverite.length === 0 ||
                state.Sujet.length === 0 ||
                state.perimetre.length === 0 ||
                state.selectedVoie.length === 0 ||
                (state.selectedType.length === 0 && edited) ||
                (state.seelectedStatut.length === 0 && edited)
              }
              className={classNames(
                state.selectedCLient.id === 0 ||
                  state.selectedSeverite.length === 0 ||
                  state.Sujet.length === 0 ||
                  state.perimetre.length === 0 ||
                  state.selectedVoie.length === 0 ||
                  (state.selectedType.length === 0 && edited) ||
                  (state.seelectedStatut.length === 0 && edited)
                  ? "cursor-not-allowed bg-gray-500 text-white  hover:translate-x-8  w-28 h-10 text-lg  items-center flex justify-center py-2 px-4 border  rounded-full "
                  : "text-white hover:bg-green-200 hover:translate-x-8  w-28 h-10 text-lg bg-green-400 items-center flex justify-center py-2 px-4 border  rounded-full "
              )}
            >
              <div>
                {!clicked &&
                ((general && dataSelected && successG) ||
                  success.length === 0) ? (
                  <div>{t("All.Valider")}</div>
                ) : (
                  <div className="flex">
                    <div className=" justify-center flex items-center">
                      <div className="animate-spin">
                        <CgSpinner size={30} color="white" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    error: state.error,
    loading: state.loading,
    perPage: state.perPage,
    success: state.success,
    successG: state.successG,
  };
};

export default connect(mapStateToProps, { addTicket, updateTicket })(
  FormTicket
);
