import {
  ADD_FOlder,
  ADD_FOlder_Failed,
  ADD_FOlder_SUCCESS,
  Get_FOlder,Get_FOlder_Failed, GET_FOLDER_SELECTED
} from "./types";
import FoldSercvices from "../services/FoldSercvices";
import { SET_MESSAGE } from "../../actions/types";
export const getFolder = () => (dispatch) => {

  return FoldSercvices.getFoldd().then(
    (res) => {
      dispatch({
        type: Get_FOlder,
        payload: res 
      });

      return Promise.resolve();
    },
    (error) => {
      const message = error.erreur || error.toString();

      dispatch({
        type: Get_FOlder_Failed,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: "Problème de connexion, merci de réessayer plus tard.",
      });

      return Promise.reject();
    }
  );
};


export const addFolder = (name,idPers,couleur) => (dispatch) => {

  return FoldSercvices.addFolder(name,idPers,couleur).then(
    (res) => {
      dispatch({
        type: ADD_FOlder,
        payload: res 
      });
      dispatch({
        type:ADD_FOlder_SUCCESS
      }) 
      return Promise.resolve();
    },
    (error) => {
      const message = error.erreur || error.toString();

      dispatch({
        type: ADD_FOlder_Failed,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: "Problème de connexion, merci de réessayer plus tard.",
      });

      return Promise.reject();
    }
  );
};
export const setFolder = () => (dispatch) => {

  return dispatch({
        type: GET_FOLDER_SELECTED});
      
  
};

