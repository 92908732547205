import React, { useState, useEffect } from "react";
import { classNames } from "../../Table/ListTable";
import {
  AiFillCloseCircle,
  AiOutlineFolderAdd,
  AiOutlineMinus,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import ReactLoading from "react-loading";
import { MdAttachFile } from "react-icons/md";

import { IoAddOutline } from "react-icons/io5";
import { useSelector, useDispatch, connect } from "react-redux";
import {
  addFolder,
  getFolder,
  setFolder,
} from "../../../JS/Folder/actions/FoldA";
import FormTicket from "./FormTicket";
import { groupeTickett } from "../../../JS/Ticket/actions/TicketA";
import { ADD_FOlder_SUCCESS_OUT } from "../../../JS/Folder/actions/types";
import { TextAreaComponent, Select2 } from "../../utils";
import { t } from "i18next";
import axiosInstance from "../../../JS/axiosInstance";

function GestionGroupeTicket({ data, close, type }) {
  const dispatch = useDispatch();
  const foldData = useSelector((state) => state.foldR.Fold);
  const success = useSelector((state) => state.foldR.success);
  const [error, setError] = useState(
    type === "remettre" || type === "delete" ? false : true
  );
  const [foldName, setFoldName] = useState("");
  const { user } = useSelector((state) => state.auth);
  const [hidden, setHidden] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [dataInterne, setDataInterne] = useState([]);
  const [dataAgent, setDataAgent] = useState([]);
  const [state, setState] = useState({
    commentaire: "",
    commentaireSelcted: { id: "", label: "", labelExtra: "" },
    uploadFile: {
      file: "",
      filename: "",
      type: "",
      filesize: "",
    },
    commentaireValidate: false,
    foldSelect: { id: "", label: "" },
    agent: { id: "", label: "" },
    ticket: { id: "", label: "", labelExtra: "" },
    stateBlocForm: {
      code_client: "",
      Flux: "",
      Id_Cat: "",
      Id_Sous_Cat: "",
      Id_Status: "",
      Id_Priorite: "",
      Id_Source: "",
      Id_Voie: "",
      sla: "",
      Id_Type: "",
      Sujet: "",
      Desc_Scis: "",
      Solution: "",
    },
  });

  const [tabName, setTabName] = useState({ name: "Modifer", current: true });

  var array = data.map((r) => r.original.Id_Tckt);
  var array2 = data.map((r) => r.original);
  function getSubMenu() {
    console.log(type);
    switch (type) {
      case "Déplacer":
        return (
          <div className="flex flex-col dark:bg-gray-900">
            {hidden ? (
              <div className=" flex flex-col dark:bg-gray-900 ">
                <div className=" ml-3  dark:bg-gray-900 dark:text-white block ">
                  {t("Ticket.EntrerDossier")}
                </div>
                <div className="flex justify-between relative items-center">
                  <div className="w-full ">
                    <input
                      required
                      className="dark:bg-gray-800 dark:text-white w-full border  border-gray-200 rounded-md dark:border-white dark:border outline-none"
                      type="text"
                      placeholder={t("Ticket.Nouveaudoss")}
                      onChange={(e) => setFoldName(e.target.value)}
                    />
                  </div>
                  <label>
                    <input
                      disabled={clicked}
                      onClick={(e) => {
                        e.preventDefault();
                        if (foldName.length > 0) {
                          dispatch(addFolder(foldName, user.Id_Pers, ""));
                          setClicked(true);
                        }
                      }}
                      type="submit"
                      className="hidden"
                    />
                    <div className="ml-2 items-center flex hover:bg-purple-500 bg-purple-400 cursor-pointer rounded-full dark:bg-purple-700  dark:hover:bg-purple-500 p-1">
                      <AiOutlineFolderAdd
                        aria-selected="true"
                        className="h-7 w-7   text-white  "
                      />
                    </div>
                  </label>
                </div>
              </div>
            ) : (
              success.length > 0 && (
                <div className="block m-1 animate-pulse dark:bg-gray-900">
                  <div className="text-lg text-green-400 dark:text-green-600 capitalize ">
                    {success}
                  </div>
                </div>
              )
            )}

            <div className=" flex items-center  justify-between">
              <div className="w-full">
                <Select2
                  selectPlaceholder={t("Ticket.SelectDossier")}
                  update={success.length > 0 ? true : false}
                  disabled={hidden}
                  defaultItem={
                    Object.values(state.foldSelect)[0].length === 0 ||
                    Object.values(state.foldSelect)[1].length === 0
                      ? null
                      : state.foldSelect ?? null
                  }
                  setSelectItem={(e) => {
                    if (e === "") {
                      setState({ ...state, foldSelect: { id: "", label: "" } });
                      setError(true);
                    } else {
                      setError(false);
                      setState({
                        ...state,
                        foldSelect: { id: Number(e.id), label: e.label },
                      });
                    }
                  }}
                  name={t("All.Dossier")}
                  data={foldData}
                />
              </div>

              <div
                onClick={() => setHidden(!hidden)}
                className=" flex mt-5    hover:bg-purple-500 bg-purple-400  cursor-pointer rounded-full dark:bg-purple-700  dark:hover:bg-purple-500 p-1 "
              >
                {!hidden ? (
                  <IoAddOutline
                    aria-selected="true"
                    className="h-7 w-7   text-white  "
                  />
                ) : (
                  <AiOutlineMinus
                    aria-selected="true"
                    className="h-7 w-7   text-white  "
                  />
                )}
              </div>
            </div>
          </div>
        );
      case "Modifier":
        return (
          <div className="m-1">
            {/* <div className="md:hidden sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                value={tabName.name}
                id="tabs"
                name="tabs"
                className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                onChange={(e) => {
                  setError(true);
                  setTabName({ name: e.target.value, current: true });
                }}
              >
                <option key={`select_key_1`}>Modifier</option>
                <option key={`select_key_2`}>Commenter</option>
                <option key={`select_key_3`}>Assigner</option>
                <option key={`select_key_4`}>Fusionner</option>
              </select>
            </div> */}
            <div className="hidden sm:block">
              <div className="border-b cursor-pointer border-gray-200">
                <div className="-mb-px flex" aria-label="Tabs">
                  <div
                    onClick={() => {
                      setError(true);

                      setTabName({
                        name: "Modifer",
                        current: true,
                      });
                    }}
                    key={`list_key_1`}
                    className={classNames(
                      tabName.name === "Modifer" && tabName.current
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
                    )}
                    aria-current="page"
                  >
                    {t("All.Modifier")}
                  </div>

                  <div
                    onClick={() => {
                      console.log(error);
                      if (
                        state.commentaire === "" &&
                        state.commentaireSelcted.id.length === 0
                      )
                        setError(true);

                      setTabName({
                        name: "Commenter",
                        current: true,
                      });
                    }}
                    key={`list_key_2`}
                    className={classNames(
                      tabName.name === "Commenter" && tabName.current
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
                    )}
                    aria-current="page"
                  >
                    {t("All.Commenter")}
                  </div>

                  <div
                    onClick={() => {
                      setError(true);
                      setTabName({
                        name: "Assinger",
                        current: true,
                      });
                    }}
                    key={`list_key_3`}
                    className={classNames(
                      tabName.name === "Assinger" && tabName.current
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
                    )}
                    aria-current="page"
                  >
                    {t("Ticket.Assigner")}
                  </div>

                  <div
                    onClick={() =>
                      setTabName({
                        name: "Fusionner",
                        current: true,
                      })
                    }
                    key={`list_key_4`}
                    className={classNames(
                      tabName.name === "Fusionner" && tabName.current
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
                    )}
                    aria-current="page"
                  >
                    {t("Ticket.Fusionner")}
                  </div>
                </div>

                {getSubSubMenu()}
              </div>
            </div>
          </div>
        );
      case "remettre":
        return (
          <div className="dark:text-white normal-case text-gray-500  text-2xl">
            {t("Ticket.vouloir")} {type}
            {t("Ticket.TickDessus")}?
          </div>
        );
      case "Commenter":
        return (
          <div className=" flex flex-col ">
            <div className="flex flex-col  mt-3 ">
              {dataInterne.length > 0 ? (
                <div className="flex">
                  <div className="w-full">
                    <Select2
                      selectPlaceholder={t(
                        "Ticket.SelectionnerBaseConnaissance"
                      )}
                      onlyLabel={true}
                      required={true}
                      extra={true}
                      update={success.length > 0 ? true : false}
                      defaultItem={
                        Object.values(state.commentaireSelcted)[0].length ===
                          0 ||
                        Object.values(state.commentaireSelcted)[1].length ===
                          0 ||
                        Object.values(state.commentaireSelcted)[2].length === 0
                          ? null
                          : state.commentaireSelcted ?? null
                      }
                      setSelectItem={(e) => {
                        if (e === "" || e === null) {
                          setState({
                            ...state,
                            commentaire: "",
                            commentaireSelcted: {
                              id: "",
                              label: "",
                              labelExtra: "",
                            },
                          });
                          setError(true);
                        } else {
                          console.log("here");
                          setError(false);
                          setState({
                            ...state,
                            commentaireSelcted: e,
                            commentaire: e?.labelExtra,
                          });
                        }
                      }}
                      name="Base de connaissance"
                      data={dataInterne}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex justify-center items-center ">
                  <ReactLoading
                    type="bubbles"
                    className="text-gray-500 dark:text-white"
                    color="gray"
                  />
                </div>
              )}
              <div className="mt-2 cursor-pointer    ">
                <TextAreaComponent
                  numbreRow={2}
                  setSujetValidate={(e) =>
                    setState({ ...state, commentaireValidate: e })
                  }
                  sujetValidate={state.commentaireValidate}
                  type={true}
                  nom="Commentaire"
                  onChange={(e) => {
                    if (e.length > 0) {
                      setError(false);
                      setState({ ...state, commentaire: e });
                    } else {
                      setState({ ...state, commentaire: "" });
                      setError(true);
                    }
                  }}
                  selected={state.commentaire}
                />
              </div>
            </div>
            <div className=" m-2 flex items-center justify-between">
              <div className="flex items-center ">
                {state.uploadFile && (
                  <div className="ml-2 italic max-w-16 underline font-light overflow-x-visible">
                    {state.uploadFile?.filename}
                  </div>
                )}
                {state.uploadFile.filename && (
                  <AiOutlineCloseCircle
                    size={25}
                    onClick={() =>
                      setState({
                        ...state,
                        uploadFile: {
                          filename: "",
                          type: "",
                          filesize: "",
                          file: "",
                        },
                      })
                    }
                    className=" text-red-400 ml-2 over:opacity-50 cursor-pointer "
                  />
                )}
              </div>

              <label
                className=" bg-gray-100  rounded-full shadow items-end
   capitalize cursor-pointer text-base  hover:bg-gray-300  border border-transparent p-1  inline-flex   font-medium dark:text-white  "
              >
                <MdAttachFile size={18} className="dark:text-white mr-2" />
                {t("Client.Pièce")}
                <input
                  type="file"
                  className="hidden"
                  onChange={(e) => {
                    let reader = new FileReader();
                    let file = e.target.files[0];
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                      if (file.size > 4e6) {
                        window.alert("Please upload a file smaller than 4 MB");
                        return false;
                      }
                      setState({
                        ...state,
                        uploadFile: {
                          filename: e.target.files[0].name,
                          type: e.target.files[0].type,
                          filesize: e.target.files[0].size,
                          file: file,
                        },
                      });
                    };
                    reader.onerror = function () {
                      setState({
                        ...state,
                        uploadFile: {
                          filename: "",
                          type: "",
                          filesize: "",
                          file: "",
                        },
                      });
                    };
                  }}
                />
              </label>
            </div>
          </div>
        );
      case "delete":
        return (
          <div className="dark:text-white  normal-case text-gray-500  text-2xl">
            {t("Ticket.vouloir")} {t("All.Supprimer")} {t("Ticket.TickDessus")}?
          </div>
        );

      case "Fusionner":
        return (
          <div className="flex items-center justify-center">
            <div className="w-full">
              <Select2
                selectPlaceholder={t("Ticket.SelectionnerTicket")}
                extraTicket={true}
                update={success.length > 0 ? true : false}
                // disabled={hidden}
                defaultItem={
                  Object.values(state.ticket)[0].length === 0 ||
                  Object.values(state.ticket)[1].length === 0 ||
                  Object.values(state.ticket)[2].length === 0
                    ? null
                    : state.ticket ?? null
                }
                setSelectItem={(e) => {
                  if (e === "" || e === null) {
                    setState({
                      ...state,
                      ticket: { id: "", label: "", labelExtra: "" },
                    });
                    setError(true);
                  } else {
                    setError(false);
                    setState({
                      ...state,
                      ticket: {
                        id: Number(e?.id),
                        label: e?.label,
                        labelExtra: e?.labelExtra,
                      },
                    });
                  }
                }}
                name="Ticket"
                data={array2}
              />
            </div>
          </div>
        );
      default:
        return <div />;
    }
  }

  function validGestionBloc() {
    switch (type) {
      case "Déplacer":
        dispatch(
          groupeTickett(
            "ticket/Fusionnerticket",
            state.foldSelect.id,
            null,
            array.toString(),
            user.Id_Pers,
            "Déplacer"
          )
        );
        dispatch(setFolder());

        close();

        break;

      case "Commenter":
        dispatch(
          groupeTickett(
            "ticket/commenterFusionner",
            state.commentaire,
            state.uploadFile,
            array.toString(),
            user.Id_Pers,
            "CommenterGroupe"
          )
        );
        close();

        break;

      case "Fusionner":
        dispatch(
          groupeTickett(
            "ticket/Fusioner_tck",
            state.ticket.id,
            null,
            array.toString(),
            user.Id_Pers,
            "Fusionner"
          )
        );
        dispatch(setFolder());
        close();

        break;
      case "Modifier":
        switch (tabName.name) {
          case "Commenter":
            dispatch(
              groupeTickett(
                "ticket/commenterFusionner",
                state.commentaire,
                state.uploadFile,
                array.toString(),
                user.Id_Pers,
                "CommenterGroupe"
              )
            );
            close();

            break;
          case "Assinger":
            dispatch(
              groupeTickett(
                "ticket/Assigner_block",
                state.agent.id,
                null,
                array.toString(),
                user.Id_Pers,
                "AssingerGroupe"
              )
            );
            close();

            break;
          case "Modifer":
            dispatch(
              groupeTickett(
                "ticket/updateticket_block",
                state.stateBlocForm,
                null,
                array.toString(),
                user.Id_Pers,
                "updateticket_block"
              )
            );
            close();

            break;
          case "Fusionner":
            dispatch(
              groupeTickett(
                "ticket/Fusioner_tck",
                state.ticket.id,
                null,
                array.toString(),
                user.Id_Pers,
                "Fusionner"
              )
            );
            dispatch(setFolder());
            close();
            break;

          default:
            break;
        }

        break;
      case "remettre":
        dispatch(
          groupeTickett(
            "ticket/Remettre_ticket",
            "",
            null,
            array.toString(),
            user.Id_Pers,
            "remettre"
          )
        );
        dispatch(setFolder());
        close();

        break;
      case "delete":
        dispatch(
          groupeTickett(
            "ticket/deleteticket",
            "",
            null,
            array.toString(),
            user.Id_Pers,
            "deleteticket"
          )
        );
        dispatch(setFolder());
        close();

        break;

      default:
        break;
    }
  }
  useEffect(() => {
    if (foldData.length === 0) {
      dispatch(getFolder());
    } else if (success.length > 1 && type === "Déplacer") {
      setTimeout(() => {
        dispatch({ type: ADD_FOlder_SUCCESS_OUT });
      }, 2000);
      setHidden(false);
    }
    return () => {};
  }, [success]);

  const getData = async (abrt) => {
    axiosInstance
      .get("Base_type")
      .then((result) => {
        setDataInterne(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  const getDataAgent = async (abrt) => {
    axiosInstance
      .get("agentsall")
      .then((result) => {
        setDataAgent(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    const abrt = new AbortController();
    if (
      (tabName.name === "Commenter" || type === "Commenter") &&
      dataInterne.length === 0
    ) {
      getData(abrt);
    } else if (tabName.name === "Assinger" && dataAgent.length === 0) {
      getDataAgent(abrt);
    }
  }, [tabName.name]);

  function getSubSubMenu() {
    switch (tabName.name) {
      case "Modifer":
        return (
          <div>
            <FormTicket
              stateBlocForm={state.stateBlocForm}
              setDataGroupe={(e) => {
                if (
                  Object.values(e)[0]?.length > 0 ||
                  Object.values(e)[1]?.length > 0 ||
                  Object.values(e)[2]?.length > 0 ||
                  Object.values(e)[3]?.length > 0 ||
                  Object.values(e)[4]?.length > 0 ||
                  Object.values(e)[5]?.length > 0 ||
                  Object.values(e)[6]?.length > 0 ||
                  Object.values(e)[7]?.length > 0 ||
                  Object.values(e)[8]?.length > 0 ||
                  Object.values(e)[9]?.length > 0 ||
                  Object.values(e)[10]?.length > 0 ||
                  Object.values(e)[11]?.length > 0 ||
                  Object.values(e)[12]?.length > 0
                )
                  setError(false);
                else setError(true);
                setState({ ...state, stateBlocForm: e });
              }}
              edited={true}
              dataSelected={Object}
            />
          </div>
        );

      case "Commenter":
        return (
          <div className=" flex flex-col ">
            <div className="flex flex-col  mt-3 ">
              {dataInterne.length > 0 ? (
                <div className="flex">
                  <div className="w-full">
                    <Select2
                      selectPlaceholder={t(
                        "Ticket.SelectionnerBaseConnaissance"
                      )}
                      onlyLabel={true}
                      extra={true}
                      update={success.length > 0 ? true : false}
                      defaultItem={
                        Object.values(state.commentaireSelcted)[0].length ===
                          0 ||
                        Object.values(state.commentaireSelcted)[1].length ===
                          0 ||
                        Object.values(state.commentaireSelcted)[2].length === 0
                          ? null
                          : state.commentaireSelcted ?? null
                      }
                      setSelectItem={(e) => {
                        if (e === "" || e === null) {
                          setState({
                            ...state,
                            commentaire: "",
                            commentaireSelcted: {
                              id: "",
                              label: "",
                              labelExtra: "",
                            },
                          });
                          setError(true);
                        } else {
                          setError(false);
                          setState({
                            ...state,
                            commentaireSelcted: e,
                            commentaire: e?.labelExtra,
                          });
                        }
                      }}
                      name={t("BaseConnaissance.Base")}
                      data={dataInterne}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex justify-center items-center ">
                  <ReactLoading
                    type="bubbles"
                    className="text-gray-500 dark:text-white"
                    color="gray"
                  />
                </div>
              )}
              <div className="mt-2 cursor-pointer    ">
                <TextAreaComponent
                  numbreRow={2}
                  setSujetValidate={(e) =>
                    setState({ ...state, commentaireValidate: e })
                  }
                  sujetValidate={state.commentaireValidate}
                  type={true}
                  nom="Commentaire"
                  onChange={(e) => {
                    if (e.length > 0) {
                      setError(false);
                      setState({ ...state, commentaire: e });
                    } else {
                      setState({ ...state, commentaire: "" });
                      setError(true);
                    }
                  }}
                  selected={state.commentaire}
                />
              </div>
            </div>
            <div className=" m-2 flex items-center justify-between">
              <div className="flex items-center ">
                {state.uploadFile && (
                  <div className="ml-2 italic max-w-16 underline font-light overflow-x-visible">
                    {state.uploadFile?.filename}
                  </div>
                )}
                {state.uploadFile.filename && (
                  <AiOutlineCloseCircle
                    size={25}
                    onClick={() =>
                      setState({
                        ...state,
                        uploadFile: {
                          file: "",
                          filename: "",
                          filesize: "",
                          type: "",
                        },
                      })
                    }
                    className=" text-red-400 ml-2 over:opacity-50 cursor-pointer "
                  />
                )}
              </div>

              <label
                className=" bg-gray-100  rounded-full shadow items-end
 capitalize cursor-pointer text-base  hover:bg-gray-300  border border-transparent p-1  inline-flex   font-medium dark:text-white  "
              >
                <MdAttachFile size={18} className="dark:text-white mr-2" />
                {t("Client.Pièce")}
                <input
                  type="file"
                  className="hidden"
                  onChange={(e) => {
                    let reader = new FileReader();
                    let file = e.target.files[0];
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                      if (file.size > 4e6) {
                        window.alert("Please upload a file smaller than 4 MB");
                        return false;
                      }
                      setState({
                        ...state,
                        uploadFile: {
                          filename: e.target.files[0].name,
                          type: e.target.files[0].type,
                          filesize: e.target.files[0].size,
                          file: file,
                        },
                      });
                    };
                    reader.onerror = function () {
                      setState({
                        ...state,
                        uploadFile: {
                          file: "",
                          filename: "",
                          filesize: "",
                          type: "",
                        },
                      });
                    };
                  }}
                />
              </label>
            </div>
          </div>
        );

      case "Assinger":
        return (
          <div>
            {dataAgent.length > 0 ? (
              <div className="flex items-center justify-center">
                <div className="w-full">
                  <Select2
                    extra={false}
                    update={success.length > 0 ? true : false}
                    // disabled={hidden}
                    defaultItem={
                      Object.values(state.agent)[0].length === 0 ||
                      Object.values(state.agent)[1].length === 0
                        ? null
                        : state.agent ?? null
                    }
                    selectPlaceholder={t("Ticket.SelectionnerAgent")}
                    setSelectItem={(e) => {
                      if (e === "" || e === null) {
                        setState({ ...state, agent: { id: "", label: "" } });
                        setError(true);
                      } else {
                        setError(false);
                        setState({
                          ...state,
                          agent: { id: Number(e?.id), label: e?.label },
                        });
                      }
                    }}
                    name="Agent"
                    data={dataAgent}
                  />
                </div>
              </div>
            ) : (
              <div className="flex justify-center items-center ">
                <ReactLoading
                  type="bubbles"
                  className="text-gray-500 dark:text-white"
                  color="gray"
                />
              </div>
            )}
          </div>
        );
      case "Fusionner":
        return (
          <div className="flex items-center justify-center">
            <div className="w-full">
              <Select2
                extraTicket={true}
                update={success.length > 0 ? true : false}
                // disabled={hidden}
                defaultItem={
                  Object.values(state.ticket)[0].length === 0 ||
                  Object.values(state.ticket)[1].length === 0 ||
                  Object.values(state.ticket)[2].length === 0
                    ? null
                    : state.ticket ?? null
                }
                selectPlaceholder={t("Ticket.SelectionnerTickets")}
                setSelectItem={(e) => {
                  if (e === "" || e === null) {
                    setState({
                      ...state,
                      ticket: { id: "", label: "", labelExtra: "" },
                    });
                    setError(true);
                  } else {
                    setError(false);
                    setState({
                      ...state,
                      ticket: {
                        id: Number(e?.id),
                        label: e?.label,
                        labelExtra: e?.labelExtra,
                      },
                    });
                  }
                }}
                name="Ticket"
                data={array2}
              />
            </div>
          </div>
        );

      default:
        return <div />;
    }
  }

  return (
    <div className="  flex items-center justify-center  ">
      <div
        className={classNames(
          type === "Modifier"
            ? " w-7/12 inset-y-12 inset-x-1/4 fixed  overflow-y-auto z-50  outline-none focus:outline-none"
            : type === "remettre" || type === "delete"
            ? " w-1/2 top-1/3 fixed  overflow-y-auto z-50  outline-none focus:outline-none"
            : " w-7/12 top-1/3 fixed overflow-y-auto  z-50  outline-none focus:outline-none"
        )}
      >
        <div className="relative  mx-auto min-w-xl">
          {/*content*/}
          <div className="border-0 dark:bg-gray-800 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className=" flex items-center p-1 justify-between  rounded-t">
              <div className="text-gray-500 dark:text-white capitalize font-light text-2xl flex items-center justify-center  ">
                {t("Ticket.GestionTick")}
              </div>
              <AiFillCloseCircle
                className="h-8 w-8 text-red-300 hover:text-red-600 cursor-pointer dark:hover:text-red-400 dark:text-red-500"
                onClick={() => close()}
              />
            </div>
            {/*body*/}
            <form>
              <div className="p-1">
                <div className="flex items-center">
                  <div className="text-sm dark:text-gray-400 text-gray-500">
                    {t("Ticket.IdentifiantTick")}
                  </div>
                  <div className=" max-h-28  overflow-y-auto flex items-center max-w-sm">
                    {array.map((r) => (
                      <div
                        key={`key_idTCK${r}`}
                        className=" flex m-1 dark:text-white dark:border-blue-500 border-blue-300 font-semibold  rounded-full  justify-center items-center  border p-1 border-dashed "
                      >
                        {r}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="p-2">
                  <div className=" bg-white  dark:bg-gray-900">
                    {getSubMenu()}
                  </div>
                </div>
              </div>

              {/*footer*/}
              <div className="px-4 py-1 space-x-4  dark:bg-gray-800 justify-around flex sm:px-6">
                <button
                  className="text-white  hover:bg-gray-200 hover:translate-x-8  w-28 h-10 text-lg bg-gray-400 items-center flex justify-center py-2 px-4 border  rounded-full shadow"
                  onClick={() => {
                    close();
                  }}
                >
                  {t("All.Annuler")}
                </button>

                <button
                  disabled={error}
                  onClick={(e) => {
                    e.preventDefault();
                    validGestionBloc();
                  }}
                  className={classNames(
                    error
                      ? "text-white cursor-not-allowed   hover:translate-x-8  w-28 h-10 text-lg bg-gray-500 items-center flex justify-center py-2 px-4 border  rounded-full shadow"
                      : type === "delete"
                      ? "hover:bg-red-200 bg-red-400"
                      : "hover:bg-green-200 bg-green-400",
                    "text-white  hover:translate-x-8  w-28 h-10 text-lg  items-center flex justify-center py-2 px-4 border  rounded-full shadow"
                  )}
                >
                  <div>
                    <div>{t("All.Valider")}</div>
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    Fold: state.Fold,
    error: state.error,
    loading: state.loading,
    //  user: state.user,
    success: state.success,
  };
};

export default connect(mapStateToProps, { getFolder, addFolder })(
  GestionGroupeTicket
);
