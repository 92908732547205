import axiosInstance from "../../axiosInstance";

const getFoldd = async () => {
  const url = `Dossier`;
  const response = await axiosInstance.get(`${url}`);
  if (response.data) return response.data;
};

const addFolder = async (name, idPers, couleur) => {
  const url = `Dossier/store`;
  let status = "";

  const response = await axiosInstance.post(url, {
    dossier: name,
    Id_Pers: idPers,
    couleur: couleur,
  });

  if (response?.status === 200) {
    return response.data;
  }
};

export default {
  getFoldd,
  addFolder,
};
