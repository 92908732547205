import React, { useState, Fragment, useEffect, useLayoutEffect } from "react";
import Table, {
  AvatarCell,
  SelectColumnFilter,
  StatusPill,
  SévPill,
  MomentCell,
  Character,
  CellType,
  NonAssign,
  CharacterSLA,
  CharacterCLient,
} from "./Table";
import { ToolTip } from "../utils";
import { filtreTicket, getTicket } from "../../JS/Ticket/actions/TicketA";
import { Menu, Transition, Switch } from "@headlessui/react";
import { PlusSmIcon, ViewListIcon, XIcon } from "@heroicons/react/solid";
import { DocumentReportIcon } from "@heroicons/react/outline";
import FormTicket from "../utils/modals/FormTicket";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import ExportModal from "../../Export/Export";
import Modals from "../utils/modals/Modals";
import { AiFillAlert, AiFillCheckCircle, AiFillDelete } from "react-icons/ai";
import RechercheTicket from "../utils/modals/RechercheTicket";
import { getFolder } from "../../JS/Folder/actions/FoldA";
import {
  FETCH_TICKET,
  SET_FILTER_TICKET,
  SUCCESS_TICKET_UPDATE_OUT,
  UPDATE_MINE_TICKET,
} from "../../JS/Ticket/actions/types";
import { BsThreeDotsVertical } from "react-icons/bs";
import axios from "axios";
import useDebounce from "../../function/useDebounce";
import { PageButton } from "../../shared/Buttons";
import Axios from "axios";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { IoTicketOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/solid";
import { HiOutlineFilter } from "react-icons/hi";
import moment from "moment";
import { ThemeContext } from "../../ThemeContext";
import axiosInstance from "../../JS/axiosInstance";
export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
moment.locale("fr");
function ListeTable() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const foldSelect = useSelector((state) => state.foldR.FoldSelected);
  const refrshEnabled = useSelector((state) => state.ticketR.refrshEnabled);
  const { lang } = React.useContext(ThemeContext);
  const [idD, setIdD] = useState(0);
  const foldData = useSelector((state) => state.foldR.Fold);
  const data = useSelector((state) => state.ticketR.ticket);
  const { user } = useSelector((state) => state.auth);
  const [size, setSize] = useState(5);
  const page = useSelector((state) => state.ticketR.page);
  const loading = useSelector((state) => state.ticketR.loading);
  const filter = useSelector((state) => state.ticketR.filter);
  const firstItem = useSelector((state) => state.ticketR.firstItem);
  const lastItem = useSelector((state) => state.ticketR.lastItem);
  const total = useSelector((state) => state.ticketR.total);
  const perPage = useSelector((state) => state.ticketR.limit);

  const pageLast = useSelector((state) => state.ticketR.pageLast);
  const success = useSelector((state) => state.ticketR.success);
  const messageError = useSelector((state) => state.ticketR.error);
  const [pageNumber, setPageNumber] = useState(page);

  const [showModal1, setShowModal1] = React.useState(false);
  const mineTicket = useSelector((state) => state.ticketR.mine);

  const [ticketInfo, setTicketINfo] = useState({});
  const [type, setType] = useState({});

  const [filterData, setFilterData] = useState({
    dateD: "",
    dateF: "",
    client: "",
    type: "",
    priorite: "",
    flux: "",
    statut: "",
    agent: "",
  });
  const [detail, setDetail] = useState({});
  const [query, setQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    data: [],
  });

  const debounceTerm = useDebounce(query, 600);
  const [limit, setLimit] = useState(perPage ?? 8);
  const headers = [
    { label: "Code Ticket", key: "code_ticket" },
    { label: "Statut", key: "statut" },
    { label: "Sujet", key: "Sujet" },
    { label: "SLA", key: "sla" },
    { label: "Agent", key: "agent" },
    { label: "Date Creation", key: "date_creation" },
    { label: "client", key: "client" },
    { label: "type", key: "type" },
    { label: "voie", key: "voie" },
    { label: "groupe", key: "groupe" },
    { label: "service", key: "service" },
    { label: "severite", key: "severite" },
  ];
  useEffect(() => {
    let Mount = true;

    if (Mount) {
      if (!filter) {
        if (loading || (data !== undefined && data.length === 0))
          dispatch({ type: FETCH_TICKET });
        if (
          data === undefined ||
          data.length === 0 ||
          pageNumber >= 1 ||
          idD > 0
        ) {
          if (debounceTerm === "") {
            dispatch(
              getTicket(
                pageNumber,
                limit,
                idD,
                user.Id_Pers,
                user.Id_D_A,
                mineTicket,
                ""
              )
            );
          } else if (
            debounceTerm.length > 2 &&
            debounceTerm.toLowerCase() !== "tck" &&
            debounceTerm.toLowerCase() !== "tck-" &&
            debounceTerm.toLowerCase() !== "cl"
          ) {
            dispatch(
              getTicket(
                pageNumber,
                limit,
                idD,
                user.Id_Pers,
                user.Id_D_A,
                mineTicket,
                debounceTerm
              )
            );
          }
        }
      } else {
        dispatch({ type: FETCH_TICKET });

        dispatch(
          filtreTicket(
            filterData.dateD,
            filterData.dateF,
            filterData.client,
            filterData.type,
            filterData.priorite,
            filterData.flux,
            filterData.statut,
            filterData.agent,
            pageNumber,
            limit
          )
        );
      }
    }

    return () => {
      Mount = false;
    };
  }, [idD, pageNumber, mineTicket, debounceTerm, limit]);

  useEffect(() => {
    if (success.length > 0) {
      setTimeout(() => {
        if (foldSelect.length > 0) {
          setIdD(0);
          dispatch(
            getTicket(
              pageNumber,
              limit,
              0,
              user.Id_Pers,
              user.Id_D_A,
              mineTicket,
              ""
            )
          );
        }
        dispatch({
          type: SUCCESS_TICKET_UPDATE_OUT,
        });
      }, 2500);
    } else return () => {};
  }, [success, foldSelect, limit]);

  useEffect(() => {
    let Mount = false;
    if (!Mount) {
      if (foldData.length === 0) dispatch(getFolder());
    }
    return () => {
      Mount = true;
    };
  }, []);

  useEffect(() => {
    let refresh;

    refresh = setInterval(function () {
      console.log("refresh");

      if (!filter && refrshEnabled) {
        setIdD(0);
        dispatch(
          getTicket(
            pageNumber,
            limit,
            0,
            user.Id_Pers,
            user.Id_D_A,
            mineTicket,
            ""
          )
        );
      }
    }, 120000);
    return () => {
      clearInterval(refresh);
    };
  }, [filter, refrshEnabled, mineTicket]);

  const Dos = () => {
    return (
      <div className=" w-full justify-start  opacity-60">
        <div className="flex w-full items-center">
          <span className="text-base text-black dark:text-white">
            {t("Ticket.Dossier")}:
          </span>
          <select
            className="rounded-full  px-4 py-2.5 bg-purple-800  hover:bg-purple-900 text-white  border-gray-300 ml-4 dark:text-white dark:bg-gray-800 "
            value={idD}
            onChange={(o) => {
              setPageNumber(1);
              setIdD(Number(o.target.value));
              dispatch({
                type: SET_FILTER_TICKET,

                paload: !filter,
              });
              if (Number(o.target.value) === 0) {
                dispatch(
                  getTicket(
                    pageNumber,
                    limit,
                    0,
                    user.Id_Pers,
                    user.Id_D_A,
                    mineTicket,
                    ""
                  )
                );
              }
            }}
          >
            <option
              className="bg-white text-gray-900 dark:text-white dark:bg-gray-800"
              value="0"
            >
              {t("All.Tous")}
            </option>
            {foldData.map((option, i) => (
              <option
                key={i}
                value={option.id_dossier}
                className="text-gray-700 dark:text-white origin-top-right absolute overflow-y-scroll overflow-x-hidden h-28 right-0 dark:bg-gray-700   w-36 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                {option.dossier}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  const getDetailClient = async (props) => {
    try {
      const response = await axiosInstance.get("Clientid/" + props.code_client);
      if (response?.status === 200) {
        setDetail(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  function callFunction(props) {
    getDetailClient(props);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "39sdfsdf076TRdgsdvhzrtfCCGhsdsdfsdfk09k94345GH.eyJzdWIiOiIxMjM0N643REZ32FV25EGdfdcpmkHGHVFgsdyj8JJVHFs.sRPMLIdsdfsdfs456765UDFgbdfgjuPK3453456SDFGDFG67TYUFGHDFG086758CVGRTdghfgHFGHJdfgEZREZRzeR4SDFGQSDFezr35466"
    );
    myHeaders.append(
      "Cookie",
      "ci_session=d4189193e586452428320ffb005f67c882b025b7"
    );

    var formdata = new FormData();
    formdata.append("compte", "client");
    formdata.append("login", "808");
    formdata.append("tel", detail.Tel);
    formdata.append("CMK_WS_PK_2", "");
    formdata.append("CMK_WS_PK_3", "");
    formdata.append("CMK_WS_PK_4", "");
    formdata.append("CMK_WS_PK_5", "");

    var config = {
      method: "post",
      url:
        "https://cmkvoip.comunikcrm.info:4444/comunik_ipbx/Api/HELPDESK/ClickToCallVoipAPI?compte=client&poste=808&telephone=" +
        detail.tel,
      headers: {
        Authorization:
          "39sdfsdf076TRdgsdvhzrtfCCGhsdsdfsdfk09k94345GH.eyJzdWIiOiIxMjM0N643REZ32FV25EGdfdcpmkHGHVFgsdyj8JJVHFs.sRPMLIdsdfsdfs456765UDFgbdfgjuPK3453456SDFGDFG67TYUFGHDFG086758CVGRTdghfgHFGHJdfgEZREZRzeR4SDFGQSDFezr35466",
        Cookie: "ci_session=d4189193e586452428320ffb005f67c882b025b7",
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  }
  useEffect(() => {
    // 2
    Axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
    // 3

    const echo = new Echo({
      broadcaster: "pusher",
      key: process.env.REACT_APP_MIX_ABLY_PUBLIC_KEY,
      wsHost: "realtime-pusher.ably.io",
      wsPort: 443,
      disableStats: true,
      encrypted: true,
    });
    // 4
    echo
      .channel("public.room")
      .subscribed(() => {})
      // 5
      .listen(".message.new", (data) => {
        if (Number(data.user) === user.Id_Pers) {
          if (data.type === "comment") {
            setNotification((c) => ({
              open: true,
              data: [...c.data, data],
            }));
          } else if (data.type === "ticket") {
            dispatch({ type: FETCH_TICKET });

            dispatch(
              getTicket(
                pageNumber,
                limit,
                0,
                user.Id_Pers,
                user.Id_D_A,
                mineTicket,
                ""
              )
            );
          }
        }
      });

    return () => {
      echo.disconnect();
    };
  }, [notification.open]);

  const columns = React.useMemo(
    () => [
      {
        Header: t("All.Identifiant"),
        accessor: "code_ticket",
        Cell: (props) => AvatarCell(props, idD),

        // aggregate: "count",
        //  Aggregated: ({ value }) => `${value} (total)`,
      },

      {
        Header: t("All.Statut"),
        accessor: "statut",
        Cell: StatusPill,
        Filter: (props) => SelectColumnFilter(props, setFilterData, filterData),
        filter: "includes",
      },
      {
        Header: "SLA",
        accessor: "etat_sla",
        Cell: CharacterSLA,
        // Cell:Space,
      },
      {
        Header: t("Client.Sujet"),
        accessor: "Sujet",
        Cell: Character,
      },
      {
        Header: t("All.Type"),
        accessor: "type",
        Cell: CellType,
      },
      {
        Header: "Agent",
        accessor: "agent",
        Cell: NonAssign,
      },
      {
        Header: t("All.Date"),
        accessor: "date_creation",
        Cell: MomentCell,
      },

      {
        Header: "Client",
        accessor: "allclient",
        Cell: CharacterCLient,
      },

      {
        Header: t("All.Sévérité"),
        accessor: "severite",
        Cell: SévPill,
      },
      {
        Header: t("All.Service"),
        accessor: "service",
      },
      {
        Header: t("Filtre.Groupe"),
        accessor: "groupe",
      },

      {
        Header: "Actions",
        id: "action_id",
        sticky: "right",
        filter: "action_id",
        disableGlobalFilter: true,
        accessor: (props) => (
          <td className="flex  ml-5 text-sm font-medium ">
            <Menu as="div" className="  relative">
              <Menu.Button>
                <BsThreeDotsVertical className="w-6 h-6 hover:text-gray-300 dark:text-white " />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-500"
                enterFrom="transform opacity-0 scale-20 "
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-out duration-200"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-20"
              >
                <Menu.Items className="z-10  absolute flex flex-row  justify-center items-center text-center right-8 w-68   bottom-4 rounded-lg cursor-pointer shadow-lg bg-white dark:bg-gray-300  divide-x divide-gray-200 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={() => callFunction(props)}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2  text-sm hover:text-blue-300 hover:cursor-pointer"
                        )}
                      >
                        <ToolTip
                          value={t("Admin.Appeler")}
                          type="Appeler"
                          color="gray"
                        />
                      </div>
                    )}
                  </Menu.Item>
                  {Number(user.Id_D_A) !== 2 && (
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2  text-sm hover:text-blue-300 hover:cursor-pointer"
                          )}
                          onClick={() => {
                            setTicketINfo(props);
                            setIsOpen(true);
                            setType("relancer");
                          }}
                        >
                          <ToolTip
                            value={t("Client.Relancer")}
                            type="Relancer"
                            color="gray"
                          />
                        </div>
                      )}
                    </Menu.Item>
                  )}

                  <Menu.Item>
                    {({ active }) => (
                      <div
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2  text-sm hover:text-blue-300 hover:cursor-pointer"
                        )}
                        onClick={() => {
                          setTicketINfo(props);
                          setIsOpen(true);
                          setType("commentaire");
                        }}
                      >
                        <ToolTip
                          value={t("All.Commenter")}
                          type="Commenter"
                          color="gray"
                        />
                      </div>
                    )}
                  </Menu.Item>

                  <Menu.Item>
                    {({ active }) => (
                      <div
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm  hover:text-blue-300 hover:cursor-pointer"
                        )}
                        onClick={() => {
                          setIsOpen(true);
                          setTicketINfo(props);
                          setType("assigner");
                        }}
                      >
                        <ToolTip
                          value={t("Ticket.Massigner")}
                          type="Assigner"
                          color="gray"
                        />
                      </div>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm  hover:text-blue-300 hover:cursor-pointer"
                        )}
                        onClick={() => {
                          setShowModal1(true);
                          setTicketINfo(props);
                        }}
                      >
                        <ToolTip
                          value={t("All.Editer")}
                          type="Modifier"
                          color="gray"
                        />
                      </div>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </td>
        ),
      },
    ],
    [lang]
  );

  return (
    <div
      // ref={imgRef}
      // style={{ width: imageDimensions.width, height: imageDimensions.height*0.77 }}
      className="  overflow-auto flex flex-col justify-between   bg-gray-100 dark:bg-gray-900 text-gray-900"
    >
      <span className=" flex  relative items-center justify-start lg:ml-10 2xl:mb-2 sm:-mb-2  2xl:text-lg dark:text-white mt-6 font-semibold  py-1 px-2  rounded-full text-purple-600  uppercase last:mr-0 mr-1">
        <ViewListIcon className="text-purple-400 w-5 h-5 mr-2" />{" "}
        {t("Bord.Agent.Liste")}
      </span>
      {messageError !== "" && (
        <div className="flex items-center justify-center gap-28 px-5">
          <div className="rounded-lg   bg-red-50 p-5">
            <div className="flex">
              <div className="flex-shrink-0  ">
                <AiFillAlert
                  className="h-8 w-8 mt-1 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-3xl font-medium text-red-800">
                  {messageError ?? "Network Failed, please try later"}
                </h3>
              </div>
            </div>
          </div>
        </div>
      )}

      {success &&
      success?.length > 0 &&
      success !==
        `Les tickets sélectionnés sont deplacés vers la corbeille.` ? (
        <div className="flex items-center justify-center gap-28 px-5">
          <div className="rounded-lg animate-bounce200  bg-green-50 p-5">
            <div className="flex">
              <div className="flex-shrink-0  ">
                <AiFillCheckCircle
                  className="h-8 w-8 mt-1 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-3xl font-medium text-green-800">
                  {success}
                </h3>
              </div>
            </div>
          </div>
        </div>
      ) : success.length > 0 &&
        success ===
          `Les tickets sélectionnés sont deplacés vers la corbeille.` ? (
        <div className="flex items-center justify-center gap-28 px-5">
          <div className="rounded-lg animate-bounce200  bg-red-50 p-5">
            <div className="flex">
              <div className="flex-shrink-0  ">
                <AiFillDelete
                  className="h-8 w-8 mt-1 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-3xl font-medium text-red-800">{success}</h3>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}

      <div
        aria-live="assertive"
        className="    absolute z-10 top-10 lg:inset-x-4/5 xl:inset-x-4/5 2xl:inset-x-4/5  inset-x-2/3  items-end px-4 py-6 pointer-events-none sm:p-6  sm:items-start"
      >
        {notification.data.length > 0 && (
          <button
            type="button"
            onClick={() => setNotification({ ...notification, data: [] })}
            className="ml-4 text-red-500 text-lg hover:text-red-200   underline text-right   z-30 flex"
          >
            Clear
          </button>
        )}
        <div className="w-full    flex flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={notification.open}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full max-h-20 p-1">
              {notification.data.map((d) => (
                <div
                  key={d.id}
                  className={classNames(
                    " w-96 bg-white dark:bg-gray-600 shadow-lg my-2 rounded-lg pointer-events-auto ring-1 ring-black  ring-opacity-5 p-1"
                  )}
                >
                  <div className="p-4">
                    <div className="flex items-start">
                      <div className="">
                        <IoTicketOutline
                          className="h-6 w-6 text-purple-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <div className=" text-sm  dark:text-gray-50 text-gray-500">
                          <strong className="mr-1">{d.message}</strong> le
                          ticket N°{" "}
                          <strong className="mx-1">#{d.Id_Tckt}</strong> <br />{" "}
                          le
                          <strong className="mx-1">
                            {" "}
                            {moment(d.createdAt).format("lll")}{" "}
                          </strong>
                        </div>
                      </div>
                      <div className="ml-4 flex-shrink-0 flex">
                        <button
                          className="bg-white dark:bg-gray-400 rounded-md inline-flex text-gray-400 dark:text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => {
                            setNotification({
                              ...notification,
                              data: notification.data.filter(
                                (e) => e.createdAt !== d.createdAt
                              ),
                            });
                          }}
                        >
                          <XIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Transition>
        </div>
      </div>

      {/*header */}
      <div className=" 2xl:flex 2xl:flex-row justify-between   mt-8  xl:flex xl:flex-row lg:flex lg:flex-row md:flex md:flex-row sm:flex    ">
        <div className="ml-6">
          <Dos />
        </div>
        {user.Id_D_A === 4 && (
          <div className="flex items-center">
            <div className="text-base font-semibold text-gray-600 dark:text-white mr-2">
              {t("Email.AllTickets")}
            </div>

            <Switch
              checked={mineTicket}
              onChange={() => {
                dispatch({ type: UPDATE_MINE_TICKET });
              }}
              className={classNames(
                mineTicket ? "bg-indigo-600" : "bg-gray-200",
                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              )}
            >
              <span
                className={classNames(
                  mineTicket ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                )}
              >
                <span
                  className={classNames(
                    mineTicket
                      ? "opacity-0 ease-out duration-100"
                      : "opacity-100 ease-in duration-200",
                    "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                  )}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    mineTicket
                      ? "opacity-100 ease-in duration-200"
                      : "opacity-0 ease-out duration-100",
                    "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                  )}
                  aria-hidden="true"
                />
              </span>
            </Switch>
            <div className="text-base font-semibold text-gray-600 dark:text-white ml-2">
              {t("Email.MyTickets")}
            </div>
          </div>
        )}

        <div className="flex justify-between  space-x-2 mr-4 lg:mr-14">
          <button
            className="text-white  justify-center  space-x-1  w-28 h-10 text-lg  rounded-full flex items-center hover:bg-purple-500 bg-purple-800  dark:bg-purple-700  dark:hover:bg-purple-500"
            onClick={() => {
              setTicketINfo({});
              setShowModal1(true);
            }}
          >
            <div className="     flex  flex-row justify-center items-center">
              <PlusSmIcon className=" w-7 h-7 text-white" />
            </div>
            <ToolTip value={t("All.CréerTick")} type="ticket" color="gray" />
          </button>

          <button
            onClick={() => {
              setExportModal(!exportModal);
            }}
            className="text-white cursor-pointer space-x-1  hover:bg-green-200 hover:translate-x-8  w-28 h-10 text-lg bg-green-400 items-center flex justify-center py-2 px-4   rounded-full "
            type="button"
          >
            <DocumentReportIcon className="w-9 h-9 ml-1  " aria-hidden="true" />
            <ToolTip
              value={t("Ticket.ExportTick")}
              type="Export"
              color="gray"
            />
          </button>

          <button
            onClick={() => {
              setShowModal(!showModal);
            }}
            className="text-white space-x-1 cursor-pointer hover:bg-gray-200 hover:translate-x-8  w-28 h-10 text-lg bg-gray-400 items-center flex justify-center py-2 px-4   rounded-full "
            type="button"
          >
            {/* <DocumentReportIcon
                  className="w-8 h-8 ml-1  "
                  aria-hidden="true"
                /> */}
            <div className="     flex  flex-row justify-center  items-center">
              <HiOutlineFilter className="w-6 h-6 animate-pulse   leading-6 text-white   font-semibold   " />
            </div>
            <ToolTip value={t("Client.Recherche")} type="Rech" color="gray" />
          </button>
        </div>
      </div>
      {/*body */}

      <div className=" flex  overflow-auto  mt-2 mx-1    ">
        <div className=" w-full    sm:px-10 pl-2 lg:px-8 pt-4">
          <div className=" absolute  top-1/6  flex   cursos-pointer z-20    right-0">
            <RechercheTicket
              canAgent={!mineTicket}
              showModal={showModal}
              setShowModal={setShowModal}
              setData={(e) => setFilterData(e)}
              page={pageNumber}
            />
          </div>
          <Table
            setQuery={(e) => {
              setQuery(e);
            }}
            setIdD={setIdD}
            setLimit={setLimit}
            idD={idD}
            size={size}
            setSize={setSize}
            columns={columns}
            data={data ?? []}
            loadingg={loading}
            pageNumber={pageNumber}
            pageLast={pageLast}
            setPage={setPageNumber}
          />
        </div>
      </div>
      {/*footer */}

      <div className=" pt-10 mx-3 xs:mx-8 sm:mx-7 lg:mx-8 ">
        <div className=" py-3">
          <div className="flex items-center md:justify-between sm:justify-center   sm:flex-1 sm:flex sm:items-center">
            <div className="flex items-center space-x-2 mr-6">
              <div className="text-gray-500 dark:text-white text-base ml-2">
                {t("Bord.AffichagePar")}
              </div>
              <select
                value={perPage}
                className="mt-0.5 block w-1/3 rounded-md bg-white border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                // className="outline-none focus:ring-transparent rounded-md text-base px-4 py-2 dark:text-gray-100 text-center  border-0 bg-gray-50 dark:bg-gray-700 shadow "
                onChange={(e) => {
                  setLimit(e.target.value);
                  setPageNumber(1);
                  // dispatch({type:FETCH_CLIENT})
                }}
              >
                {[8, 20, 30, 50]
                  .filter((v, i, a) => a.indexOf(v) === i)
                  .sort((a, b) => a - b)
                  .map((e, i) => (
                    <option key={`${e}__ index_size${i}`} value={e}>
                      {e}
                    </option>
                  ))}
              </select>
            </div>
            <>
              <div className="hidden md:flex">
                <div className="text-lg dark:text-white text-gray-600 ">
                  {t("Table.Affichagedélément")}{" "}
                  <i className="font-bold"> {firstItem ?? 0}</i> {t("Table.à")}{" "}
                  <i className="font-bold">{lastItem ?? 0}</i> {t("Table.sur")}{" "}
                  <i className="font-bold"> {total ?? 0}</i>{" "}
                  {t("Table.éléments")}
                </div>
              </div>
              <nav
                className=" navC  relative  inline-flex items-center rounded-md shadow-sm -space-x-px"
                aria-label="Pagination"
              >
                <PageButton
                  className="rounded-l-md h-full "
                  onClick={() => {
                    setPageNumber(pageLast - pageLast + 1);
                    //  gotoPage(0)
                  }}
                  disabled={pageNumber === 1}
                >
                  <span className="sr-only">First</span>
                  <ChevronDoubleLeftIcon
                    className="h-7 w-7 text-gray-400 dark:text-white"
                    aria-hidden="true"
                  />
                </PageButton>
                <PageButton
                  className="h-full"
                  onClick={() => {
                    setPageNumber((current) =>
                      current > 1 ? current - 1 : current
                    );
                  }}
                  disabled={pageNumber === 1}
                >
                  <span className="sr-only">Précédent</span>
                  <ChevronLeftIcon
                    className="h-7 w-7 text-gray-400 dark:text-white"
                    aria-hidden="true"
                  />
                </PageButton>
                <PageButton
                  className="h-full"
                  onClick={() => {
                    setPageNumber(pageNumber + 1);
                  }}
                  disabled={pageNumber === pageLast}
                >
                  <span className="sr-only">Suivant</span>
                  <ChevronRightIcon
                    className="h-7 w-7 text-gray-400 dark:text-white"
                    aria-hidden="true"
                  />
                </PageButton>
                <PageButton
                  className="rounded-r-md h-full "
                  onClick={() => {
                    setPageNumber(pageLast);
                  }}
                  disabled={pageNumber === pageLast}
                >
                  <span className="sr-only">Dernière page</span>
                  <ChevronDoubleRightIcon
                    className="h-7 w-7 text-gray-400 dark:text-white  "
                    aria-hidden="true"
                  />
                </PageButton>
              </nav>
            </>
          </div>
        </div>
      </div>

      {exportModal && (
        <ExportModal
          type="ticket"
          headers={headers}
          setExportModal={setExportModal}
        />
      )}

      {isOpen && (
        <div>
          <div
            className="justify-center items-center overflow-y-auto min-h-screen  
          flex fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative  my-6 ">
              <div className="dark:bg-gray-900 rounded-xl shadow-lg bg-white outline-none focus:outline-none">
                <Modals
                  ticket={ticketInfo}
                  type={type}
                  setTicket={setTicketINfo}
                  close={setIsOpen}
                />
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </div>
      )}

      {showModal1 && (
        <div className="  flex-grow   ">
          <div className="overflow-y-auto inset-y-14  inset-x-1/4 bottom-2 xl:-top-8  xl:bottom-2 fixed w-full  z-40 outline-none focus:outline-none">
            <div className="my-6 mx-auto">
              <div
                className="dark:bg-gray-900 rounded-lg shadow-lg mt-16  
              flex flex-col w-8/12 lg:w-7/12 xl:w-7/12 2xl:w-7/12 bg-white outline-none focus:outline-none"
              >
                <FormTicket
                  general={false}
                  close={setShowModal1}
                  dataSelected={ticketInfo}
                  setDataSelected={setTicketINfo}
                />
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-30 bg-black" />
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    Fold: state.Fold,
    ticket: state.ticket,
    error: state.error,
    loading: state.loading,
    perPage: state.perPage,
    total: state.total,
    success: state.success,
    mine: state.mine,
    refrshEnabled: state.refrshEnabled,
  };
};

export default connect(mapStateToProps, { getTicket, getFolder })(ListeTable);
