import React, { useState, useEffect } from "react";
import { MultiSelectComponent, Select2 } from "../utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import axiosInstance from "../../JS/axiosInstance";

const FilterData = ({
  onChange,
  name,
  prefix,
  mono,
  selectedObject,
  onCHangeMOno,
}) => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const getData = () => {
    axiosInstance
      .get(name)
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    if (prefix !== "CL") getData();
  }, []);

  const options = data.map((d) => ({
    id: `${[Object.values(d)[0]]}`,
    value: `${[Object.values(d)[1]]}`,
    label: `${[Object.values(d)[1]]}`,
    labelD: `${prefix === "CL" ? [Object.values(d)[2]] : ""}`,
  }));
  return (
    <>
      {mono ? (
        <Select2
          selectPlaceholder={`${t("All.Sélectionner")} ${name}`}
          dark="600"
          data={data}
          name={name}
          defaultItem={
            Object.values(selectedObject)[0].length === 0 ||
            Object.values(selectedObject)[1].length === 0
              ? null
              : selectedObject ?? null
          }
          setSelectItem={(e) => {
            onCHangeMOno(e);
          }}
        />
      ) : (
        <MultiSelectComponent
          setSelected={(e) => onChange(e)}
          data={options}
          prefix={prefix}
        />
      )}
    </>
  );
};

export default FilterData;
