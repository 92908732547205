import React,{useRef} from 'react';
import socketIOClient from "socket.io-client";



const ENDPOINT = process.env.REACT_APP_BAESE_URL_RMC;



const getInitialTheme = () => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const storedPrefs = window.localStorage.getItem('current-theme');
    if (typeof storedPrefs === 'string') {
      return storedPrefs;
    }
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark';
    }
  }
  return 'light';
};

export const ThemeContext = React.createContext();

export const ThemeProvider = ({ initialTheme, children }) => {
  const [theme, setTheme] = React.useState(getInitialTheme);
  const [lang, setLang] = React.useState(localStorage.getItem('lang')??"fr");
  const sockettest=useRef(null)


  const checkTheme = (existing) => {
    const root = window.document.documentElement;
    const isDark = existing === 'dark';

    root.classList.remove(isDark ? 'light' : 'dark');
    root.classList.add(existing);

    localStorage.setItem('current-theme', existing);
  };

  if (initialTheme) {
    checkTheme(initialTheme);
  }

  React.useEffect(() => {
     sockettest.current = socketIOClient(ENDPOINT);
    
  }, []);


  React.useEffect(() => {
    checkTheme(theme);
  
    
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme,lang, setLang,socket:sockettest }}>
      {children}
    </ThemeContext.Provider>
  );
};
