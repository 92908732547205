import { ConfigProvider, DatePicker } from "antd";
import moment from "moment";
import "moment/locale/fr";
import { useTranslation } from "react-i18next";
import "antd/dist/antd.css";
import frFR from "antd/lib/locale/fr_FR";
const { RangePicker } = DatePicker;
moment.locale("fr");
const Datech = ({
  values,
  setValues2,
  defaultValues,
  disbaledDate,
  both,
  setValues,
  mode,
  defaultOpen,
  dateDebut = true,
}) => {
  const { t } = useTranslation();
  let dateD;
  //
  return (
    <div className="mt-1">
      {both ? (
        <ConfigProvider locale={frFR}>
          <RangePicker
            defaultOpen={defaultOpen}
            defaultValue={[moment().subtract(7, "days"), moment()]}
            className="dark:bg-gray-700  dark:text-white  w-full h-9"
            onChange={(date) => {
              if (date === null) {
                setValues2("");
                setValues("");
              } else
                date &&
                  date.map((e, i) => {
                    if (i === 0) {
                      dateD = e.format("YYYY-MM-DD h:mm:ss");
                      if (e && (e !== undefined || e !== "Invalid date"))
                        setValues(dateD);
                      else setValues("");
                    } else {
                      if (e && (e !== undefined || e !== "Invalid date"))
                        setTimeout(() => {
                          setValues2(e.format("YYYY-MM-DD h:mm:ss"));
                        }, 1);
                      else setValues2("");
                    }
                  });
            }}
          />
        </ConfigProvider>
      ) : disbaledDate ? (
        <ConfigProvider locale={frFR}>
          <DatePicker
            defaultValue={defaultValues === "" ? "" : moment(defaultValues)}
            showTime={mode ? mode : false}
            placeholder=""
            className="dark:bg-gray-700  dark:text-white  w-full h-9"
            onChange={(date) => {
              console.log("date1", date);
              if (date && (date !== undefined || date !== "Invalid date")) {
                let startOfDay = moment(date).startOf("day");
                console.log(
                  "startttt",
                  startOfDay.format("YYYY-MM-DD HH:mm:ss")
                ); //start of day is the beginning of the day, ie. 00:00:00 of the day.)
                // var m = moment(date).utcOffset(0);
                // m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                // m.toISOString();
                // m.format(date);
                setValues(startOfDay.format("YYYY-MM-DD HH:mm:ss"));
              } else setValues("");
            }}
          />
        </ConfigProvider>
      ) : (
        <ConfigProvider locale={frFR}>
          <DatePicker
            locale={frFR}
            defaultValue={defaultValues === "" ? "" : moment(defaultValues)}
            showTime={mode ? mode : false}
            placeholder=""
            className="dark:bg-gray-700  dark:text-white  w-full h-9"
            disabledDate={(current) =>
              current.format("YYYY-MM-DD h:mm:ss") <
              moment(values).format("YYYY-MM-DD HH:mm:ss")
            }
            onChange={(date) => {
              if (date && (date !== undefined || date !== "Invalid date")) {
                let endOfDay = moment(date).endOf("day");
                console.log(
                  "endddddddddd",
                  endOfDay.format("YYYY-MM-DD HH:mm:ss")
                ); //end of day is the end of the day ie. 23:59:59 of the day.)
                setValues(endOfDay.format("YYYY-MM-DD HH:mm:ss"));
              } else setValues("");
            }}
          />
        </ConfigProvider>
      )}
    </div>
  );
};

export default Datech;
