import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import ticketR from '../Ticket/reducers/ticket'
import clientR from '../CLient/reducers/clientR'
import foldR from "../Folder/reducers/foldR";
export default combineReducers({
  clientR,
  ticketR,
  auth,
  message,
  foldR,
});