import React from 'react'
import { classNames } from './Utils'

export function Button({ children, className, ...rest }) {
  return (
    <button
      type="button"
      className={
        classNames(
          "relative inline-flex items-center px-4 py-2 border  border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50",
          className
        )}
      {...rest}
    >
      {children}
    </button>
  )
}

export function PageButton({ children,disabled, className, ...rest }) {
  return (
    <button
    disabled={disabled}
    type="button"
      className={
        classNames(
          disabled?"cursor-not-allowed":
          "relative  inline-flex items-center md:px-1 md:py-1 sm:px-1 sm:py-1 px-2 py-2 dark:bg-gray-800  border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50",
          className
        )}
      {...rest}
    >
      {children}
    </button>
  )
}

