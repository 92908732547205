import React, { useState, Fragment, useEffect } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
} from "react-table";
// import { useTranslation } from "react-i18next";
import { Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { classNames } from "../../shared/Utils";
import { SortIcon, SortUpIcon, SortDownIcon } from "../../shared/Icons";
import moment from "moment";
import "moment/locale/fr";
import { ToolTip } from "../utils";
import "./Table.css";
import { useDispatch, useSelector } from "react-redux";
import { filtreTicket, getTicket } from "../../JS/Ticket/actions/TicketA";
import GestionGroupeTicket from "../utils/modals/GestionGroupeTicket";
import { AiOutlineColumnWidth } from "react-icons/ai";
import VarHome from "../VAR/VarHome";
import { FiRefreshCcw } from "react-icons/fi";
import { BsInfoLg } from "react-icons/bs";
import {
  DISABLE_REFRESH,
  ENABLE_REFRESH,
  SET_FILTER_TICKET,
  UPDATE_TICKET,
} from "../../JS/Ticket/actions/types";
import { useSticky } from "react-table-sticky";
function GlobalFilter({ setQuery, setPage, setIdD }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const filter = useSelector((state) => state.ticketR.filter);
  const limit = useSelector((state) => state.ticketR.limit);
  const { t } = useTranslation();
  //value
  const [value, setValue] = useState("");
  return (
    <label className="flex flex-row  items-center gap-x-2 ">
      <div
        className={classNames(
          "flex items-center justify-center cursor-pointer hover:bg-gray-200 h-9 w-9 rounded-md bg-gray-100 dark:bg-gray-900 dark:hover:bg-gray-600 "
        )}
        onClick={() => {
          setIdD(0);
          setPage(1);
          dispatch(
            getTicket(1, limit, 0, user.Id_Pers, user.Id_D_A, false, "")
          );
          dispatch({
            type: SET_FILTER_TICKET,

            payload: !filter,
          });
          setQuery("");
          setValue("");
          dispatch({ type: ENABLE_REFRESH });
        }}
      >
        {filter ? (
          <FiRefreshCcw
            className={classNames(
              filter ? "animate-spin" : "",
              "h-6 w-6 text-indigo-500 hover:text-indigo-800 cursor-pointer   dark:text-indigo-500"
            )}
          />
        ) : (
          <ToolTip value={t("All.Actualiser")} type="Relancer" color="gray" />
        )}
      </div>

      <>
        <div className="relative flex justify-end items-center">
          <input
            value={value}
            //  onBlur={() => dispatch({ type: ENABLE_REFRESH })}
            autoComplete="off"
            type="search"
            className="rounded-md dark:text-white dark:bg-gray-800 border-gray-300 p-1.5 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            onChange={(e) => {
              var value = e.target.value;
              setQuery(value.toLowerCase());
              setValue(value.toLowerCase());
              setPage(1);

              dispatch({ type: DISABLE_REFRESH });
            }}
            placeholder={t("All.Rechercher") + " " + `ticket`}
          />
        </div>
      </>
    </label>
  );
}

//Filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({ column: { id, render } }, setFilterData) {
  const dispatch = useDispatch();
  const perPage = useSelector((state) => state.ticketR.limit);
  const page = useSelector((state) => state.ticketR.page);
  const { user } = useSelector((state) => state.auth);
  const filter = useSelector((state) => state.ticketR.filter);
  const mineTicket = useSelector((state) => state.ticketR.mine);

  const [selected, setselected] = useState("");
  const options = [
    { id: 1, label: "Créé" },
    { id: 2, label: "Consulté" },
    { id: 3, label: "En cours" },
    { id: 4, label: "En attente" },
    { id: 5, label: "Résolu" },
    { id: 6, label: "Clôturé" },
  ];

  useEffect(() => {
    let mount = true;

    if (mount) {
      if (!filter) {
        setselected("");
      }
    }
    return () => {
      mount = false;
    };
  }, [filter]);

  return (
    <div className="flex items-center justify-between">
      <label className="flex gap-x-2 items-baseline">
        <span className="text-gray-700 dark:text-white">
          {render("Header")}
        </span>
        <select
          className=" mt-1 block w-full bg-gray-50 dark:bg-gray-800 dark:text-white py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          id={id}
          value={selected}
          onChange={(e) => {
            const value = e.target.value;

            setselected(value);
            if (value.length > 0) {
              dispatch(
                filtreTicket("", "", "", "", "", "", value, "", page, perPage)
              );
              setFilterData({
                dateD: "",
                dateF: "",
                client: "",
                type: "",
                priorite: "",
                flux: "",
                statut: value,
                agent: "",
                pageNumber: page,
                perPage: perPage,
              });
            } else if (value.length === 0 && selected !== "") {
              dispatch({ type: SET_FILTER_TICKET, paload: !filter });
              dispatch(
                getTicket(
                  1,
                  perPage,
                  0,
                  user.Id_Pers,
                  user.Id_D_A,
                  mineTicket,
                  ""
                )
              );
            }
          }}
        >
          <option value="">Tous</option>
          {options.map((option, i) => (
            <option key={`${i}__index_${option.id}`} value={option.label}>
              {option.label}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
}

// color inside status :
export function StatusPill({ value }) {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={classNames(
        "px-4 py-1 uppercase text-justify leading-wide  font-bold  rounded-full shadow-sm",
        status.includes("créé") ? "bg-yellow-400 text-white" : null,
        status.includes("consulté") ? "bg-blue-400 text-white" : null,
        status.includes("en cours") ? "bg-blue-800 text-white" : null,
        status.includes("en attente") ? "bg-purple-400 text-white" : null,
        status.includes("résolu") ? "bg-gray-400 text-white" : null,
        status.includes("clôturé") ? "bg-green-400 text-white" : null
      )}
    >
      {status}
    </span>
  );
}

// color inside sévérité :
export function SévPill({ value }) {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={classNames(
        "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
        status.includes("elevée") ? "bg-gray-500 text-white" : null,
        status.includes("normale") ? "bg-gray-400 text-white" : null,
        status.includes("faible") ? "bg-gray-300 text-white" : null
      )}
    >
      {status}
    </span>
  );
}

export function AvatarCell(props) {
  const dispatch = useDispatch();

  const [hover, setHover] = useState(false);
  const [openModalVar, setOpenVar] = useState(false);

  return (
    <>
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className="flex  relative  items-center "
      >
        <div
          className={classNames(
            props.value
              ?.toLowerCase()
              .includes(props.cell.row.original.query?.toLowerCase())
              ? "bg-yellow-100   block p-1 rounded-full"
              : !hover
              ? "  dark:text-white"
              : " opacity-25  dark:text-white",

            "text-sm inline-flex  space-x-1  font-semibold  text-gray-900"
          )}
        >
          <div> {props.value}</div>
          {props.row.original.count_msg_unread > 0 && (
            <div className="bg-red-400 h-4 w-4 animate-ping  rounded-full flex items-center text-white justify-center">
              {props.row.original.count_msg_unread}
            </div>
          )}
        </div>
        {hover && (
          <BsInfoLg
            onClick={() => {
              setOpenVar((c) => !c);
              dispatch({ type: DISABLE_REFRESH });
              if (props.row.original.count_msg_unread > 0) {
                dispatch({
                  type: UPDATE_TICKET,
                  payload: {
                    ticket: {
                      ...props.row.original,

                      count_msg_unread: 0,
                    },

                    success: "",
                    successG: "",
                  },
                });
              }
            }}
            id="id"
            className="h-5 w-5  absolute  left-6 cursor-pointer text-indigo-300 dark:text-indigo-500"
          />
        )}
      </div>

      {openModalVar && (
        <div
          className="justify-center items-center  h-full
    flex fixed inset-y-0  z-101  md:w-8/12 outline-none focus:outline-none "
        >
          <div className="relative py-2  my-6 ">
            <div className="dark:bg-gray-900 h-full  rounded-xl shadow-lg  bg-white outline-none focus:outline-none">
              <div className="rounded-lg  flex items-center justify-center bg-gray-200  shadow divide-y divide-gray-200 sm:divide-y-0 ">
                <div className="flex z-101 h-5/6 ">
                  <VarHome
                    onClose={(e) => {
                      setOpenVar(e);
                      dispatch({ type: ENABLE_REFRESH });
                    }}
                    ticket={props.row.original}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-gray-900 opacity-25" />
        </div>
      )}
    </>
  );
}

export function CellType({ value }) {
  if (value)
    return (
      <div className="flex items-center   ">
        <div className="text-justify max-w-sm truncate  font-medium px-3 py-1 leading-wide text-gray-900  bg-white border border-gray-400 rounded-full dark:bg-gray-800  shadow dark:text-white">
          {value}
        </div>
      </div>
    );
  else {
    return <div />;
  }
}

export function MomentCell({ value }) {
  return (
    <div className="flex items-center ml-2">
      <div className="text-sm text-gray-900 dark:text-white">
        {moment(value).format("lll")}
      </div>
    </div>
  );
}

export function NonAssign({ value }) {
  const { t } = useTranslation();
  if (!value || value === "--") {
    return (
      <div className="text-sm  ml-3 text-red-800 border text-center  px-3 py-1 leading-wide   bg-white  border-red-800 rounded-full dark:bg-gray-800  shadow  font-semibold dark:text-red-400 e capitalize">
        {t("Bord.NonAssigné")}
      </div>
    );
  } else if (value?.length > 15) {
    return (
      <div className="flex items-center ml-3">
        <div className="text-sm  text-gray-900 dark:text-white">
          <ToolTip type="agent" value={value} color="gray" />
        </div>
      </div>
    );
  } else {
    return (
      <div className="text-sm text-gray-900 ml-3 dark:text-white capitalize">
        {value}
      </div>
    );
  }
}
export function Character(props) {
  if (props.value?.length > 50) {
    return (
      <div className="flex items-center ">
        <div
          className={classNames(
            props.value
              .toLowerCase()
              ?.includes(props.cell.row.original.query?.toLowerCase())
              ? "bg-yellow-100  block p-1 rounded-full"
              : "text-sm cursor-help text-gray-900 dark:text-white"
          )}
        >
          <ToolTip value={props.value} color="gray" />
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={classNames(
          props.value
            .toLowerCase()
            ?.includes(props.cell.row.original.query?.toLowerCase())
            ? "bg-yellow-100   block p-1 rounded-full"
            : "",
          "text-sm text-gray-900 dark:text-white capitalize"
        )}
      >
        {props.value}
      </div>
    );
  }
}

export function CharacterSLA({ value }) {
  var colorSLA = "";
  switch (Number(value.substring(0, 1))) {
    case 1:
      colorSLA = "red";
      break;
    case 3:
      colorSLA = "yellow";
      break;
    case 2:
      colorSLA = "green";
      break;
    default:
      colorSLA = "yellow";

      break;
  }
  return (
    <div className="flex  ">
      <div className="text-sm text-gray-900 dark:text-white">
        <ToolTip
          value={value.substring(2, value.length)}
          type="sla"
          color="gray"
          colorSLA={colorSLA}
        />
      </div>
    </div>
  );
}

export function CharacterCLient(props) {
  return (
    <div className="flex items-center ml-1">
      <div
        className={classNames(
          props.value
            ?.toLowerCase()
            .includes(props.cell.row.original.query?.toLowerCase())
            ? "bg-yellow-100  block p-1 rounded-full"
            : " text-gray-600 text-lg border font-semibold border-purple-500  rounded-md px-2 text-center   border-dashed  cursor-help dark:text-white"
        )}
      >
        <ToolTip value={props.value} type="client" color="gray" />
      </div>
    </div>
  );
}

export function Tool({ value }) {
  return (
    <div className="flex items-center ml-1">
      <div className="text-sm text-gray-900 dark:text-white">
        <ToolTip value={value} color="gray" />
      </div>
    </div>
  );
}

export function Space({ value }) {
  return (
    <div className="flex items-center  dark:text-white whitespace-normal">
      {value}
    </div>
  );
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    const dispatch = useDispatch();

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
      dispatch({ type: ENABLE_REFRESH });

      if (indeterminate) dispatch({ type: DISABLE_REFRESH });
    }, [resolvedRef, indeterminate]);

    return (
      <div className=" ">
        <input
          className="  checked:bg-gray-500 dark:bg-gray-500"
          aria-checked="mixed"
          type="checkbox"
          ref={resolvedRef}
          {...rest}
        />
      </div>
    );
  }
);

function Table({
  columns,
  data,
  pageNumber,
  pageLast,
  setPage,
  loadingg,
  idD,
  setIdD,
  setQuery,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    preGlobalFilteredRows,
    selectedFlatRows,
    allColumns,
  } = useTable(
    {
      columns,
      data,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: pageNumber - 1,
          }),
          [state]
        );
      },
      initialState: {
        pageIndex: pageNumber - 1,
        hiddenColumns: ["service", "groupe"],
      },
      manualPagination: true,
      pageCount: pageLast,
    },
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ rows, getToggleAllRowsSelectedProps }) => (
            <div
              className="ml-2"
              onClick={() => {
                if (selectedFlatRows.length === 0) setSelectMenu("");
              }}
            >
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div
              className="ml-3"
              onClick={() => {
                if (selectedFlatRows.length === 0) setSelectMenu("");
              }}
            >
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    },
    useSticky,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination, // new
    useRowSelect
  );

  const [openModalGroupe, setOpenModalGroupe] = useState(false);
  const [openFiltreColumn, setOpenFiltreColumn] = useState(false);

  const [selectMenu, setSelectMenu] = useState("");
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <>
      <div className="  xl:flex xl:flex-row    justify-between ">
        <div className=" xl:flex xl:flex-row lg:flex lg:flex-row md:flex md:flex-row sm:flex sm:flex-row flex flex-col md:gap-x-2  xl:justify-evenly space-x-2">
          <GlobalFilter
            setIdD={setIdD}
            setPage={setPage}
            preGlobalFilteredRows={preGlobalFilteredRows}
            setQuery={setQuery}
          />

          {headerGroups.map((headerGroup) =>
            headerGroup.headers.map((column) =>
              column.Filter ? (
                <div className="mt-2 sm:mt-0" key={column.id}>
                  {column.render("Filter")}
                </div>
              ) : null
            )
          )}
          <div className="relative">
            <div className="flex items-center ">
              <div className=" text-base mr-2 dark:text-white text-gray-600">
                {" "}
                {t("All.Colonnes")}{" "}
              </div>
              <div
                onClick={() => setOpenFiltreColumn(!openFiltreColumn)}
                className="bg-white  border-2 rounded-md   flex p-1 justify-between items-center cursor-pointer  max-h-12 dark:bg-gray-800"
              >
                <AiOutlineColumnWidth className="w-8 h-8 flex dark:text-white text-gray-400" />
              </div>
            </div>

            <div className="absolute z-20  top-0 left-full bg-white dark:bg-gray-600">
              <Transition
                as={Fragment}
                show={openFiltreColumn}
                enter="transition ease-out duration-500"
                enterFrom="transform opacity-0 scale-20 "
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-out duration-200"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-20"
              >
                <div className="flex shadow border rounded-md flex-col">
                  <div className="tooltip-arrow" data-popper-arrow>
                    {allColumns
                      .slice(1, allColumns.length)
                      .map((col, index) => (
                        <div
                          key={`key_filtre_col${index}`}
                          className="block m-1"
                        >
                          <label className="p-2 border rounded-md cursor-pointer dark:hover:bg-gray-500 hover:bg-gray-200 items-center flex ">
                            <input
                              className="mr-2"
                              type="checkbox"
                              // onClick={()=> localStorage.setItem("getHidden",JSON.stringify(state.hiddenColumns.toString(),null,2) ) }

                              {...col.getToggleHiddenProps()}
                            />
                            <div className="dark:text-white">{col.Header}</div>
                          </label>
                        </div>
                      ))}
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </div>

        {selectedFlatRows?.length > 0 && (
          <div className="">
            <Transition
              as={Fragment}
              show={selectedFlatRows?.length > 0 ? true : false}
              enter="transition ease-out duration-500"
              enterFrom="transform opacity-0 scale-20 "
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-out duration-200"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-20"
            >
              {user.Id_D_A !== 2 ? (
                <div className="flex mr-5 ">
                  <div
                    onClick={() => {
                      setSelectMenu("Déplacer");
                      setOpenModalGroupe(!openModalGroupe);
                      dispatch({ type: DISABLE_REFRESH });
                    }}
                    className={classNames(
                      selectMenu === "Déplacer"
                        ? "block hover:bg-gray-200 cursor-pointer bg-gray-200 rounded-full hover:shadow text-black items-center  px-4 py-2  text-sm hover:text-blue-300 hover:cursor-pointer"
                        : "block hover:bg-gray-200 cursor-pointer  rounded-full hover:shadow text-black items-center  px-4 py-2  text-sm hover:text-blue-300 hover:cursor-pointer"
                    )}
                  >
                    <ToolTip
                      value={t("Ticket.DéplacerticketDossier")}
                      type="Déplacer"
                      color="gray"
                    />
                  </div>
                  <div
                    onClick={() => {
                      setSelectMenu("Modifier");
                      setOpenModalGroupe(!openModalGroupe);
                      dispatch({ type: DISABLE_REFRESH });
                    }}
                    className={classNames(
                      selectMenu === "Modifier"
                        ? "block hover:bg-gray-200 cursor-pointer bg-gray-200 rounded-full hover:shadow text-black items-center  px-4 py-2  text-sm hover:text-blue-300 hover:cursor-pointer"
                        : "block hover:bg-gray-200 cursor-pointer  rounded-full hover:shadow text-black items-center  px-4 py-2  text-sm hover:text-blue-300 hover:cursor-pointer"
                    )}
                  >
                    <ToolTip
                      value={t("Ticket.AjourTicket")}
                      type="Modifier"
                      color="gray"
                    />
                  </div>
                  {idD !== 0 && (
                    <div
                      onClick={() => {
                        setSelectMenu("remettre");
                        setOpenModalGroupe(!openModalGroupe);
                        dispatch({ type: DISABLE_REFRESH });
                      }}
                      className={classNames(
                        selectMenu === "remettre"
                          ? "block hover:bg-gray-200 cursor-pointer bg-gray-200 rounded-full hover:shadow text-black items-center  px-4 py-2  text-sm hover:text-blue-300 hover:cursor-pointer"
                          : "block hover:bg-gray-200 cursor-pointer  rounded-full hover:shadow text-black items-center  px-4 py-2  text-sm hover:text-blue-300 hover:cursor-pointer"
                      )}
                    >
                      <ToolTip value="Remettre" type="Remettre" color="gray" />
                    </div>
                  )}
                  {idD !== 3 && (
                    <div
                      onClick={() => {
                        setSelectMenu("delete");
                        setOpenModalGroupe(!openModalGroupe);
                        dispatch({ type: DISABLE_REFRESH });
                      }}
                      className={classNames(
                        selectMenu === "delete"
                          ? "block hover:bg-gray-200 cursor-pointer bg-gray-200 rounded-full hover:shadow text-black items-center  px-4 py-2  text-sm hover:text-blue-300 hover:cursor-pointer"
                          : "block hover:bg-gray-200  cursor-pointer rounded-full hover:shadow text-black items-center  px-4 py-2  text-sm hover:text-blue-300 hover:cursor-pointer"
                      )}
                    >
                      <ToolTip
                        value={t("All.Supprimer")}
                        type="Delete"
                        color="gray"
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex mr-5 ">
                  <div
                    onClick={() => {
                      setSelectMenu("Commenter");
                      setOpenModalGroupe(!openModalGroupe);
                      dispatch({ type: DISABLE_REFRESH });
                    }}
                    className={classNames(
                      selectMenu === "Commenter"
                        ? "block hover:bg-gray-200 cursor-pointer bg-gray-200 rounded-full hover:shadow text-black items-center  px-4 py-2  text-sm hover:text-blue-300 hover:cursor-pointer"
                        : "block hover:bg-gray-200 cursor-pointer  rounded-full hover:shadow text-black items-center  px-4 py-2  text-sm hover:text-blue-300 hover:cursor-pointer"
                    )}
                  >
                    <ToolTip
                      value="Commenter les tickets"
                      type="Commenter"
                      color="gray"
                    />
                  </div>
                  <div
                    onClick={() => {
                      setSelectMenu("Fusionner");
                      setOpenModalGroupe(!openModalGroupe);
                      dispatch({ type: DISABLE_REFRESH });
                    }}
                    className={classNames(
                      selectMenu === "Fusionner"
                        ? "block hover:bg-gray-200 cursor-pointer bg-gray-200 rounded-full hover:shadow text-black items-center  px-4 py-2  text-sm hover:text-blue-300 hover:cursor-pointer"
                        : "block hover:bg-gray-200 cursor-pointer  rounded-full hover:shadow text-black items-center  px-4 py-2  text-sm hover:text-blue-300 hover:cursor-pointer"
                    )}
                  >
                    <ToolTip
                      value="Fusionner les tickets séléctionnés dans un ticket"
                      type="Fusionner"
                      color="gray"
                    />
                  </div>
                </div>
              )}
            </Transition>
          </div>
        )}
      </div>

      {openModalGroupe && (
        <GestionGroupeTicket
          page={pageNumber}
          type={selectMenu}
          data={selectedFlatRows}
          close={() => {
            dispatch({ type: ENABLE_REFRESH });
            setOpenModalGroupe(false);
          }}
        />
      )}

      {/* table */}
      <div className="mt-4  flex flex-col relative  ">
        <div className="-my-2 overflow-x-scroll  -mx-4 xs:-mx-10 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle  inline-block sm:px-6 lg:px-5">
            <div className="shadow border-b  border-gray-200 sm:rounded-lg">
              <table
                {...getTableProps()}
                className="min-w-full table  divide-y divide-gray-200"
              >
                <thead className="bg-gray-50    dark:bg-gray-800">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                      {headerGroup.headers.map((column, i) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          scope="col"
                          className={classNames(
                            //i===1  ?"sticky left-0 bg-gray-50    dark:bg-gray-800  z-10": "z-0",
                            "group font-bold  px-2 py-3 w-screen text-left text-sm text-gray-500 dark:text-white uppercase tracking-wider"
                          )}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <>
                            <div className="flex items-center justify-between">
                              {column.render("Header")}
                              {/* Add a sort direction indicator */}
                              {/* Sort avec 3 cas ( 2cas dans un cas ) */}
                              <span>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <SortDownIcon className="w-4 h-4 dark:text-white text-gray-400" />
                                  ) : (
                                    <SortUpIcon className="w-4 h-4 dark:text-white text-gray-400" />
                                  )
                                ) : (
                                  <SortIcon className="w-4 h-4 dark:text-white text-gray-400 opacity-0 group-hover:opacity-100" />
                                )}
                              </span>
                            </div>
                          </>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody
                  {...getTableBodyProps()}
                  className="bg-white dark:bg-gray-800 divide-y divide-gray-200 body"
                >
                  {loadingg ? (
                    <tr>
                      <td />
                      <td
                        rowSpan={columns.length * 20}
                        colSpan={columns.length * 20}
                      >
                        <>
                          <div className="flex justify-center items-center text-2xl m-4 text-gray-500">
                            {t("All.Chargement")}...
                          </div>
                        </>
                      </td>
                    </tr>
                  ) : page.length === 0 ? (
                    <tr>
                      <td />
                      <td
                        rowSpan={columns.length * 20}
                        colSpan={columns.length * 20}
                      >
                        <>
                          <div className="flex justify-center  items-center text-2xl m-4 text-gray-500">
                            {t("All.Vide")}...
                          </div>
                        </>
                      </td>
                    </tr>
                  ) : (
                    page.map((row, i) => {
                      // new
                      prepareRow(row);

                      return (
                        <tr
                          //
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell, i) => (
                            <td
                              {...cell.getCellProps()}
                              className={classNames(
                                // i===1 ?"sticky left-0 border-gray-400 bg-white dark:bg-gray-800  border-solid z-10 ": "",
                                "px-1 py-4 w-screen   whitespace-nowrap"
                              )}
                              role="cell td"
                            >
                              {cell.column.Cell.name === "defaultRenderer" ? (
                                <>
                                  <div className="text-sm  dark:text-white text-gray-500">
                                    {cell.render("Cell")}
                                  </div>
                                </>
                              ) : (
                                cell.render("Cell")
                              )}
                            </td>
                          ))}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Pagination */}
    </>
  );
}

export default Table;
