import {
  Get_Ticket,
  Get_Ticket_Failed,
  ADD_TICKET,
  ADD_TICKET_FAILED,
  FETCH_TICKET,
  UPDATE_TICKET_SERVICE,
  UPDATE_TICKET_SERVICE_FAILED,
  SUCCESS_TICKET_UPDATE,
  SUCCESS_TICKET_UPDATE_OUT,
  GET_TICKET_FILTER,
  GET_TICKET_FILTER_OUT,
  UPDATE_TICKET,
  UPDATE_TICKET_FAILED,
  DELETE_GROUPE_TICKET,
  DELETE_GROUPE_TICKET_FAILED,
  COMMENTER_GROUPE_TICKET,
  COMMENTER_GROUPE_TICKET_FAILED,
  ADD_COMMENTER_TICKET,
  GET_COMMENTER_TICKET,
  GET_COMMENTER_TICKET_FAILED,
  GET_COMMENTER_FETCH__TICKET,
  UPDATE_MINE_TICKET,
  DELETE_TICKET,
  ENABLE_REFRESH,
  DISABLE_REFRESH,
  SET_FILTER_TICKET,
  SET_STATUS_TO_FILTER,
} from "../actions/types";
import { t } from "i18next";

const initialState = {
  error: "",
  ticket: [],
  firstItem: 0,
  lastItem: 0,
  page: 1,
  pageLast: 1,
  loading: false,
  total: 0,
  success: "",
  successG: "",
  filter: false,
  filterStatus: false,
  commentaire: [],
  loadingC: false,
  mine: false,
  limit: 8,
  refrshEnabled: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TICKET:
      return {
        ...state,
        error: "",
        loading: true,
        filter: false,
      };

    case Get_Ticket:
      return {
        ...state,
        mine: payload.mine,
        total: payload.total,
        error: "",
        ticket: payload.ticket,
        firstItem: payload.firstItem,
        lastItem: payload.lastItem,
        page: payload.page,
        pageLast: payload.pageLast,
        loading: false,
        success: "",
        filter: false,
        limit: payload.limit,
      };
    case GET_TICKET_FILTER:
      return {
        ...state,
        total: payload.total,
        error: "",
        ticket: payload.ticket,
        page: payload.page,
        pageLast: payload.pageLast,
        loading: false,
        firstItem: payload.firstItem,
        lastItem: payload.lastItem,
        success: "",
        successG: "",
        filter: true,
        limit: payload.limit,
      };

    case UPDATE_MINE_TICKET:
      return {
        ...state,
        loading: true,
        mine: !state.mine,
      };
    case GET_TICKET_FILTER_OUT:
      return {
        ...state,
        ticket: !state.ticket ? [...initialState.ticket] : [...state.ticket],
        loading: false,

        success: "",
        successG: "",
        filter: false,
      };
    case Get_Ticket_Failed:
      return {
        ...state,
        ticket: !state.ticket ? [...initialState.ticket] : [...state.ticket],
        loading: false,
        error: payload,
        success: "",
        successG: "",

        filter: false,
      };
    case ADD_TICKET:
      return {
        ...state,
        loading: false,
        ticket: !state.ticket
          ? [payload.ticket, ...initialState.ticket]
          : [payload.ticket, ...state.ticket],
        success: payload.success,
        successG: payload.successG,
        firstItem: !state.firstItem
          ? initialState.firstItem + 1
          : state.firstItem,
        lastItem: !state.lastItem
          ? initialState.lastItem + 1
          : state.lastItem + 1,
        total: !state.total ? initialState.total + 1 : state.total + 1,

        filter: false,
        error: "",
      };
    case ADD_TICKET_FAILED:
      return {
        ...state,
        loading: false,
        error: t("Ticket.Problemecnx"),
        success: "",
        successG: "",

        filter: false,
      };
    case UPDATE_TICKET:
      return {
        ...state,
        loading: false,
        ticket: state.ticket?.map((tick) =>
          tick.Id_Tckt === payload.ticket.Id_Tckt ? payload.ticket : tick
        ),
        success: payload.success,
        successG: payload.successG,

        error: "",
      };
    case UPDATE_TICKET_FAILED:
      return {
        ...state,
        loading: false,
        filter: false,
      };
    case UPDATE_TICKET_SERVICE:
      return {
        ...state,
        loading: false,
        ticket: state.ticket?.map((tick) =>
          tick.Id_Tckt === payload.Id_Tckt ? payload : tick
        ),
        filter: false,
      };

    case ADD_COMMENTER_TICKET:
      return {
        filter: false,

        ...state,
        loading: false,
        commentaire: !state.commentaire
          ? [payload, ...initialState.commentaire]
          : [payload, ...state.commentaire],
      };
    case GET_COMMENTER_FETCH__TICKET:
      return {
        ...state,
        loadingC: true,
      };
    case GET_COMMENTER_TICKET:
      return {
        ...state,
        loadingC: false,
        loading: false,
        commentaire: payload.reverse(),
      };
    case GET_COMMENTER_TICKET_FAILED:
      return {
        ...state,
        loading: false,
        commentaire: [],
        error: t("Ticket.Problemecnx"),
      };
    case UPDATE_TICKET_SERVICE_FAILED:
      return {
        ...state,
        ticket: [...state.ticket],
        loading: false,
        error: t("Ticket.Problemecnx"),

        filter: false,
      };
    case SUCCESS_TICKET_UPDATE: {
      return {
        ...state,
        success: payload.success,
        successG: payload.successG,
        filter: false,
      };
    }
    case SUCCESS_TICKET_UPDATE_OUT: {
      return {
        ...state,
        success: "",
        successG: "",

        filter: false,
      };
    }
    case DELETE_GROUPE_TICKET: {
      let filtred = payload.resp.map((item) => {
        return item.Id_Tckt;
      });
      return {
        ...state,
        error: "",
        ticket: state.ticket?.filter((item) => !filtred.includes(item.Id_Tckt)),
        loading: false,
        success: payload.success,
        filter: false,
      };
    }
    case DELETE_TICKET: {
      return {
        ...state,
        error: "",
        ticket: state.ticket?.filter(
          (item) => payload.ticket.Id_Tckt !== item.Id_Tckt
        ),
        loading: false,
        success: payload.success,
        filter: false,
      };
    }
    case DELETE_GROUPE_TICKET_FAILED: {
      return {
        ...state,
        error: "",
        ticket: [...state.ticket],
        loading: false,
        error: t("Ticket.Problemecnx"),
        filter: false,
      };
    }

    case COMMENTER_GROUPE_TICKET: {
      return {
        ...state,
        error: "",
        ticket: state.ticket?.map((itemX) =>
          payload.resp.find((i) => i.Id_Tckt === itemX.Id_Tckt)
            ? payload.resp.find((i) => i.Id_Tckt === itemX.Id_Tckt)
            : itemX
        ),
        loading: false,
        success: payload.success,
        successG: "",
        filter: false,
      };
    }

    case COMMENTER_GROUPE_TICKET_FAILED: {
      return {
        ...state,
        error: "",
        ticket: [...state.ticket],
        loading: false,
        error: t("Ticket.Problemecnx"),
        filter: false,
      };
    }

    case ENABLE_REFRESH: {
      return {
        ...state,
        refrshEnabled: true,
      };
    }
    case DISABLE_REFRESH: {
      return {
        ...state,
        refrshEnabled: false,
      };
    }
    case SET_FILTER_TICKET: {
      return {
        ...state,
        filter: payload,
      };
    }
    case SET_STATUS_TO_FILTER: {
      return {
        ...state,
        filterStatus: action.payload,
      };
    }

    default:
      return state;
  }
}
