import React,{Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from './component/ThemeContext'
import {Provider} from 'react-redux'
import store from './component/JS/store/store'
import Loader from './component/loading/Loader';

import { BrowserRouter } from 'react-router-dom';
if (process.env.NODE_ENV==="production")
console.log=()=>{}
ReactDOM.render(
  <Provider store={store}>
  <ThemeProvider>
  <Suspense fallback={(<div className='flex  h-screen w-screen items-center justify-center '><Loader/></div>)}>
    <BrowserRouter>
    <App />
    </BrowserRouter>
    
  </Suspense>
  </ThemeProvider>,
   </Provider>,
  document.getElementById('root')
);

reportWebVitals();
