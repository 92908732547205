import {
  ADD_FOlder,
  ADD_FOlder_Failed,
  ADD_FOlder_SUCCESS,
  ADD_FOlder_SUCCESS_OUT,
  Get_FOlder,
  Get_FOlder_Failed,
  GET_FOLDER_SELECTED
} from "../actions/types";

const initialState = {
  error: "",
  Fold: [],
  FoldSelected:"",
  success:"",
  loading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
  

    case Get_FOlder:
      return {
        ...state,
        error: "",
        success:"",
        Fold: payload,
        FoldSelected:"",

        loading: false,

      };


    case Get_FOlder_Failed:
      return {
        ...state,
        Fold: [],
        success:"",
        loading: false,
        FoldSelected:"",
        error: "Netwok Failed,Try later.",
      };


    case ADD_FOlder:
      return {
        ...state,
        error: "",
        success:"",
        FoldSelected:"",
        Fold: [...state.Fold,payload],
        loading: false,

      };


    case ADD_FOlder_Failed:
      return {
        ...state,
        Fold: [...state.Fold],
        success:"",
        loading: false,
        FoldSelected:"",

        error: "Netwok Failed,Try later.",
      };
      case ADD_FOlder_SUCCESS:
        return{
          ...state,
          success:"dossier ajouté",
        FoldSelected:"",

        }

        case GET_FOLDER_SELECTED:
          return{
            ...state,
            FoldSelected:"change"
          }
      case ADD_FOlder_SUCCESS_OUT:
      return{
        ...state,
        success:"",
        FoldSelected:"",

      }
    default:
      return state;
  }
}
