import React, { useEffect, useState, Fragment } from "react";
import { MdAttachFile } from "react-icons/md";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { CgSpinner } from "react-icons/cg";
import axiosInstance from "./component/JS/axiosInstance";

const Formulaire = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [token, setToken] = useState("");
  const [messageApi, setMessageApi] = useState("");

  const [listSujet, setListSujet] = useState([]);
  const [sujetInput, setSujetInput] = useState(false);
  // const [formvalue, setFormvalue]= useState({ nom:'',prenom:'',email:'',message:'',sujet:''});
  const [formerror, setFormerror] = useState({});
  const [issubmit, setSubmit] = useState(false);

  const [email, setEmail] = useState("");
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [message, setMessage] = useState("");
  const [sujet, setSujet] = useState(0);
  const [numTelephone, setNumTelephone] = useState("");
  const [loadSujet, setLoadSujet] = useState(false);
  // const [identifiantUnique, setIdentifiantUnique] = useState(0);
  const [codeClient, setClient] = useState("");
  const [notifSucces, setNotifSucces] = useState(false);

  const [uploadFile, setUploadFile] = useState({
    file: "",
    filename: "",
    filesize: "",
    filetype: "",
  });

  const [loading, setLoading] = useState(false);

  const RechercheMail = async () => {
    var formdata = new FormData();
    formdata.append("email", email);

    try {
      const response = await axiosInstance.post(
        "Client/recherche_byemail",
        formdata
      );
      if (response?.status === 200) {
        if (response.data) {
          console.log("recherchemail", JSON.stringify(response.data));
          CreationTicket(response.data);
        } else {
          CreationClient(nom, prenom, email);
        }
      }
    } catch (error) {
      console.error(`Error ${error}`);
    }
  };

  const CreationClient = async (nom, prenom, email) => {
    var formdata = new FormData();

    formdata.append("Nom", nom);
    formdata.append("Prenom", prenom);
    formdata.append("CIN", "0");
    formdata.append("Email", email);
    formdata.append("tel", numTelephone);
    formdata.append("username", email);
    formdata.append("password", email);
    formdata.append("RS", nom + " " + prenom);
    formdata.append("id", "1");

    try {
      const response = await axiosInstance.post("Client/storeRapide", formdata);
      if (response?.status === 200) {
        if (response.data) {
          setClient(response.data.code_client);
          CreationTicket(response.data.code_client);
        }
      }
    } catch (error) {
      console.error(`Error ${error}`);
    }
  };

  const CreationTicket = async (result) => {
    var formdata = new FormData();
    formdata.append("code_client", result);
    formdata.append("Id_Priorite", "1");
    formdata.append("Id_Status", "1");

    formdata.append("Sujet", sujet);
    formdata.append("sla", "");
    formdata.append("Desc_Scis", message);
    formdata.append("Commentaires", "");
    formdata.append("file", uploadFile.file);
    formdata.append("type", uploadFile.filetype);
    formdata.append("filename", uploadFile.filename);
    formdata.append("filesize", uploadFile.filesize);
    formdata.append("id", "1");
    try {
      const response = await axiosInstance.post(
        "ticket/storeclient/2",
        formdata
      );
      if (response?.status === 200) {
        setMessageApi("Merci de bien vouloir consulter votre boite e-mail");
        setNom("");
        setPrenom("");
        setEmail("");
        setMessage("");
        setSujet("");
        setNumTelephone("");
        setUploadFile({
          filename: "",
          filetype: "",
          filesize: 0,
          file: null,
          preview: null,
        });
        setLoading(false);
        setTimeout(() => {
          setMessageApi(false);
        }, 6000);

        setTimeout(() => {
          setNotifSucces(true);
        }, 3000);
      }
    } catch (error) {
      console.error(`Error ${error}`);
    }
  };

  const Login = async () => {
    var formdata = new FormData();
    formdata.append("username", "client@gmail.com");
    formdata.append("password", "test2020");

    try {
      const response = await axiosInstance.post("login", formdata);
      if (response?.status === 200) {
        setToken(response.data.data.token);
      }
    } catch (error) {
      console.error(`Error ${error}`);
    }
  };

  const Sujet = () => {
    axiosInstance
      .get("sujet")
      .then((result) => {
        setListSujet(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    setFormerror(
      validationform({ nom, prenom, email, sujet, message, numTelephone })
    );
    setSubmit(true);
  };

  const validationform = (value) => {
    const errors = {};
    const numPattern = /^\+?[0-9]{8,15}$/;
    const emailPattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!value.email) {
      errors.email = "Ce champ est obligatoire";
    } else if (!emailPattern.test(value.email)) {
      errors.email = "Email est invalide";
    }
    if (!value.prenom) {
      errors.prenom = "Ce champ est obligatoire";
    }

    if (!value.nom) {
      errors.nom = "Ce champ est obligatoire";
    }

    if (!value.message) {
      errors.message = "Ce champ est obligatoire";
    }

    if (!value.sujet) {
      errors.sujet = "Ce champ est obligatoire";
    }

    if (!value.numTelephone) {
      errors.numTelephone = "Ce champ est obligatoire";
    } else if (!numPattern.test(value.numTelephone)) {
      errors.numTelephone = "Le numéro est invalide";
    }

    return errors;
  };

  const getTokenFromUrl = () => {
    const url_string = window.location.href;
    var url = new URL(url_string);
    setNom(url.searchParams.get("nom"));
    setPrenom(url.searchParams.get("prenom"));
    setEmail(url.searchParams.get("email"));
    // setTelephone(url.searchParams.get("tel"));
  };

  useEffect(() => {
    if (Object.keys(formerror).length === 0 && issubmit) {
      RechercheMail();
      setLoading(true);
    }
  }, [formerror, issubmit]);

  useEffect(() => {
    getTokenFromUrl();
    Login();
  }, []);

  useEffect(() => {
    if (token) {
      Sujet();
    }
  }, [token]);

  return (
    <div className="flex  items-center justify-center">
      <div className=" w-2/3 pt-6 sm:pt-0 rounded-lg   ">
        <form onSubmit={handlesubmit}>
          <div className=" ">
            <div className="bg-white py-6 px-4 border-blue-300 border-2  sm:p-6 mt-8 shadow-2xl rounded-2xl">
              <div className="flex flex-col justify-center container mx-auto items-center">
                <h2 className="text-xl leading-6 font-bold text-blue-500 flex justify-center">
                  Vous avez une demande ?
                </h2>
                <p className="px-4 mt-2">
                  Remplisser le formulaire et envoyez-nous votre demande,nous
                  serons ravie de vous répondre
                </p>
              </div>

              {messageApi.length > 0 ? (
                <div className=" mt-4 animate-bounce flex justify-center">
                  <p className="text-xl text-green-500  ">
                    Votre demande de réclamation a été créé avec succès
                  </p>
                </div>
              ) : null}

              <div className="mt-10">
                <div className="grid grid-cols-4 gap-4  ">
                  <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                    <label className="block  text-sm font-medium text-gray-700">
                      Nom
                    </label>
                    <input
                      value={nom}
                      onChange={(e) => setNom(e.target.value)}
                      placeholder="Veuillez saisir le nom"
                      type="text"
                      autocomplete="off"
                      className=" mt-3 block w-full border-2 border-blue-400  rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />

                    {formerror.nom && (
                      <div className=" ml-2 mt-2 ">
                        <p className="text-red-500  ">{formerror.nom}</p>
                      </div>
                    )}
                  </div>

                  <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                    <label className="block  text-sm font-medium text-gray-700">
                      Prénom
                    </label>
                    <input
                      value={prenom}
                      onChange={(e) => setPrenom(e.target.value)}
                      placeholder="Veuillez saisir le prénom"
                      type="text"
                      autocomplete="off"
                      className=" mt-3 block w-full border-2 border-blue-400  rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />

                    {formerror.prenom && (
                      <div className=" ml-2 mt-2 ">
                        <p className="text-red-500  ">{formerror.prenom}</p>
                      </div>
                    )}
                  </div>

                  <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                    <label className="block  text-sm font-medium text-gray-700">
                      Numéro de téléphone *
                    </label>
                    <input
                      value={numTelephone}
                      onChange={(e) => setNumTelephone(e.target.value)}
                      placeholder="Veuillez saisir l'identifiant"
                      type="text"
                      autocomplete="off"
                      className=" mt-3 block w-full border-2 border-blue-400  rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />

                    {formerror.numTelephone && (
                      <div className="ml-2r mt-2 ">
                        <p className="text-red-500  ">
                          {formerror.numTelephone}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="col-span-4 sm:col-span-2 lg:col-span-2">
                    <label className="block  text-sm font-medium text-gray-700">
                      Adresse Mail *
                    </label>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Veuillez saisir l'email"
                      type="text"
                      autocomplete="off"
                      className=" mt-3 block w-full border-2 border-blue-400  rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />

                    {formerror.email && (
                      <div className=" ml-2 mt-2 ">
                        <p className="text-red-500  ">{formerror.email}</p>
                      </div>
                    )}
                  </div>

                  <div className="col-span-4 sm:col-span-2 lg:col-span-4">
                    <label className="block dark:text-white text-sm font-medium text-gray-700">
                      Sujet *
                    </label>

                    <select
                      //id={sujet.id}
                      className=" mt-3 block w-full  py-2 text-base border-2 border-blue-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      onChange={(e) => {
                        if (e.target.value === "Autres") {
                          setSujet(e.target.value);
                          setSujetInput(true);
                        } else {
                          setSujetInput(false);
                          setSujet(e.target.value);
                        }
                      }}
                      value={sujet}
                    >
                      <option>Choisir le sujet</option>
                      {loadSujet ? (
                        <option>loading...</option>
                      ) : (
                        <>
                          {listSujet.map((post, i) => (
                            <option
                              key={i}
                              value={post.sujet}
                              className=" text-gray-700  origin-top-right absolute overflow-y-scroll overflow-x-hidden h-28 right-0 dark:bg-gray-700 dark:text-white mt-2 w-36 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              {post.sujet}
                            </option>
                          ))}
                        </>
                      )}
                    </select>

                    {formerror.sujet && (
                      <div className=" ml-2 mt-2 ">
                        <p className="text-red-500  ">{formerror.sujet}</p>
                      </div>
                    )}
                  </div>

                  {sujetInput ? (
                    <>
                      <div className="col-span-4 sm:col-span-2 lg:col-span-4">
                        <label className="block  text-sm font-medium text-gray-700">
                          Autre sujet *
                        </label>
                        <input
                          // value={formvalue.sujet}
                          onChange={(e) => setSujet(e.target.value)}
                          placeholder="Veuillez saisir l'objet"
                          type="text"
                          autocomplete="off"
                          className=" mt-3 block w-full border-2 border-blue-400  rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />

                        {formerror.sujet && (
                          <div className=" ml-2 mt-2 ">
                            <p className="text-red-500">{formerror.sujet}</p>
                          </div>
                        )}
                      </div>
                    </>
                  ) : null}

                  <div className="col-span-4 sm:col-span-2 lg:col-span-4">
                    <label className="block  text-sm font-medium text-gray-700">
                      Message *
                    </label>
                    <textarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Veuillez saisir le message"
                      type="text"
                      autocomplete="off"
                      className=" mt-3 block w-full border-2 border-blue-400  rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {formerror.message && (
                      <div className=" ml-2 mt-2 ">
                        <p className="text-red-500  ">{formerror.message}</p>
                      </div>
                    )}
                  </div>

                  <div className="w-full col-span-4  flex items-center justify-start ">
                    <label
                      className="bg-gray-400 h-10  w-32 items-center  rounded-full shadow
           capitalize cursor-pointer text-lg  hover:bg-gray-300  border border-transparent  inline-flex justify-center  font-medium text-white "
                    >
                      <MdAttachFile size={20} className="text-white mr-1" />

                      <p className="text-sm ">Piéce jointe</p>
                      <input
                        accept="image/*,application/pdf"
                        type="file"
                        className="hidden"
                        onChange={(e) => {
                          const reader = new FileReader();
                          const file = e.target.files[0];
                          console.log(file);

                          reader.onload = function () {
                            // if (file.size > 4e6) {
                            //   window.alert(
                            //     "Please upload a file smaller than 4 MB"
                            //   );
                            //   return false;
                            // }
                            setUploadFile({
                              filename: file.name,
                              filetype: file.type,
                              filesize: file.size,
                              file,
                              preview: reader.result,
                            });
                          };
                          reader.onerror = function () {
                            setUploadFile({
                              filename: "",
                              filetype: "",
                              filesize: 0,
                              file: null,
                              preview: null,
                            });
                          };
                          reader.readAsDataURL(file);
                        }}
                      />
                    </label>
                    {uploadFile && uploadFile?.filetype?.includes("image") ? (
                      <img
                        src={uploadFile.preview}
                        className="h-16 w-32 object-contain hover:shadow-md  "
                        alt="upload_file"
                      />
                    ) : (
                      <div className="ml-2 italic max-w-16 underline font-light overflow-x-visible">
                        {uploadFile?.filename}
                      </div>
                    )}
                    {uploadFile?.filename && (
                      <AiOutlineCloseCircle
                        size={25}
                        onClick={() =>
                          setUploadFile({
                            filename: "",
                            filetype: "",
                            filesize: 0,
                            file: null,
                            preview: null,
                          })
                        }
                        className=" text-red-400   h-5 w-5 over:opacity-50 cursor-pointer "
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="flex justify-center ">
                <button
                  style={{ backgroundColor: "#1f999e" }}
                  // onClick={()=>{
                  //   setLoading(true)
                  //   RechercheMail()
                  //   }}
                  disabled={loading}
                  className="w-40 h-12 bg-blue-500 rounded-full mt-4 text-white "
                >
                  {loading ? (
                    <div className="flex  justify-center  items-center w-40 h-12  bg-blue-500 rounded-full ">
                      <div className="animate-spin">
                        <CgSpinner size={30} color="white" />
                      </div>
                    </div>
                  ) : (
                    <span>Envoyer demande</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Formulaire;
