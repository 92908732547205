import axiosInstance from "../JS/axiosInstance";

export async function sendMailFunction(
  userSender,
  userReciever,
  recieverBCC,
  userSujet,
  userMessage,
  filename,
  path
) {
  var formdata = new FormData();

  formdata.append("userSender", userSender);
  formdata.append("userReciever", userReciever);
  formdata.append("userSujet", userSujet);
  formdata.append("userMessage", userMessage);
  formdata.append("recieverBCC", recieverBCC);
  formdata.append("filename", filename);
  formdata.append("path", path);

  try {
    const res = await axiosInstance.post("ticket/sendMail", formdata);
    if (res?.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.error(`Error ${error}`);
  }
}
