import React, {
  useCallback,
  useEffect,
  useState,
  Fragment,
  useRef,
  useMemo,
} from "react";
import {
  AiFillClockCircle,
  AiFillCloseCircle,
  AiFillFile,
  AiFillFilePdf,
  AiFillFileExcel,
  AiFillFileWord,
  AiFillPicture,
  AiOutlineFlag,
  AiOutlineMail,
  AiOutlineEdit,
} from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { BsArrowLeftRight, BsTelephone } from "react-icons/bs";
import { FaRegBuilding } from "react-icons/fa";
import { BiMap } from "react-icons/bi";
import { Select2, ProgressBar, TextAreaComponent, ToolTip } from "../utils";
import { RiAlertLine } from "react-icons/ri";
import moment from "moment";
import "moment/locale/fr";
import { useDispatch, useSelector } from "react-redux";
import { MdAttachFile } from "react-icons/md";
import ReactLoading from "react-loading";
import { FiHelpCircle } from "react-icons/fi";
import {
  getDetailsTicket,
  updateListTicket,
  updateTicket,
} from "../../JS/Ticket/actions/TicketA";
import { CgSpinner } from "react-icons/cg";

import {
  GET_COMMENTER_FETCH__TICKET,
  SUCCESS_TICKET_UPDATE,
  SUCCESS_TICKET_UPDATE_OUT,
} from "../../JS/Ticket/actions/types";
import { Transition, Dialog, Menu } from "@headlessui/react";
import { Switch } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import axios from "axios";
import { sendMailFunction } from "../../Mail/sendMailFunction";
import FormTicket from "../utils/modals/FormTicket";
import { IoExitOutline } from "react-icons/io5";
import "./VarHome.css";
import updateTicketFunc from "../../function/updateTicket";
import JoditEditor from "./Joditor";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../JS/axiosInstance";
import { ThemeContext } from "../../ThemeContext";
import { Editor } from "../../editor/Editor";
moment.locale("fr");
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function createMarkup(text) {
  return { __html: `${text}` };
}

function VarHome({
  onClose,
  ticket,
  outside,
  setTicket,
  setArray,
  array,
  sideBar,
  notification,
}) {
  const sendBtnRef = useRef(null);

  const editor = useRef(null);
  const keydownHandler = useCallback((e) => {
    if (e.shiftKey && e.keyCode === 13) {
      //
      console.log("shif");
    } else if (e.keyCode === 13) {
      sendBtnRef.current.click();

      e.preventDefault();
    }
  }, []);

  // const config = {
  //   useSearch: false,
  //   spellcheck: true,
  //   enter: "br",
  //   defaultMode: "1",
  //   width: "auto",
  //   height: "auto",
  //   toolbarAdaptive: true,
  //   textIcons: false,
  //   toolbarSticky: true,
  //   showCharsCounter: false,
  //   showWordsCounter: false,
  //   showXPathInStatusbar: false,
  //   askBeforePasteHTML: false,
  //   askBeforePasteFromWord: false,
  //   zIndex: 50,
  //   toolbarButtonSize: 'middle',
  //   minHeight: 200,
  //   maxHeight: 600,
  //   minWidth: null,
  //   useSplitMode: false,
  //   language: "auto",
  //   debugLanguage: false,
  //   addNewLineOnDBLClick: false ,
  //   cleanHTML: {
  //     allowTags: {
  //         p: true,
  //         a: {
  //             href: true
  //         },
  //         table: true,
  //         tr: true,
  //         td: true,
  //     }
  //   },
  //   toolbar: true,
  //   theme: "default",
  //   buttons: "bold,strikethrough,underline,italic,fontsize,link,|,fullsize,|",
  //   buttonsMD: "bold,strikethrough,underline,italic,fontsize,link,|,fullsize,|",
  //   buttonsXS: "bold,strikethrough,underline,italic,fontsize,link,|,fullsize,|",
  //   buttonsSM: "bold,strikethrough,underline,italic,fontsize,link,|,fullsize,|",

  //   editorCssClass: "alic",
  //   placeholder: "Saisir un commentaire...",
  //   controls: {
  //     fontsize: {
  //       list: [
  //         "8",
  //         "9",
  //         "10",
  //         "11",
  //         "12",
  //         "14",
  //         "16",
  //         "18",
  //         "24",

  //       ],
  //     },

  //   },
  //   style:{
  //     background:"#ffffff",
  //     height:"100%",
  //   }
  // };
  const config = {
    style: {
      padding: `9px 0px 9px 10px`,
      //  maxWidth: "593px",
    },

    useSearch: false,
    readonly: false,
    nl2brInPlainText: true,
    enter: "br",
    defaultActionOnPaste: "insert_only_text",
    defaultMode: "1",
    width: "auto",
    height: "auto",
    addNewLineOnDBLClick: false,
    toolbarAdaptive: true,
    toolbarSticky: true,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    allowResizeX: false,
    zIndex: 101,
    minHeight: 200,
    maxHeight: 200,
    useSplitMode: true,
    language: "fr",
    debugLanguage: false,
    spellcheck: true,
    //change hide toolbar
    toolbarInlineForSelection: false,
    toolbar: true,

    i18n: "en",
    theme: "default",
    buttons: [
      "bold",
      "strikethrough",
      "underline",
      "italic",
      "fontsize",
      "fullsize",
    ],
    buttonsMD: [
      "bold",
      "strikethrough",
      "underline",
      "italic",
      "fontsize",
      "fullsize",
    ],
    buttonsSM: [
      "bold",
      "strikethrough",
      "underline",
      "italic",
      "fontsize",
      "fullsize",
    ],
    buttonsXS: [
      "bold",
      "strikethrough",
      "underline",
      "italic",
      "fontsize",
      "fullsize",
    ],

    editorCssClass: "alic",
    cleanHTML: {
      fillEmptyParagraph: false, //   removeEmptyElements:true
    },
    dtd: { removeExtraBr: true },
    // disablePlugins: ["add-new-line", "enter"],
    placeholder: "Saisir un message...",
    controls: {
      fontsize: {
        list: ["8", "12", "14", "16", "18", "24"],
      },
    },
  };

  const cancelButtonRef = useRef(null);
  const refImage = useRef(null);
  const refFormTicket = useRef(null);
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const [colorSLA, setColorSLa] = useState("");
  const [colorSeverite, setColorSeverite] = useState("");
  const [colorFlux, setColorFlux] = useState("");
  const [colorStatut, setColorStatus] = useState("");
  const [detail, setDetail] = useState({});
  const [error, setError] = useState(false);
  const [hiddenCLose, setHiddenClose] = useState(false);
  const [ccInput, setcCInput] = useState(false);
  const [upload, setUpload] = useState(null);
  const [uploadFile, setUploadFile] = useState({
    file: "",
    filename: "",
    filesize: "",
    type: "",
  });
  const [email, setEmail] = useState({
    userSender: user.Email ?? "",
    userRec: "",
    sujet: "",
    cc: "",
    body: "",
  });
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataBase, setDataBase] = useState([]);
  const [tabName, setTabName] = useState({ name: "General", current: true });
  const commentaireData = useSelector((state) => state.ticketR.commentaire);
  const loading = useSelector((state) => state.ticketR.loadingC);
  const successG = useSelector((state) => state.ticketR.successG);
  const [img, setImg] = useState("");
  const [subSubMenu, setSubSubMenu] = useState("Transfert classique");
  const [subSubMenuG, setSubSubMenuG] = useState("échanges");

  const [dataAgent, setDataAgent] = useState([]);
  const [adminData, setDataAdmin] = useState([]);
  const [agentValidate, setAgentValidate] = useState(false);
  const [raisonInputValidate, setRaisonInputValidate] = useState(false);
  const [timeLineData, setTimeLine] = useState([]);
  const [loadingTimeLine, setLoadingTimeLIne] = useState(false);
  const [interventionInputValidate, setInterventionInputValidate] =
    useState(false);
  const [enabled, setEnabled] = useState(false);
  const [enregData, setEnregData] = useState([]);

  const [sms, setSMS] = useState({
    entete: "",
    number: "",
    message: "",
  });
  const [transfertState, setTransfertState] = useState({
    personSelected: { id: "", label: "" },
    raisonInput: "",
    interventionInput: "",
  });
  const [commentaire, setCommentaire] = useState({
    filename: "",
    type: "",
    filesize: "",
    file: null,
  });
  const [messages, setMessages] = useState("");
  const [fullScreen, setFullScreen] = useState(false);

  const [openForm, setOpenForm] = useState(false);
  const [infoTicket, setInfoTicket] = useState({
    Id_Tckt: ticket.Id_Tckt,
    code_ticket: ticket.code_ticket ?? "",
    code_client: ticket.code_client ?? "",
    Id_Pers: user.Id_Pers,
    Flux: ticket.Flux ?? "",
    Id_Priorite: ticket.Id_Priorite ?? "",
    Id_Status: ticket.Id_Status ?? "",
    Id_Type: ticket.Id_Type ?? "",
    Id_Cat: ticket.Id_Cat ?? "",
    Id_Sous_Cat: ticket.Id_Sous_Cat ?? "",
    Id_Voie: ticket.Id_Voie ?? "",
    Id_Source: ticket?.Id_Source ?? "",
    Sujet: ticket.Sujet ?? "",
    sla: ticket.sla ?? "",
    Desc_Scis: ticket.Desc_Scis ?? "",
    Solution: ticket.Solution ?? "",
    Commentaires: "",
    id_departement: ticket.id_departement ?? "",
    id_service: ticket?.id_service ?? "",
    Id_groupe: ticket.Id_groupe ?? "",
  });
  const [clicked, setClicked] = useState(false);
  const [location, setLocation] = useState("");
  const [errorTicket, setErrorTicket] = useState("");
  const [successTicket, setSuccessTicket] = useState(false);

  function handleExit() {
    refFormTicket.current?.classList.add("exit");
    setTimeout(() => {
      refFormTicket.current?.classList.remove("exit");
      setErrorTicket("");
      setOpenForm(false);
    }, 400);
  }
  const ref = useRef();
  const dispatch = useDispatch();

  const getDataBase = () => {
    axiosInstance
      .get("Base_type")
      .then((result) => {
        setDataBase(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  const getDataAgent = (filter) => {
    axiosInstance
      .get("agentsall")
      .then((result) => {
        setDataAgent(
          result.data.filter(
            (r) => JSON.stringify(r.Id_D_A) === JSON.stringify(filter)
          )
        );
      })
      .catch((error) => console.log("error", error));
  };

  const getDataAdmin = async () => {
    axiosInstance
      .get("admin/getall")
      .then((result) => {
        setDataAdmin(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  const getHistoData = async () => {
    setLoadingTimeLIne(true);

    var formdata = new FormData();
    formdata.append("Id_Tckt", ticket.Id_Tckt);

    try {
      const response = await axiosInstance.post("HistoTicketAll", formdata);
      if (response?.status === 200) {
        setTimeLine(response.data);
        setLoadingTimeLIne(false);
      }
    } catch (error) {
      setLoadingTimeLIne(false);
      console.error(`Error ${error}`);
    }
  };

  useEffect(() => {
    if (subSubMenu === "Transfert classique") {
      setTransfertState({
        personSelected: { id: "", label: "" },
        raisonInput: "",
        interventionInput: "",
      });
      setDataAgent([]);
      setDataAdmin([]);

      getDataAgent(2);
    } else if (subSubMenu === "Niveau 1") {
      setTransfertState({
        personSelected: { id: "", label: "" },
        raisonInput: "",
        interventionInput: "",
      });
      setDataAgent([]);
      setDataAdmin([]);

      getDataAgent(4);
    } else if (subSubMenu === "Niveau 2") {
      setTransfertState({
        personSelected: { id: "", label: "" },
        raisonInput: "",
        interventionInput: "",
      });
      setDataAgent([]);
      setDataAdmin([]);
      getDataAdmin();
    }
  }, [subSubMenu]);

  useEffect(() => {
    if (dataBase.length === 0) {
      getDataBase();
    }
    if (tabName.name === "General" && subSubMenuG === "échanges") {
      dispatch({ type: GET_COMMENTER_FETCH__TICKET });

      dispatch(getDetailsTicket(ticket.Id_Tckt, user.Id_Pers));
    }
  }, [ticket.Id_Tckt]);

  const getColor = useCallback(() => {
    switch (Number(ticket.etat_sla.substring(0, 1))) {
      case 1:
        setColorSLa("red");
        break;
      case 3:
        setColorSLa("yellow");
        break;
      case 2:
        setColorSLa("green");
        break;
      default:
        setColorSLa("yellow");

        break;
    }
  }, [ticket.etat_sla]);
  const getColorSeverite = useCallback(() => {
    switch (ticket.Id_Priorite) {
      case 3:
        setColorSeverite("red");
        break;
      case 2:
        setColorSeverite("gray");
        break;
      case 1:
        setColorSeverite("green");
        break;
      default:
        setColorSeverite("gray");

        break;
    }
  }, [ticket.Id_Priorite]);

  const getColorFlux = useCallback(() => {
    switch (ticket.Flux) {
      case 1:
        setColorFlux("green");
        break;
      case 2:
        setColorFlux("red");
        break;

      default:
        setColorFlux("sky");

        break;
    }
  }, [ticket.Flux]);
  const getColorStatut = useCallback(() => {
    switch (ticket.Id_Status) {
      case 1:
        setColorStatus("yellow");
        break;
      case 2:
        setColorStatus("sky");
        break;
      case 3:
        setColorStatus("blue");
        break;
      case 4:
        setColorStatus("purple");
        break;
      case 5:
        setColorStatus("gray");
        break;
      case 6:
        setColorStatus("green");
        break;

      default:
        setColorStatus("yellow");

        break;
    }
  }, [ticket.Id_Status]);

  async function UploadFileFunc(e) {
    var formdata = new FormData();
    formdata.append("Id_Pers", user.Id_Pers);
    formdata.append("file", e);
    formdata.append("type", e.type);
    formdata.append("filename", e.name);
    formdata.append("filesize", e.size);

    try {
      const response = await axiosInstance.post("file/store", formdata);
      if (response?.status === 200) {
        if (response.status === 200) {
          setTimeout(() => {
            setUpload(null);
            setUploadFile({
              file: response.data.full_path,
              filename: response.data.filename,
              type: response.data.type,
            });
          }, 800);
        } else alert("error");
      }
    } catch (error) {
      console.error(`Error ${error}`);
    }
    // onUploadProgress: (data) => {
    //   setUpload(Math.round((data.loaded / data.total) * 100));
    // },
  }
  function buttonValidate() {
    switch (tabName.name) {
      case "General":
        switch (subSubMenuG) {
          case "échanges":
            console.log("messages check", messages);
            // return editor.current?.value?.length === 0;
            return messages === "<p><br></p>" || messages.length === 0;

          case "messages":
            return editor.current.value === 0;
          default:
            return;
        }

      case "Transfert":
        switch (subSubMenu) {
          case "Transfert classique":
            return (
              Object.values(transfertState.personSelected)[0].length === 0 ||
              Object.values(transfertState)[1].length === 0 ||
              Object.values(transfertState)[2].length === 0
            );

          case "Transfert libre":
            if (!enabled) {
              return (
                Object.values(email)[0].length === 0 ||
                !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                  email.userSender
                ) ||
                !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                  email.userRec
                ) ||
                Object.values(email)[1].length === 0 ||
                //  Object.values(email)[3].length === 0 ||
                //  !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.cc))||
                Object.values(email)[2].length === 0
              );
            } else {
              return (
                Object.values(sms)[0].length === 0 ||
                Object.values(sms)[1].length < 8 ||
                Object.values(sms)[2].length === 0
              );
            }
          default:
            return Object.values(transfertState.personSelected)[0].length === 0;
        }
      default:
        break;
    }
  }
  async function validGestionBloc() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + user.token);
    let s;
    switch (tabName.name) {
      case "General":
        switch (subSubMenuG) {
          case "échanges":
            dispatch(
              updateListTicket(
                "commentaire/store",
                {
                  text: messages,
                  filename: commentaire.filename,
                  filesize: commentaire.filesize,
                  type: commentaire.type,
                  file: commentaire.file,
                },
                ticket,
                user,
                false,
                false
              )
            );
            setFullScreen(false);
            setMessages("");
            setCommentaire({
              filename: "",
              filesize: "",
              type: "",
              file: "",
            });
            break;
          case "messages":
            alert("messages");
            break;
        }
        break;

      case "Transfert":
        switch (subSubMenu) {
          case "Transfert libre":
            if (enabled) {
              setClick(true);
              var raw = JSON.stringify({
                numeros_mobile: sms.number,
                en_tete: sms.entete,
                message: sms.message,
              });

              try {
                const response = await axiosInstance.post(
                  "ticket/sendsms",
                  raw
                );
                if (response?.status === 200) {
                  setClick(false);

                  setSMS({
                    number: "",
                    entete: "",
                    message: "",
                  });
                  dispatch({
                    type: SUCCESS_TICKET_UPDATE,
                    payload: {
                      success: "Le ticket a été transféré en SMS avec succès",
                      successG: "",
                    },
                  });
                  if (outside) setTicket({});
                  onClose(false);
                }
              } catch (error) {
                console.error(`Error ${error}`);
              }
            } else {
              setClick(true);
              sendMailFunction(
                email.userSender,
                email.userRec,
                email.cc,
                email.sujet,
                email.body,
                uploadFile.filename,
                uploadFile.file
              )
                .then((r) => {
                  if (r.success === true) {
                    setClick(false);
                    setError("");
                    setEmail({
                      userRec: "",
                      userSender: "",
                      sujet: "",
                      cc: "",
                      body: "",
                    });
                    dispatch({
                      type: SUCCESS_TICKET_UPDATE,
                      payload: {
                        success:
                          "Le ticket a été transféré par E-mail avec succès",
                        successG: "",
                      },
                    });
                    if (outside) setTicket({});
                    onClose(false);
                  }
                })
                .catch((er) => {
                  if (er.name === "AbortError") return;
                  setError(er);
                  setClick(false);
                });
            }

            break;
          default:
            dispatch(
              updateListTicket(
                "ticket/transfert",
                transfertState,
                ticket.Id_Tckt,
                user.Id_Pers,
                true,
                true
              )
            );
            setTransfertState({
              personSelected: { id: "", label: "" },
              raisonInput: "",
              interventionInput: "",
            });

            if (outside) setTicket({});
            onClose(false);

            break;
        }

        break;

      default:
        break;
    }
  }
  const updateNumber = (e) => {
    const val = e.target.value;
    if (!isNaN(val)) setSMS({ ...sms, number: e.target.value });
  };
  useEffect(() => {
    const abrt = new AbortController();
    getColor();
    getColorSeverite();
    getColorFlux();
    getColorStatut();
    if (tabName.name === "Histo" && timeLineData.length === 0) {
      getHistoData();
    }
    return () => {
      abrt.abort();
    };
  }, [tabName.name, ticket]);

  useEffect(() => {
    // const abrt = new AbortController();
    const getDetailClient = async () => {
      axiosInstance
        .get("Clientid/" + ticket.code_client)
        .then((result) => {
          console.log("result.data", result.data);
          setDetail(result.data);
        })
        .catch((error) => console.log("error", error));
    };
    getDetailClient();
  }, [ticket.code_client]);
  useEffect(() => {
    if (successG && successG.startsWith("S"))
      setTimeout(() => {
        dispatch({ type: SUCCESS_TICKET_UPDATE_OUT });
      }, 2500);
  }, [successG]);

  useEffect(() => {
    const abrt = new AbortController();
    const getPlayerData = () => {
      var config = {
        method: "get",
        url:
          process.env.REACT_APP_URL_IPBX +
          "comunik_ipbx/Api/HELPDESK/EnregistrementFinalVoipAPI?compte=client&tel=" +
          detail.Tel +
          "&date1=" +
          ticket.date_creation +
          "&date2=" +
          ticket.date_creation +
          "&CMK_WS_PK_1=&CMK_WS_PK_2&CMK_WS_PK_3&CMK_WS_PK_4&CMK_WS_PK_5",
        headers: {
          Authorization:
            "eyhzrtfCCGhsdsdfsdSDFfk09k94sdfZE.eyFEZR55FVSDGEGdfdc3pmkHG23HVFgsdyj8JJVHFs.sDG523121IMMqs456765UDFgbdfgSDFGjuPK34534HDFG086758CVGRTdgh974435H578FGHsfdk907kzzsfcx345DFHJiBTYTDY",
        },
      };
      axios(config)
        .then(function (response) {
          if (ticket.Id_Voie == 3) {
            if (response.data.enregistrement) {
              setEnregData(response.data.enregistrement);
            } else {
              setEnregData([]);
            }
          } else {
            setEnregData([]);
          }

          // setEnregData(response.data.enregistrement);
        })
        .catch(function (error) {
          if (error.name === "AbortError") return;
        });
    };
    if (tabName.name === "Enregistrement" && enregData.length === 0)
      getPlayerData();

    return () => {
      abrt.abort();
    };
  }, [tabName.name]);

  function getElementTimeLine(key) {
    switch (key) {
      case "General":
        return <ToolTip value={key} type="global" color="gray" />;

      case "Transfert":
        return <ToolTip value={key} type="transfert" color="gray" />;

      case "Fusion":
        return <ToolTip value={key} type="fusion" color="gray" />;
      case "Relance":
        return <ToolTip value={key} type="Relancer2" color="gray" />;
      default:
        break;
    }
  }
  function getSubSubTransfertMenu() {
    return (
      <div className="flex flex-col p-2 ">
        {subSubMenu !== "Transfert libre" ? (
          <>
            <div className="flex items-center p-2 mt-2">
              <Select2
                elementValidate={agentValidate}
                setElementValidate={setAgentValidate}
                disabled={true}
                extra={false}
                defaultItem={{
                  id: user.Id_Pers,
                  label: `${user.Prenom} ${user.Nom} `,
                }}
                dark="600"
                setSelectItem={() => {}}
                name="Agent expéditeur"
                selectPlaceholder="Séléctionner un agent"
                data={[]}
              />
            </div>
            <div className="p-2">
              {dataAgent.length > 0 || adminData.length > 0 ? (
                <div className="flex items-center justify-center">
                  <div className="w-full ">
                    <Select2
                      dark="600"
                      extra={false}
                      defaultItem={
                        Object.values(transfertState.personSelected)[0]
                          .length === 0 ||
                        Object.values(transfertState.personSelected)[1]
                          .length === 0
                          ? null
                          : transfertState.personSelected ?? null
                      }
                      setSelectItem={(e) => {
                        if (e === "" || e === null) {
                          setTransfertState({
                            ...transfertState,
                            personSelected: { id: "", label: "" },
                          });
                        } else {
                          setTransfertState({
                            ...transfertState,
                            personSelected: {
                              id: Number(e?.id),
                              label: e?.label,
                            },
                          });
                        }
                      }}
                      name={
                        subSubMenu === "Niveau 2"
                          ? "Admin**"
                          : subSubMenu === "Transfert classique"
                          ? "Agent destinataire**"
                          : "Agent responsable destinataire**"
                      }
                      selectPlaceholder="Séléctionner un agent"
                      data={subSubMenu === "Niveau 2" ? adminData : dataAgent}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex justify-center items-center ">
                  <ReactLoading
                    type="bubbles"
                    className="text-gray-500 dark:text-white"
                    color="gray"
                  />
                </div>
              )}
            </div>

            <div className="p-2">
              <TextAreaComponent
                numbreRow={3}
                setSujetValidate={(e) => setRaisonInputValidate(e)}
                sujetValidate={raisonInputValidate}
                type={subSubMenu === "Transfert classique" ? true : false}
                nom={
                  subSubMenu === "Transfert classique"
                    ? "Raison du transfert**"
                    : "Raison du transfert"
                }
                onChange={(e) => {
                  setTransfertState({ ...transfertState, raisonInput: e });
                }}
                selected={transfertState.raisonInput}
              />
            </div>

            <div className="p-2">
              <TextAreaComponent
                numbreRow={3}
                setSujetValidate={(e) => setInterventionInputValidate(e)}
                sujetValidate={interventionInputValidate}
                type={subSubMenu === "Transfert classique" ? true : false}
                nom={
                  subSubMenu === "Transfert classique"
                    ? "Intervention effectué**"
                    : "Intervention effectué"
                }
                onChange={(e) => {
                  setTransfertState({
                    ...transfertState,
                    interventionInput: e,
                  });
                }}
                selected={transfertState.interventionInput}
              />
            </div>
          </>
        ) : (
          <div className=" relative flex ">
            <div className="absolute top-0 inset-x-48 flex items-center m-1">
              <div
                className={classNames(
                  enabled
                    ? "text-gray-500 dark:text-white"
                    : "text-indigo-500 dark:text-blue-400",
                  " text-base mr-2"
                )}
              >
                E-Mail{" "}
              </div>
              <Switch
                checked={enabled}
                onChange={setEnabled}
                className={classNames(
                  enabled ? "bg-indigo-600" : "bg-gray-200 dark:bg-gray-500 ",
                  "relative flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white  shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
              <div
                className={classNames(
                  !enabled
                    ? "text-gray-500 dark:text-white"
                    : "text-indigo-500 dark:text-blue-400",
                  " text-base ml-2"
                )}
              >
                SMS
              </div>
            </div>
            {!enabled ? (
              <div className="mt-12  mb-1 h-full w-full">
                <div className="text-base dark:text-white">
                  Envoyer un E-mail
                </div>
                <form
                  action=""
                  autoComplete="off"
                  className="space-y-4 gap-2 flex flex-col"
                >
                  <div className=" pr-10 ">
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 dark:bg-gray-800 text-gray-500 dark:text-gray-300  sm:text-sm">
                        From
                      </span>
                      <input
                        autoComplete="off"
                        value={email.userSender}
                        onChange={(e) =>
                          setEmail({ ...email, userSender: e.target.value })
                        }
                        enterKeyHint="next"
                        lang="fr"
                        type="email"
                        name="email"
                        className="flex-1 min-w-0 block w-full dark:text-white dark:bg-gray-700  dark:placeholder-gray-300   px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                        placeholder="you@exemple.com"
                      />
                    </div>
                  </div>
                  <div className=" relative pr-10 ">
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 dark:bg-gray-800 text-gray-500 dark:text-gray-300  sm:text-sm">
                        To
                      </span>
                      <input
                        value={email.userRec}
                        onChange={(e) =>
                          setEmail({ ...email, userRec: e.target.value })
                        }
                        enterKeyHint="next"
                        lang="fr"
                        type="email"
                        autoComplete="off"
                        name="email"
                        id="email"
                        className="flex-1 min-w-0 block w-full dark:text-white dark:bg-gray-700  dark:placeholder-gray-300   px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                        placeholder="To@exemple.com"
                      />
                      <div
                        onClick={() => setcCInput(!ccInput)}
                        className="inline-flex items-center hover:bg-gray-300 cursor-pointer justify-center absolute right-14 h-5 w-8 rounded-full  bg-gray-200 inset-y-3"
                      >
                        <div
                          className={classNames(
                            ccInput ? "line-through" : "no-underline",
                            " text-gray-500 dark:text-gray-800  sm:text-sm"
                          )}
                        >
                          Cc
                        </div>
                      </div>
                    </div>
                  </div>

                  {ccInput && (
                    <div className=" pr-10 ">
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 dark:bg-gray-800 text-gray-500 dark:text-gray-300  sm:text-sm">
                          CC
                        </span>
                        <input
                          value={email.cc}
                          onChange={(e) =>
                            setEmail({ ...email, cc: e.target.value })
                          }
                          autoComplete="off"
                          enterKeyHint="next"
                          lang="fr"
                          type="email"
                          name="email"
                          className="flex-1 min-w-0 block w-full dark:text-white dark:bg-gray-700  dark:placeholder-gray-300   px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                          placeholder="you@exemple.com"
                        />
                      </div>
                    </div>
                  )}
                  <div className=" pr-10 ">
                    <label
                      htmlFor="body"
                      className="block text-sm ml-1 dark:text-white font-medium text-gray-700"
                    >
                      Sujet
                    </label>
                    <div className="mt-1">
                      <input
                        autoComplete="off"
                        value={email.sujet}
                        onChange={(e) =>
                          setEmail({ ...email, sujet: e.target.value })
                        }
                        type="text"
                        name="Sujet"
                        placeholder="Sujet"
                        id="Sujet"
                        className="shadow-sm focus:ring-indigo-500 resize-none focus:border-indigo-500 block w-full sm:text-sm border-gray-300 dark:bg-gray-700 dark:placeholder-gray-300 dark:text-white rounded-md"
                      />
                    </div>
                  </div>
                  <div className=" pr-10 space-y2  ">
                    <label
                      htmlFor="body"
                      className="block text-sm ml-1 font-medium dark:text-white text-gray-700"
                    >
                      Contenu
                    </label>
                    <div className="mt-1 relative">
                      {/* input file   */}
                      <label className=" w-10 h-10 absolute z-10  right-8 bottom-0 cursor-pointer rounded-full flex items-center justify-center text-gray-400 hover:text-gray-500 dark:hover:text-gray-300">
                        <div className="flex items-center">
                          <MdAttachFile
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                          <div className="text-sm  ">upload file</div>
                        </div>

                        <input
                          autoComplete="off"
                          type="file"
                          name="file"
                          className="hidden"
                          onChange={(e) => {
                            let reader = new FileReader();
                            var file = e.target.files[0];
                            reader.readAsDataURL(file);

                            reader.onload = function () {
                              if (file.size > 4e6) {
                                window.alert(
                                  "Please upload a file smaller than 4 MB"
                                );
                                return false;
                              }
                              UploadFileFunc(file);
                            };
                            reader.onerror = function () {
                              setUploadFile({
                                filesize: "",
                                filename: "",
                                type: "",
                                file: "",
                              });
                            };
                          }}
                        />
                      </label>

                      <textarea
                        value={email.body}
                        onChange={(e) =>
                          setEmail({ ...email, body: e.target.value })
                        }
                        rows={3}
                        name="Contenu"
                        id="Contenu"
                        className="shadow-sm focus:ring-indigo-500 resize-none focus:border-indigo-500 block w-full sm:text-sm border-gray-300 dark:bg-gray-700 dark:placeholder-gray-300 dark:text-white  rounded-md"
                        placeholder="Contenu"
                      />
                    </div>
                  </div>
                  {/* file & progress Bar */}

                  <div className=" z-10  rounded-md">
                    <div className="w-80">
                      {upload && <ProgressBar progressPercentage={upload} />}
                      {Object.values(uploadFile)[0].length > 0 && (
                        <div className=" flex items-center ml-3    ">
                          {uploadFile?.type?.split("/")[1] === "pdf" ? (
                            <div className="flex items-center cursor-pointer   rounded-md justify-center">
                              <div className="  text-sm text-gray-500 text-right dark:text-white whitespace-normal">
                                {uploadFile?.filename}
                              </div>
                              <AiFillFilePdf className="h-8 text-red-300 w-8" />
                            </div>
                          ) : uploadFile?.type?.split("/")[0] === "image" ? (
                            <div className="flex flex-col justify-between rounded-md ">
                              {/* className="h-8 text-indigo-400 w-8"*/}
                              <AiFillPicture className="h-8 text-indigo-300 w-8" />

                              <div className=" text-sm text-gray-500 text-right dark:text-white whitespace-normal">
                                {uploadFile?.filename}
                              </div>
                            </div>
                          ) : uploadFile?.filename
                              ?.split(".")[1]
                              ?.includes("doc") ? (
                            <div className="flex items-center cursor-pointer rounded-md justify-centerr">
                              <div className="  text-sm text-gray-500 text-right  whitespace-normal">
                                {uploadFile?.filename}
                              </div>

                              <AiFillFileWord className="h-8 text-indigo-400 w-8" />
                            </div>
                          ) : (
                            <div className="flex items-center cursor-pointer rounded-md justify-centerr">
                              <div className="  text-sm text-gray-500 text-right dark:text-white whitespace-normal">
                                {uploadFile?.filename}
                              </div>

                              <AiFillFile className="h-8 text-gray-400 w-8" />
                            </div>
                          )}
                          {Object.values(uploadFile)[0].length > 0 && (
                            <AiFillCloseCircle
                              onClick={() =>
                                setUploadFile({
                                  file: "",
                                  filename: "",
                                  type: "",
                                  filesize: "",
                                })
                              }
                              className="h-6  w-6 text-red-300 cursor-pointer hover:text-red-500 dark:text-red-500"
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </form>
                <div ref={ref} />
              </div>
            ) : (
              <div className="mt-12  h-full mb-1 w-full ">
                <div className="text-base dark:text-white">Envoyer un SMS</div>
                <form action="" className="space-y-4 gap-2 flex p-2 flex-col">
                  <div>
                    <label
                      htmlFor="phone-number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Phone Number
                    </label>
                    <div className="mt-1 pr-10 relative  ">
                      <input
                        autoComplete="off"
                        maxLength={8}
                        required
                        type="tel"
                        value={sms.number}
                        onChange={updateNumber}
                        className="focus:ring-indigo-500 rounded-md shadow-sm focus:border-indigo-500 block w-full   sm:text-sm border-gray-300 "
                        placeholder="70 000 000"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block capitalize text-sm font-medium text-gray-700">
                      titre
                    </label>
                    <div className="mt-1 pr-10 relative  ">
                      <input
                        autoComplete="off"
                        maxLength={8}
                        required
                        type="text"
                        value={sms.entete}
                        onChange={(e) => {
                          if (!/[^a-zA-Z0-9\s]/.test(e.target.value))
                            setSMS({ ...sms, entete: e.target.value });
                        }}
                        className="focus:ring-indigo-500 rounded-md shadow-sm focus:border-indigo-500 block w-full   sm:text-sm border-gray-300 "
                        placeholder="Helpdesk"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block capitalize text-sm font-medium text-gray-700">
                      Message
                    </label>
                    <div className="mt-1 pr-10 relative  ">
                      <input
                        autoComplete="off"
                        maxLength={50}
                        required
                        type="text"
                        value={sms.message}
                        onChange={(e) => {
                          if (!/[^a-zA-Z0-9\s]/.test(e.target.value))
                            setSMS({ ...sms, message: e.target.value });
                        }}
                        className="focus:ring-indigo-500 resize-none space-x-5 rounded-md shadow-sm focus:border-indigo-500 block w-full   sm:text-sm border-gray-300 "
                        placeholder="Ecrire ici... "
                      />
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
  function getSubSubMenu() {
    switch (tabName.name) {
      case "Transfert":
        return (
          <div className="h-flex flex-col justify-between dark:bg-gray-600 w-full">
            <div className=" h-96 flex flex-col-reverse m-1 overflow-y-auto w-full">
              {getSubSubTransfertMenu()}
            </div>
            <div className="h-48 relative  ">
              {error.length > 0 && (
                <div className=" align-middle mx-1 text-red-500 animate-pulse text-xl bottom-20 z-20 left-1/3">
                  {error}
                </div>
              )}

              <div className=" flex py-5 justify-center items-center  space-x-8  dark:bg-gray-600 sm:px-6">
                <button
                  className="text-white  hover:bg-gray-200 hover:translate-x-8  w-28 h-10 text-lg bg-gray-400 items-center flex justify-center py-2 px-4 border  rounded-full shadow"
                  onClick={() => {
                    setTransfertState({
                      personSelected: { id: "", label: "" },
                      raisonInput: "",
                      interventionInput: "",
                    });
                    setEmail({
                      userSender: "",
                      userRec: "",
                      cc: "",
                      sujet: "",
                      body: "",
                    });
                    setSMS({
                      entete: "",
                      number: "",
                      message: "",
                    });
                  }}
                >
                  Annuler
                </button>

                <button
                  disabled={buttonValidate()}
                  onClick={() => {
                    validGestionBloc();
                  }}
                  className={classNames(
                    buttonValidate()
                      ? "cursor-not-allowed bg-gray-500 text-white  hover:translate-x-8  w-28 h-10 text-lg  items-center flex justify-center py-2 px-4 border  rounded-full shadow"
                      : "text-white hover:bg-green-200 hover:translate-x-8  w-28 h-10 text-lg bg-green-400 items-center flex justify-center py-2 px-4 border  rounded-full shadow"
                  )}
                >
                  {!click ? (
                    <div>valider</div>
                  ) : (
                    <div className="flex">
                      <div className=" justify-center flex items-center">
                        <div className="animate-spin">
                          <CgSpinner size={30} color="white" />
                        </div>
                      </div>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        );
      case "General": {
        switch (subSubMenuG) {
          case "échanges":
            return (
              <div className="flex flex-col justify-between  dark:bg-gray-600  w-full ">
                {fullScreen ? (
                  <div className="w-full  ">
                    <div className="relative ">
                      <div className="border  dark:bg-gray-700 border-gray-300 dark:border-gray-50 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                        <div className=" w-full text-editor-full-height overflow-y-auto border-0 dark:bg-gray-700 dark:placeholder-gray-100 dark:text-white focus:ring-0 sm:text-sm">
                          <Editor
                            fullScreen={fullScreen}
                            setFullScreen={setFullScreen}
                            valueEditor={messages}
                            setValueEditor={setMessages}
                          />

                          {/* <JoditEditor
                            setFile={(file) => {
                              setMessages((c) => (c === "" ? file.name : c));
                              setCommentaire({
                                filename: file.name,
                                type: file.type,
                                filesize: file.size,
                                file,
                              });
                            }}
                            // onKeyDown={(event, value) => {
                            //   keydownHandler(event, value);
                            // }}
                            placeholder="Saisir votre message..."
                            tabIndex={-1}
                            value={messages}
                            ref={editor}
                            config={config}
                            onChange={(content) => {
                              console.log(content);
                              setMessages(content.trim());
                            }}
                          /> */}
                        </div>

                        <div className="py-2" aria-hidden="true">
                          <div className="py-px">
                            <div className="h-5" />
                          </div>
                        </div>
                      </div>

                      <div className="absolute  bottom-0 z-10 inset-x-0 pl-3 pr-2  md:flex md:justify-between">
                        <div className="flex items-center  space-x-5">
                          <div className="flex items-center ">
                            <label className="-m-2.5 w-10 h-10 cursor-pointer rounded-full flex items-center justify-center text-gray-400 hover:text-gray-500">
                              <MdAttachFile
                                className="h-5 w-5 z-40"
                                aria-hidden="true"
                              />
                              <span className="sr-only">Attach a file</span>
                              <input
                                autoComplete="off"
                                type="file"
                                className="hidden"
                                onChange={(e) => {
                                  let reader = new FileReader();
                                  var file = e.target.files[0];
                                  reader.readAsDataURL(file);
                                  reader.onload = function () {
                                    if (file.size > 4e6) {
                                      window.alert(
                                        "Please upload a file smaller than 4 MB"
                                      );
                                      return false;
                                    }
                                    setMessages((c) =>
                                      c === "" ? file.name : c
                                    );
                                    setCommentaire({
                                      filename: file.name,
                                      type: file.type,
                                      filesize: file.size,
                                      file: file,
                                    });
                                  };
                                  reader.onerror = function () {
                                    setCommentaire({
                                      filename: "",
                                      type: "",
                                      filesize: "",
                                      file: null,
                                    });
                                  };
                                }}
                              />
                            </label>

                            {commentaire.filename.length === 0 ? (
                              <div className="hidden md:inline text-base font-light italic text-gray-500 dark:text-white">
                                {t("All.pieceJointe")}
                              </div>
                            ) : (
                              <div className="flex items-center">
                                {commentaire?.type?.split("/")[1] === "pdf" ? (
                                  <AiFillFilePdf className="h-8 text-red-300 w-8" />
                                ) : commentaire?.type?.split("/")[0] ===
                                  "image" ? (
                                  <AiFillPicture className="h-8 text-indigo-400 w-8" />
                                ) : commentaire?.filename
                                    ?.split(".")[1]
                                    ?.includes("doc") ? (
                                  <AiFillFileWord className="h-8 text-indigo-400 w-8" />
                                ) : null}
                                <div className="text-base font-light italic text-gray-500 whitespace-normal dark:text-white">
                                  {" "}
                                  {commentaire.filename}
                                </div>

                                {Object.values(commentaire)[1]?.length > 0 && (
                                  <AiFillCloseCircle
                                    onClick={() => {
                                      setMessages((c) =>
                                        c === commentaire.filename ? "" : c
                                      );
                                      setCommentaire({
                                        filename: "",
                                        file: null,
                                        type: "",
                                        filesize: "",
                                      });
                                    }}
                                    className="  h-6 w-6 text-red-300 hover:text-red-400 cursor-pointer dark:text-red-500"
                                  />
                                )}
                              </div>
                            )}
                            <label className=" w-10 h-10 cursor-pointer rounded-full flex items-center justify-center text-gray-400 hover:text-gray-500">
                              <FiHelpCircle
                                onClick={() => setOpen(!open)}
                                className=" ml-5 z-40 h-5 w-5"
                                aria-hidden="true"
                              />

                              <div className="">
                                <Select2
                                  dark="600"
                                  setOpenSelect2={setOpen}
                                  openSelect2={open}
                                  hidden={true}
                                  zIndex={true}
                                  reverse={true}
                                  extra={true}
                                  defaultItem={
                                    Object.values(commentaire)[0].length ===
                                      0 ||
                                    Object.values(commentaire)[1].length ===
                                      0 ||
                                    Object.values(commentaire)[2].length === 0
                                      ? null
                                      : commentaire ?? null
                                  }
                                  setSelectItem={(e) => {
                                    if (e === "" || e === null) {
                                      setMessages({
                                        id: "",
                                        label: "",
                                        labelExtra: "",
                                      });
                                    } else {
                                      console.log("eeeeeeee", e);
                                      setMessages(e?.labelExtra);
                                    }
                                  }}
                                  name="hidden"
                                  onlyLabel={true}
                                  data={dataBase}
                                />
                              </div>
                            </label>
                            <div className="text-base font-light whitespace-normal italic ml-4 text-gray-500 dark:text-white">
                              Base de connaissance
                            </div>
                          </div>
                        </div>
                        <div className="flex-shrink-0">
                          <button
                            ref={sendBtnRef}
                            disabled={buttonValidate()}
                            onClick={() => validGestionBloc()}
                            type="submit"
                            className={classNames(
                              buttonValidate()
                                ? "bg-gray-400 dark:bg-gray-500  cursor-not-allowed"
                                : "bg-green-400 cursor-pointer hover:bg-green-500 focus:ring-green-500",
                              "inline-flex items-center px-4 p-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 "
                            )}
                          >
                            Envoyer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="h-24 lg:h-96  flex flex-col-reverse m-1 overflow-y-auto w-full">
                      {loading ? (
                        <div className="w-full flex justify-center  items-center text-lg">
                          <ReactLoading
                            type="bubbles"
                            className="text-gray-500 dark:text-gray-400"
                            color="gray"
                          />
                        </div>
                      ) : commentaireData.length === 0 ? (
                        <div className="flex justify-center  items-center text-5xl m-4 text-gray-500">
                          Empty...
                        </div>
                      ) : (
                        commentaireData.length > 0 &&
                        commentaireData.map((c, index) => {
                          return (
                            <div
                              key={`key_${c?.Id_Com}_${index}`}
                              className="flex relative py-2  m-2"
                            >
                              <div className="flex">
                                {index === commentaireData.length - 1 ? (
                                  <>
                                    <div className="-top-8 mt-1 p-1  absolute mx-12 my-10  left-1/3 text-sm text-gray-500 dark:text-white">
                                      {moment(c?.D_H_C).format("LL")}
                                    </div>
                                  </>
                                ) : index >= 0 &&
                                  index <= commentaireData.length &&
                                  new Date(
                                    commentaireData[index].D_H_C.split(" ")[0]
                                  ) >
                                    new Date(
                                      commentaireData[index + 1].D_H_C.split(
                                        " "
                                      )[0]
                                    ) ? (
                                  <>
                                    <div className="-top-8 mt-1 p-1 absolute mx-12 my-10  left-1/3 text-sm text-gray-500 dark:text-white">
                                      {moment(c?.D_H_C).format("LL")}
                                    </div>
                                  </>
                                ) : null}
                                <div className="flex-shrink-0">
                                  <div className="flex items-center justify-center uppercase bg-indigo-100 dark:bg-indigo-400 dark:text-gray-200  text-gray-500 h-10 w-10 rounded-full">
                                    {c?.Prenom?.substring(0, 1) ?? ""}
                                  </div>
                                </div>
                                <div className="flex flex-col">
                                  <div className="flex ml-2 mt-2 items-center">
                                    <div className="text-base text-gray-700 dark:text-gray-200 ml-1 font-medium">
                                      {c?.Id_A == user.Id_Login
                                        ? "Vous"
                                        : c?.Prenom}
                                    </div>
                                    <div className="ml-2 text-gray-400 dark:text-gray-50 cursor-pointer font-light">
                                      <ToolTip
                                        color="gray"
                                        type="D_H_C"
                                        value={c?.D_H_C}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-col   ">
                                    <div
                                      dangerouslySetInnerHTML={createMarkup(
                                        c?.Commentaire === c?.filename
                                          ? ""
                                          : c?.Commentaire !== null
                                          ? c?.Commentaire
                                          : "..."
                                      )}
                                      className="max-w-md ml-3  whitespace-normal text-gray-500 dark:text-gray-300 hover:whitespace-pre break-all  overflow-hidden  "
                                    ></div>

                                    <div className=" flex ml-3 ">
                                      {c?.id_file > 0 &&
                                        (c?.type?.split("/")[1] === "pdf" ? (
                                          <div className="flex items-center cursor-pointer hover:bg-gray-100 p-2 rounded-md justify-center">
                                            <a
                                              href={c?.full_path}
                                              className="  text-sm text-gray-500 text-right dark:text-white whitespace-normal"
                                              download={c?.full_path}
                                            >
                                              {c?.filename}
                                            </a>
                                            <AiFillFilePdf className="h-8 text-red-300 w-8" />
                                          </div>
                                        ) : c?.type?.split("/")[0] ===
                                          "image" ? (
                                          <div className="flex flex-col justify-between rounded-md ">
                                            {/* className="h-8 text-indigo-400 w-8"*/}

                                            <img
                                              ref={refImage}
                                              onClick={() =>
                                                setImg(c?.full_path)
                                              }
                                              className="h-20 p-1 cursor-pointer  object-cover  "
                                              src={c?.full_path}
                                              alt="image"
                                            />
                                            <div className=" text-sm text-gray-500 text-right dark:text-white whitespace-normal">
                                              {c?.filename}
                                            </div>
                                          </div>
                                        ) : c?.filename
                                            ?.split(".")[1]
                                            ?.includes("doc") ||
                                          c?.filename
                                            ?.split(".")[1]
                                            ?.includes("odt") ? (
                                          <div className="flex items-center cursor-pointer hover:bg-gray-100 p-2 rounded-md justify-centerr">
                                            <a
                                              href={c?.full_path}
                                              className="text-sm text-gray-500 text-right dark:text-white whitespace-normal"
                                              download={c?.filename}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {c?.filename}
                                            </a>

                                            <AiFillFileWord className="h-8 text-indigo-400 w-8" />
                                          </div>
                                        ) : c?.type
                                            ?.split("/")[1]
                                            ?.includes("csv") ||
                                          c?.fileName
                                            ?.split(".")[1]
                                            ?.includes("xls") ? (
                                          <div className="flex items-center cursor-pointer hover:bg-gray-100 p-2 rounded-md justify-centerr">
                                            <a
                                              href={c?.full_path}
                                              className="  text-sm text-gray-500 text-right dark:text-white whitespace-normal"
                                              download={c?.full_path}
                                            >
                                              {c?.filename}
                                            </a>

                                            <AiFillFileExcel className="h-8 text-green-400 w-8" />
                                          </div>
                                        ) : (
                                          <div className="flex items-center cursor-pointer hover:bg-gray-100 p-2 rounded-md justify-centerr">
                                            <a
                                              href={c?.full_path}
                                              className="  text-sm text-gray-500 text-right dark:text-white whitespace-normal"
                                              download={c?.full_path}
                                            >
                                              {c?.filename}
                                            </a>

                                            <AiFillFile className="h-8 text-gray-400 w-8" />
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>

                    <div className="flex h-48 w-9/12 lg:w-full items-start space-x-4">
                      <div className="w-full ">
                        <div className="relative ">
                          <div className="border  dark:bg-gray-700 border-gray-300 dark:border-gray-50 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                            <div className=" w-full h-28 overflow-y-auto border-0 dark:bg-gray-700 dark:placeholder-gray-100 dark:text-white focus:ring-0 sm:text-sm">
                              <Editor
                                fullScreen={fullScreen}
                                setFullScreen={setFullScreen}
                                valueEditor={messages}
                                setValueEditor={setMessages}
                              />

                              {/* <JoditEditor
                            setFile={(file) => {
                              setMessages((c) => (c === "" ? file.name : c));
                              setCommentaire({
                                filename: file.name,
                                type: file.type,
                                filesize: file.size,
                                file,
                              });
                            }}
                            // onKeyDown={(event, value) => {
                            //   keydownHandler(event, value);
                            // }}
                            placeholder="Saisir votre message..."
                            tabIndex={-1}
                            value={messages}
                            ref={editor}
                            config={config}
                            onChange={(content) => {
                              console.log(content);
                              setMessages(content.trim());
                            }}
                          /> */}
                            </div>

                            <div className="py-2" aria-hidden="true">
                              <div className="py-px">
                                <div className="h-5" />
                              </div>
                            </div>
                          </div>

                          <div className="absolute  bottom-0 z-10 inset-x-0 pl-3 pr-2 flex   md:justify-between">
                            <div className="flex items-center  space-x-5">
                              <div className="flex items-center ">
                                <label className="-m-2.5 w-10 h-10 cursor-pointer rounded-full flex items-center justify-center text-gray-400 hover:text-gray-500">
                                  <MdAttachFile
                                    className="h-5 w-5 z-40"
                                    aria-hidden="true"
                                  />
                                  <span className="sr-only">Attach a file</span>
                                  <input
                                    autoComplete="off"
                                    type="file"
                                    className="hidden"
                                    onChange={(e) => {
                                      let reader = new FileReader();
                                      var file = e.target.files[0];
                                      reader.readAsDataURL(file);
                                      reader.onload = function () {
                                        if (file.size > 4e6) {
                                          window.alert(
                                            "Please upload a file smaller than 4 MB"
                                          );
                                          return false;
                                        }
                                        setMessages((c) =>
                                          c === "" ? file.name : c
                                        );
                                        setCommentaire({
                                          filename: file.name,
                                          type: file.type,
                                          filesize: file.size,
                                          file: file,
                                        });
                                      };
                                      reader.onerror = function () {
                                        setCommentaire({
                                          filename: "",
                                          type: "",
                                          filesize: "",
                                          file: null,
                                        });
                                      };
                                    }}
                                  />
                                </label>

                                {commentaire.filename.length === 0 ? (
                                  <div className="hidden md:inline text-base font-light italic text-gray-500 dark:text-white">
                                    {t("All.pieceJointe")}
                                  </div>
                                ) : (
                                  <div className="flex items-center">
                                    {commentaire?.type?.split("/")[1] ===
                                    "pdf" ? (
                                      <AiFillFilePdf className="h-8 text-red-300 w-8" />
                                    ) : commentaire?.type?.split("/")[0] ===
                                      "image" ? (
                                      <AiFillPicture className="h-8 text-indigo-400 w-8" />
                                    ) : commentaire?.filename
                                        ?.split(".")[1]
                                        ?.includes("doc") ? (
                                      <AiFillFileWord className="h-8 text-indigo-400 w-8" />
                                    ) : null}
                                    <div className="text-base font-light italic text-gray-500 whitespace-normal dark:text-white">
                                      {" "}
                                      {commentaire.filename}
                                    </div>

                                    {Object.values(commentaire)[1]?.length >
                                      0 && (
                                      <AiFillCloseCircle
                                        onClick={() => {
                                          setMessages((c) =>
                                            c === commentaire.filename ? "" : c
                                          );
                                          setCommentaire({
                                            filename: "",
                                            file: null,
                                            type: "",
                                            filesize: "",
                                          });
                                        }}
                                        className="  h-6 w-6 text-red-300 hover:text-red-400 cursor-pointer dark:text-red-500"
                                      />
                                    )}
                                  </div>
                                )}
                                <label className=" w-10 h-10 cursor-pointer rounded-full flex items-center justify-center text-gray-400 hover:text-gray-500">
                                  <FiHelpCircle
                                    onClick={() => setOpen(!open)}
                                    className=" ml-5 z-40 h-5 w-5"
                                    aria-hidden="true"
                                  />

                                  <div className="">
                                    <Select2
                                      dark="600"
                                      setOpenSelect2={setOpen}
                                      openSelect2={open}
                                      hidden={true}
                                      zIndex={true}
                                      reverse={true}
                                      extra={true}
                                      defaultItem={
                                        Object.values(commentaire)[0].length ===
                                          0 ||
                                        Object.values(commentaire)[1].length ===
                                          0 ||
                                        Object.values(commentaire)[2].length ===
                                          0
                                          ? null
                                          : commentaire ?? null
                                      }
                                      setSelectItem={(e) => {
                                        if (e === "" || e === null) {
                                          setMessages({
                                            id: "",
                                            label: "",
                                            labelExtra: "",
                                          });
                                        } else {
                                          console.log("eeeeeeee", e);
                                          setMessages(e?.labelExtra);
                                        }
                                      }}
                                      name="hidden"
                                      onlyLabel={true}
                                      data={dataBase}
                                    />
                                  </div>
                                </label>
                                <div className="text-base font-light whitespace-normal italic ml-4 text-gray-500 dark:text-white">
                                  Base de connaissance
                                </div>
                              </div>
                            </div>
                            <div className="flex-shrink-0">
                              <button
                                ref={sendBtnRef}
                                disabled={buttonValidate()}
                                onClick={() => validGestionBloc()}
                                type="submit"
                                className={classNames(
                                  buttonValidate()
                                    ? "bg-gray-400 dark:bg-gray-500  cursor-not-allowed"
                                    : "bg-green-400 cursor-pointer hover:bg-green-500 focus:ring-green-500",
                                  "inline-flex items-center px-4 p-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 "
                                )}
                              >
                                Envoyer
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            );
          case "messages":
            return (
              <div className="flex flex-col justify-between dark:bg-gray-600   w-full ">
                {/* list chat */}
                <div className=" h-96 flex flex-col   m-1 overflow-y-auto w-full">
                  body
                </div>
                {/* input  */}
                <div className="flex h-48     w-full items-start space-x-4">
                  input
                </div>
              </div>
            );
          default:
            return;
        }
      }
      case "Histo":
        return (
          <div className=" h-full  flex   ">
            <div
              style={{ height: "95%" }}
              className=" w-full  overflow-y-auto  "
            >
              <div
                className={classNames(
                  " flex mt-2  relative h-96 max-w-xl             "
                )}
              >
                <ul role="list" className=" px-2  mt-2 z-0">
                  {loadingTimeLine ? (
                    <div className="text-center absolute inset-1/2  align-middle">
                      <ReactLoading
                        type="bubbles"
                        className="text-gray-500  dark:text-white"
                        color="gray"
                      />
                    </div>
                  ) : (
                    timeLineData.map((event, eventIdx) => (
                      <li key={`${event.Date}___${eventIdx}`}>
                        <div className="relative pb-8">
                          {eventIdx !== timeLineData.length - 1 ? (
                            <span
                              className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                              aria-hidden="true"
                            />
                          ) : null}
                          <div className="relative flex w-full  justify-between space-x-3">
                            <div className="">
                              <span
                                className={classNames(
                                  " bg-purple-400 cursor-pointer   h-8 w-8 rounded-full flex items-center justify-center dark:bg-purple-500 "
                                )}
                              >
                                {getElementTimeLine(event.name)}
                              </span>
                            </div>

                            <div
                              dangerouslySetInnerHTML={createMarkup(
                                event.event
                              )}
                              className=" w-11/12 flex-1  ml-3 text-gray-500 dark:text-gray-300 whitespace-pre-wrap break-all  overflow-hidden  "
                            />

                            <time
                              className="text-xs w-1/12 whitespace-nowrap  dark:text-gray-100 text-gray-500"
                              dateTime={event.Date}
                            >
                              {moment(event.Date).format("LLLL")}
                            </time>
                          </div>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
              </div>
              <div className={classNames("relative h-48")} />
            </div>
          </div>
        );
      case "Enregistrement":
        return (
          <div className=" h-full  w-full">
            <div className={classNames(" flex mt-2 h-96  overflow-y-auto")}>
              {enregData.map((e, index) => {
                return (
                  <div key={index} className="  w-full   ">
                    <div className="w-full p-1 shadow-md border rounded-md max-w-full gap-2    grid grid-cols-3 ">
                      <div className=" col-span-1 flex space-x-2 pl-1 items-center">
                        <div className=" text-gray-400 dark:text-gray-500 capitalize">
                          type :
                        </div>
                        <div className="text-base font-bold">{e.type}</div>
                      </div>
                      <div className=" col-span-1 flex space-x-2 pl-1 items-center">
                        <div className=" text-gray-400 dark:text-gray-500 capitalize">
                          appelant :
                        </div>
                        <div className="text-base font-bold">{e.caller}</div>
                      </div>
                      <div className=" col-span-1 flex space-x-2 pl-1 items-center">
                        <div className=" text-gray-400 dark:text-gray-500 capitalize">
                          date :
                        </div>
                        <div className="text-base font-bold">{e.date_call}</div>
                      </div>

                      <div className=" col-span-3 flex space-x-2 pl-1 items-center">
                        <div className=" text-gray-400 dark:text-gray-500 capitalize">
                          filename :
                        </div>
                        <audio
                          className="h-12 w-10/12 block"
                          src={e.filename}
                          autoPlay={false}
                          controls
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={classNames("relative h-48")} />
          </div>
        );
      default:
        return <div />;
    }
  }
  function callFunction(tel) {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "39sdfsdf076TRdgsdvhzrtfCCGhsdsdfsdfk09k94345GH.eyJzdWIiOiIxMjM0N643REZ32FV25EGdfdcpmkHGHVFgsdyj8JJVHFs.sRPMLIdsdfsdfs456765UDFgbdfgjuPK3453456SDFGDFG67TYUFGHDFG086758CVGRTdghfgHFGHJdfgEZREZRzeR4SDFGQSDFezr35466"
    );
    myHeaders.append(
      "Cookie",
      "ci_session=d4189193e586452428320ffb005f67c882b025b7"
    );

    var formdata = new FormData();
    formdata.append("compte", "client");
    formdata.append("login", "808");
    formdata.append("tel", tel);
    formdata.append("CMK_WS_PK_2", "");
    formdata.append("CMK_WS_PK_3", "");
    formdata.append("CMK_WS_PK_4", "");
    formdata.append("CMK_WS_PK_5", "");

    var config = {
      method: "post",
      url:
        "https://cmkvoip.comunikcrm.info:4444/comunik_ipbx/Api/HELPDESK/ClickToCallVoipAPI?compte=client&poste=808&telephone=" +
        tel,
      headers: {
        Authorization:
          "39sdfsdf076TRdgsdvhzrtfCCGhsdsdfsdfk09k94345GH.eyJzdWIiOiIxMjM0N643REZ32FV25EGdfdcpmkHGHVFgsdyj8JJVHFs.sRPMLIdsdfsdfs456765UDFgbdfgjuPK3453456SDFGDFG67TYUFGHDFG086758CVGRTdghfgHFGHJdfgEZREZRzeR4SDFGQSDFezr35466",
        Cookie: "ci_session=d4189193e586452428320ffb005f67c882b025b7",
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  }
  useEffect(() => {
    ref.current?.scrollIntoView({ block: "end", behavior: "smooth" });

    return () => {};
  }, [upload, uploadFile]);
  let loc = useLocation();

  useEffect(() => {
    setLocation(loc);
  }, [loc]);

  function validateFunc() {
    return (
      clicked ||
      infoTicket.code_client.length === 0 ||
      infoTicket.Flux.length === 0 ||
      infoTicket.Id_Status.length === 0 ||
      infoTicket.Id_Status === 0 ||
      infoTicket.Id_Priorite.length === 0 ||
      infoTicket.Sujet.length === 0 ||
      infoTicket.Id_Voie.length === 0 ||
      infoTicket.Id_Voie === 0
    );
  }

  async function updateTicketFunction() {
    setClicked((c) => !c);

    if (location.pathname === "/tickets" && !sideBar) {
      dispatch(
        updateTicket({
          Id_Tckt: infoTicket?.Id_Tckt,
          code_client: infoTicket.code_client,
          Id_Pers: user.Id_Pers,
          Flux: Number(infoTicket.Flux) > 0 ? Number(infoTicket.Flux) : "",
          Id_Priorite:
            Number(infoTicket.Id_Priorite) > 0
              ? Number(infoTicket.Id_Priorite)
              : "",
          Id_Status:
            Number(infoTicket.Id_Status) > 0
              ? Number(infoTicket.Id_Status)
              : "",
          Id_Type:
            Number(infoTicket.Id_Type) > 0 ? Number(infoTicket.Id_Type) : "",
          Id_Cat:
            Number(infoTicket.Id_Cat) > 0 ? Number(infoTicket.Id_Cat) : "",
          Id_Sous_Cat:
            Number(infoTicket.Id_Sous_Cat) > 0
              ? Number(infoTicket.Id_Sous_Cat)
              : "",
          Id_Voie:
            Number(infoTicket.Id_Voie) > 0 ? Number(infoTicket.Id_Voie) : "",
          Id_Source:
            Number(infoTicket.Id_Source) > 0
              ? Number(infoTicket.Id_Source)
              : "",
          Sujet: infoTicket.Sujet,
          sla: infoTicket.sla,
          Desc_Scis: infoTicket.Desc_Scis,
          Solution: infoTicket.Solution,
          Commentaires: infoTicket.Commentaires,
          Id_departement:
            Number(infoTicket.id_departement) > 0
              ? Number(infoTicket.id_departement)
              : "",
          Id_service:
            Number(infoTicket.id_service) > 0
              ? Number(infoTicket.id_service)
              : "",
          Id_groupe:
            Number(infoTicket.Id_groupe) > 0
              ? Number(infoTicket.Id_groupe)
              : "",
          type: "",
          filename: "",
          filesize: "",
          uri: "",
          general: true,
        })
      );

      setTimeout(() => {
        setClicked(false);
        handleExit();
      }, 2500);
    } else if (location.pathname === "/Mail" && !sideBar) {
      const { data, success, err } = await updateTicketFunc(
        user.Id_Pers,
        infoTicket
      );
      if (success) {
        setSuccessTicket(true);

        setArray(
          array.map((tick) =>
            tick.Id_Tckt === data?.Id_Tckt
              ? {
                  BODY: ticket.BODY,
                  CC: ticket.CC,

                  DATE_INSERT: ticket.DATE_INSERT,

                  DATE_RECEIVED: ticket.DATE_RECEIVED,

                  Date_H_Cr: ticket.Date_H_Cr,
                  FILENAME: ticket.FILENAME,

                  FROM_MAIL: ticket.FROM_MAIL,

                  FROM_NAME: ticket.FROM_NAME,

                  FULLMAILID: ticket.FULLMAILID,
                  INREPLYTO: ticket.INREPLYTO,

                  MAILID: ticket.MAILID,

                  PATH: ticket.PATH,

                  REFERENCES: ticket.REFERENCES,

                  REF_UNIQUEID: ticket.REF_UNIQUEID,

                  SUBJECT: ticket.SUBJECT,

                  THREADID: ticket.THREADID,

                  TO: ticket.TO,

                  URL: ticket.URL,

                  adresse_ip: ticket.adresse_ip,

                  id: ticket.id,

                  item_id: ticket.item_id,

                  level: ticket.level,

                  mail_to_ticket: ticket.mail_to_ticket,

                  ref_campagne: ticket.ref_campagne,

                  ref_inbox: ticket.ref_inbox,
                  suivi: ticket.suivi,

                  unique_id: ticket.unique_id,

                  Flux: data.Flux,
                  Desc_Scis: data.Desc_Scis,
                  Id_Cat: data.Id_Cat,
                  Id_Etpse: data.Id_Etpse,

                  Id_Priorite: data.Id_Priorite,
                  Id_Source: data.Id_Source,
                  Id_Sous_Cat: data.Id_Sous_Cat,
                  Id_Status: data.Id_Status,
                  Id_Tckt: data.Id_Tckt,
                  Id_Type: data.Id_Type,
                  Id_Voie: data.Id_Voie,
                  Solution: data.Solution,
                  Sujet: data.Sujet,
                  agence: data.agence,
                  agent: data.agent,
                  allclient: data.allclient,
                  client: data.client,
                  code_agence: data.code_agence,
                  code_client: data.code_client,
                  code_ticket: data.code_ticket,
                  date_creation: data.date_creation,
                  date_resolution: data.date_resolution,
                  departement: data.departement,
                  etat_sla: data.etat_sla,
                  familles_produits: data.familles_produits,
                  groupe: data.groupe,
                  id_client: data.id_client,
                  id_departement: data.id_departement,
                  id_groupe: data.id_groupe,
                  id_service: data.id_service,
                  perimetre: data.perimetre,
                  produits: data.produits,
                  service: data.service,
                  severite: data.severite,
                  sla: data.sla,
                  source: data.source,
                  statut: data.statut,
                  type: data.type,
                  voie: data.voie,
                }
              : tick
          )
        );

        setTimeout(() => {
          setClicked(false);
          setSuccessTicket(false);
          handleExit();
        }, 2500);
      } else {
        setErrorTicket(err);
      }
    }
    //if(location.pathname === "/clients")
    else if (sideBar) {
      //
      const { data, success, err } = await updateTicketFunc(
        user.Id_Pers,
        infoTicket
      );
      console.log("getData", data, "errror", err, "successs", success);
      if (success) {
        dispatch(
          updateTicket({
            Id_Tckt: data?.Id_Tckt,
            code_client: data.code_client,
            Id_Pers: user.Id_Pers,
            Flux: Number(data.Flux) > 0 ? Number(data.Flux) : "",
            Id_Priorite:
              Number(data.Id_Priorite) > 0 ? Number(data.Id_Priorite) : "",
            Id_Status: Number(data.Id_Status) > 0 ? Number(data.Id_Status) : "",
            Id_Type: Number(data.Id_Type) > 0 ? Number(data.Id_Type) : "",
            Id_Cat: Number(data.Id_Cat) > 0 ? Number(data.Id_Cat) : "",
            Id_Sous_Cat:
              Number(data.Id_Sous_Cat) > 0 ? Number(data.Id_Sous_Cat) : "",
            Id_Voie: Number(data.Id_Voie) > 0 ? Number(data.Id_Voie) : "",
            Id_Source: Number(data.Id_Source) > 0 ? Number(data.Id_Source) : "",
            Sujet: data.Sujet,
            sla: data.sla,
            Desc_Scis: data.Desc_Scis,
            Solution: data.Solution,
            Commentaires: data.Commentaires,
            Id_departement:
              Number(data.id_departement) > 0
                ? Number(data.id_departement)
                : "",
            Id_service:
              Number(data.id_service) > 0 ? Number(data.id_service) : "",
            Id_groupe: Number(data.Id_groupe) > 0 ? Number(data.Id_groupe) : "",
            type: "",
            filename: "",
            filesize: "",
            uri: "",
            general: true,
          })
        );

        setSuccessTicket(true);
        if (notification)
          setArray({
            Flux: data.Flux,
            Desc_Scis: data.Desc_Scis,
            Id_Cat: data.Id_Cat,
            Id_Etpse: data.Id_Etpse,

            Id_Priorite: data.Id_Priorite,
            Id_Source: data.Id_Source,
            Id_Sous_Cat: data.Id_Sous_Cat,
            Id_Status: data.Id_Status,
            Id_Tckt: data.Id_Tckt,
            Id_Type: data.Id_Type,
            Id_Voie: data.Id_Voie,
            Solution: data.Solution,
            Sujet: data.Sujet,
            agence: data.agence,
            agent: data.agent,
            allclient: data.allclient,
            client: data.client,
            code_agence: data.code_agence,
            code_client: data.code_client,
            code_ticket: data.code_ticket,
            date_creation: data.date_creation,
            date_resolution: data.date_resolution,
            departement: data.departement,
            etat_sla: data.etat_sla,
            familles_produits: data.familles_produits,
            groupe: data.groupe,
            id_client: data.id_client,
            id_departement: data.id_departement,
            id_groupe: data.id_groupe,
            id_service: data.id_service,
            perimetre: data.perimetre,
            produits: data.produits,
            service: data.service,
            severite: data.severite,
            sla: data.sla,
            source: data.source,
            statut: data.statut,
            type: data.type,
            voie: data.voie,
          });
        else
          setArray(
            array.map((tick) =>
              tick.Id_Tckt === data?.Id_Tckt
                ? {
                    Flux: data.Flux,
                    Desc_Scis: data.Desc_Scis,
                    Id_Cat: data.Id_Cat,
                    Id_Etpse: data.Id_Etpse,

                    Id_Priorite: data.Id_Priorite,
                    Id_Source: data.Id_Source,
                    Id_Sous_Cat: data.Id_Sous_Cat,
                    Id_Status: data.Id_Status,
                    Id_Tckt: data.Id_Tckt,
                    Id_Type: data.Id_Type,
                    Id_Voie: data.Id_Voie,
                    Solution: data.Solution,
                    Sujet: data.Sujet,
                    agence: data.agence,
                    agent: data.agent,
                    allclient: data.allclient,
                    client: data.client,
                    code_agence: data.code_agence,
                    code_client: data.code_client,
                    code_ticket: data.code_ticket,
                    date_creation: data.date_creation,
                    date_resolution: data.date_resolution,
                    departement: data.departement,
                    etat_sla: data.etat_sla,
                    familles_produits: data.familles_produits,
                    groupe: data.groupe,
                    id_client: data.id_client,
                    id_departement: data.id_departement,
                    id_groupe: data.id_groupe,
                    id_service: data.id_service,
                    perimetre: data.perimetre,
                    produits: data.produits,
                    service: data.service,
                    severite: data.severite,
                    sla: data.sla,
                    source: data.source,
                    statut: data.statut,
                    type: data.type,
                    voie: data.voie,
                  }
                : tick
            )
          );

        setTimeout(() => {
          setClicked(false);
          setSuccessTicket(false);
          handleExit();
        }, 2500);
      } else {
        setErrorTicket("Erreur serveur 500");
        setClicked((c) => !c);
      }
    }
  }

  return (
    <div
      // onMouseEnter={() => setHiddenClose(true)}
      //onMouseLeave={() => setHiddenClose(false)}
      className={classNames(
        // openForm ? "p-3" : "py-2",
        "xl:flex xl:flex-row  relative overflow-y-auto var h-screen bg-gray-200  dark:bg-gray-700 "
      )}
    >
      {!openForm && !hiddenCLose && (
        <div
          className="
      absolute hover:delay-100 hover:scale-125 lg:top-0 lg:right-0 z-20 cursor-pointer h-10 w-10 rounded-full"
        >
          <AiFillCloseCircle
            onClick={() => {
              if (outside) setTicket({});
              onClose(false);
            }}
            className="h-9 w-9  rounded-full  text-gray-500  dark:hover:text-gray-400  hover:text-gray-700 hover:scale-150"
          />
        </div>
      )}

      {openForm ? (
        <div
          ref={refFormTicket}
          className=" z-50 animation dark:bg-gray-900 bg-white "
        >
          <div className="flex w-full  bg-white items-center justify-between ">
            <div className="flex items-center w-2/3 pt-2 justify-between">
              <div
                className="text-gray-500 dark:text-white pl-5
                    font-light text-lg flex items-center justify-center"
              >
                <AiOutlineEdit
                  className="mr-2 text-green-400 font-semibold  dark:text-green-500"
                  size={30}
                />
                {t("All.Modifier")} Ticket n°{ticket.code_ticket}
              </div>

              {errorTicket.length > 0 && (
                <div className="flex text-center text-lg text-red-500 animate-pulse font-semibold">
                  {errorTicket}
                </div>
              )}
              {(successTicket || successG.length > 0) && (
                <div className="flex text-xl text-center text-green-500 animate-pulse font-semibold">
                  {t("Ticket.succesModif")}
                </div>
              )}
            </div>

            {/*error.length>0&&*/}
            <IoExitOutline
              onClick={() => {
                handleExit();
              }}
              className="mr-2 text-gray-500 mt-2 transform  hover:text-gray-300 cursor-pointer font-semibold  dark:text-gray-800"
              size={30}
            />
          </div>

          <FormTicket
            close={setOpenForm}
            dataSelected={ticket}
            setDataSelected={() => {}}
            setDataGroupe={(e) => {
              setInfoTicket({
                ...infoTicket,
                code_ticket: e?.code_ticket,
                code_client: e?.code_client,
                Id_Pers: user.Id_Pers,
                Flux: e?.Flux,
                Id_Priorite: e?.Id_Priorite,
                Id_Status: e?.Id_Status,
                Id_Type: e?.Id_Type,
                Id_Cat: e?.Id_Cat,
                Id_Sous_Cat: e?.Id_Sous_Cat,
                Id_Voie: e?.Id_Voie,
                Id_Source: Number(e?.Id_Source),
                Sujet: e?.Sujet,
                sla: e?.sla,
                Desc_Scis: e?.Desc_Scis,
                Solution: e?.Solution,
                Commentaires: e?.Commentaires,
                id_departement: e?.id_departement,
                id_service: e?.id_service,
                Id_groupe: e?.Id_groupe,
              });
            }}
            edited={true}
            stateBlocForm={infoTicket}
            general={true}
          />
          <div className="flex items-center w-full   justify-around">
            <div className="px-4 py-3 space-x-4  dark:bg-gray-900 justify-center flex sm:px-6">
              <button
                className="text-white  hover:bg-gray-200 hover:translate-x-8  w-28 h-10 text-lg bg-gray-400 items-center flex justify-center py-2 px-4 border  rounded-full "
                onClick={() => {
                  handleExit();
                }}
              >
                {t("All.Annuler")}
              </button>

              <button
                disabled={validateFunc()}
                onClick={() => {
                  updateTicketFunction();
                }}
                className={classNames(
                  validateFunc()
                    ? "cursor-not-allowed bg-gray-500"
                    : "hover:bg-green-200  cursor-pointer bg-green-400",
                  "text-white hover:translate-x-8  w-28 h-10 text-lg  items-center flex justify-center py-2 px-4 border  rounded-full "
                )}
              >
                <div>
                  {!clicked ? (
                    <div>{t("All.Valider")}</div>
                  ) : (
                    <div className="flex">
                      <div className=" justify-center flex items-center">
                        <div className="animate-spin">
                          <CgSpinner size={30} color="white" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          {/* Left side */}

          <aside className="flex-col overflow-y-auto md:ml-12 lg:ml-0  p-2 ml-1 flex  space-y-2 w-4/12 sm:w-10/12 md:w-full lg:11/12 xl:w-5/12 rounded-lg  bg-gray-200 dark:bg-gray-700   dark:text-gray-700   md:flex  ">
            {/* Detail CLient */}
            <div
              className="shadow-xl rounded-md overflow-y-auto bg-white  dark:bg-gray-600  items-start
              2xl:items-start 2xl:flex 2xl:flex-col xl:flex xl:flex-col xl:items-start
              h-2/4 md:flex md:items-center  justify-between
              sm:flex sm:flex-row flex-row w-full"
            >
              <div className="flex  flex-col justify-between h-full space-y-1">
                <div
                  className="flex ml-2 p-3
          "
                >
                  <FaRegBuilding className="h-6 w-6 text-gray-600 dark:text-white" />
                  <div className="text-gray-500  dark:text-white text-xl capitalize ml-2">
                    {t("Ticket.DétailClient")}
                  </div>
                </div>

                <div className=" flex p-2 mt-4 items-center justify-start w-full">
                  <div
                    className="xl:h-10 xl:w-10 md:h-8 md:w-8 rounded-full bg-purple-400 uppercase p-2
      flex items-center justify-center xl:text-2xl md:text-sm  sm:text-sm text-base text-white "
                  >
                    {detail.agence?.substring(0, 1) ?? "***"}{" "}
                  </div>
                  <div className=" flex items-center justify-between max-w-2xl">
                    <div className="xl:flex xl:flex-col md:flex md:flex-row sm:flex-row sm:flex justify-around items-start ">
                      <div className="flex items-center">
                        <div className="text-gray-400 dark:text-white text-xs  ml-2 capitalize">
                          {t("All.NomComplet")}:
                        </div>
                        <div className="flex font-medium text-sm whitespace-normal  ml-1 capitalize text-gray-600 dark:text-white">
                          {detail?.agence ?? "***"}
                        </div>
                      </div>
                    </div>
                    <div className="xl:flex xl:flex-col md:flex md:flex-row sm:flex-row sm:flex justify-start items-start ">
                      <div className="flex items-center">
                        <div className="text-gray-400 dark:text-white text-xs  ml-2 capitalize">
                          code:
                        </div>

                        <div className="flex font-medium md:text-sm xl:text-xl 2xl:text-xl   ml-1 text-gray-600 dark:text-white">
                          {detail?.code_agence ?? "EPS***"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault();

                    window.location = `mailto:${detail.email}`;
                  }}
                  className="flex items-center  p-2     mt-2 w-full dark:text-white hover:text-indigo-400  cursor-pointer"
                >
                  <AiOutlineMail className="h-4 w-4 hover:scale-25 hover:text-indigo-400 dark:text-white text-gray-400 m-1" />

                  <div className="text-md text-gray-600 dark:text-white hover:scale-25 hover:text-indigo-400 capitalize">
                    {detail.email?.length === 0
                      ? "undefined"
                      : detail.email ?? "***@***.com"}
                  </div>
                </div>
                <div
                  onClick={() => {
                    callFunction(detail.Tel);
                  }}
                  className={classNames(
                    !detail.Tel
                      ? ""
                      : "transform hover:scale-105  cursor-pointer  ",
                    "flex  p-2     mt-2 w-full  items-center    "
                  )}
                >
                  <BsTelephone className="h-4 w-4  dark:text-white  text-green-600 m-1" />

                  <div className="text-md text-green-400 dark:text-white">
                    {isNaN(detail.Tel) || !detail.Tel
                      ? "Aucun numéro affiché"
                      : detail.Tel}
                  </div>
                </div>
                <div className=" p-2 flex items-center   dark:text-white hover:text-gray-800 m-1  ">
                  <div className="flex items-center max-w-md overflow-x-auto">
                    <BiMap className="h-5 w-5   text-gray-400 " />
                    <div className="text-md   text-gray-600 dark:text-white whitespace-normal pl-1">
                      {detail.Adresse?.length === 0
                        ? "undefined"
                        : detail.Adresse ?? "Aucune adresse mentionnée"}
                    </div>
                  </div>
                </div>
              </div>
              <div className=" p-2  xl:grid 2xl:grid grid-cols-2 w-full gap-x-6 gap-y-2 flex flex-row  m-1   ">
                <div className="col-span-2 flex items-center border-t-2 border-gray-500 mr-5 ml-5" />
                <div className="flex items-center col-span-1 ">
                  <div className="text-xs text-gray-400 mr-1">
                    {t("Ticket.NomClient")} :
                  </div>
                  <div className=" font-medium  text-sm      text-gray-600 dark:text-white">
                    <ToolTip
                      color="gray"
                      type="agent"
                      value={`${detail.Prenom} ${detail.Nom}`}
                    />
                  </div>
                </div>
                <div className="flex items-center col-span-1">
                  <div className="text-xs text-gray-400 mr-1">
                    {t("Ticket.CodeClient")} :
                  </div>
                  <div className="flex capitalize   text-md dark:text-white text-gray-700 ">
                    {ticket.code_client}
                  </div>
                </div>
              </div>
            </div>
            {/* state */}
            <div
              className=" shadow-xl rounded-md bg-white dark:bg-gray-600
            h-1/4 flex "
            >
              <div
                className="2xl:flex 2xl:flex-col w-4/6 xl:flex xl:flex-col lg;flex lg:flex-row
       flex flex-row justify-center   "
              >
                <div className=" grid p-2 w-full grid-cols-2 gap-y-4">
                  <div className="flex text-sm items-center  text-gray-400 capitalize  dark:text-white">
                    <RiAlertLine
                      className={"h-5  w-5 mr-2 rounded-full text-gray-400"}
                    />
                    {t("All.Sévérité")}:
                  </div>
                  <div
                    className={
                      "rounded-full p-1 text-center font-medium text-white bg-" +
                      colorSeverite +
                      "-300"
                    }
                  >
                    {ticket.severite}
                  </div>

                  <div className="flex text-sm items-center  text-gray-400 capitalize  dark:text-white">
                    <BsArrowLeftRight
                      className={"h-4 mr-2  w-4 rounded-full text-gray-400"}
                    />
                    {t("Bord.Périmètre")}:
                  </div>
                  <div
                    className={
                      "rounded-full p-1 text-center font-medium text-" +
                      colorFlux +
                      "-800  bg-" +
                      colorFlux +
                      "-300"
                    }
                  >
                    {ticket.perimetre}
                  </div>

                  <div className="flex text-sm items-center  text-gray-400 capitalize  dark:text-white">
                    <AiOutlineFlag
                      className={"h-4 mr-2  w-4 rounded-full text-gray-400"}
                    />
                    {t("All.Statut")}:
                  </div>
                  <div
                    className={
                      "rounded-full p-1 text-center font-medium text-" +
                      colorStatut +
                      "-800  bg-" +
                      colorStatut +
                      "-500"
                    }
                  >
                    {ticket.statut}
                  </div>
                </div>
              </div>
            </div>
            {/* Date*/}
            <div
              className=" overflow-x-auto   shadow-xl rounded-md   bg-white dark:bg-gray-600        items-start
                 h-1/4 flex-col justify-between flex"
            >
              <div className=" grid p-2 h-full w-full mt-2 grid-cols-2 items-center gap-2">
                <div className="flex text-sm items-center  text-gray-500  dark:text-white">
                  <AiFillClockCircle className="h-5  w-5 mr-1  text-gray-400" />
                  {t("All.Date")}
                </div>
                <div className=" ml-3 dark:text-white text-gray-600 ">
                  {moment(ticket.date_creation).format("LLL")}
                </div>

                <div className="flex text-sm items-center  text-green-500  dark:text-white">
                  <AiFillClockCircle className="h-5 w-5  mr-1 rounded-full text-green-500" />
                  {t("Ticket.DateResolution")}
                </div>
                <div className="flex ml-3 dark:text-white text-green-500 ">
                  {ticket.date_resolution
                    ? moment(ticket.date_resolution).format("LLL")
                    : "En cours"}
                </div>
                <div
                  className={
                    "flex text-sm items-center text-" +
                    colorSLA +
                    "-500 uppercase dark:text-white"
                  }
                >
                  <AiFillClockCircle
                    className={
                      "h-5 w-5  mr-1 rounded-full text-" + colorSLA + "-500"
                    }
                  />
                  sla
                </div>
                <div
                  className={
                    "flex ml-3 dark:text-white text-" + colorSLA + "-500 "
                  }
                >
                  {moment(
                    ticket.etat_sla.substring(2, ticket.etat_sla.length)
                  ).format("LLL")}
                </div>
              </div>
            </div>
          </aside>
          {/* right side*/}
          <aside className="flex flex-col md:ml-12 lg:ml-0 h-full overflow-x-auto  p-2 bg-gray-200 dark:bg-gray-700  space-y-3  ">
            {/* toppp */}
            <div
              className=" shadow-xl rounded-md w-5/12 sm:w-full md:w-full lg:w-full  h-1/6
         bg-white dark:bg-gray-600 grid grid-cols-4 gap-4
            p-2"
            >
              <div className=" flex flex-col relative justify-between  col-span-1 ">
                <div className="flex items-center ">
                  <div className="text-sm flex whitespace-nowrap  font-light text-gray-400 dark:text-gray-200  ">
                    {t("Ticket.CodeClient")} :
                  </div>
                  <div className=" ml-1 text-xl  uppercase text-gray-800 dark:text-white font-extrabold animate-pulse underline">
                    #{ticket.code_ticket}
                  </div>
                </div>
                <div className="flex items-center ">
                  <div className="text-sm flex  whitespace-nowrap   font-light text-gray-400 dark:text-gray-200   mr-1">
                    {t("Ticket.Assignerà")} :
                  </div>
                  <div className=" ml-1 text-base whitespace-nowrap   text-gray-800 dark:text-white font-bold">
                    {ticket.agent.length > 0 ? ticket.agent : "Non assigné"}
                  </div>
                </div>
              </div>
              <div className=" flex col-span-2 items-center ml-10  text-gray-800 font-light  dark:text-white">
                <div className="text-sm  font-light text-gray-400 dark:text-gray-200   mr-1">
                  {t("Client.Sujet")} :
                </div>
                <div className="max-w-xl flex items-center">
                  <div className="dark:text-white whitespace-pre-line break-words  font-normal overflow-hidden ">
                    {ticket.Sujet}
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  setOpenForm(true);
                }}
                className="text-sm flex hover:text-gray-500 cursor-pointer items-end justify-end col-span-1 font-light text-gray-400 dark:text-gray-200   mr-1"
              >
                <ToolTip
                  value={t("All.Modifier")}
                  type="Modifier3"
                  color="gray"
                />
              </div>
            </div>
            {/* magic bpx */}

            <div
              className=" shadow-xl rounded-md  w-5/12  sm:w-10/12 md:w-full lg:w-full  bg-white dark:bg-gray-600  items-start
              2xl:items-start 2xl:flex 2xl:flex-col   xl:flex xl:flex-col xl:items-start
              h-full mb-2 
               p-2 "
            >
              <div className="  w-full  ">
                {/* <div className="md:hidden sm:hidden dark:bg-gray-600">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    value={tabName.name}
                    id="tabs"
                    name="tabs"
                    className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    onChange={(e) =>
                      setTabName({ name: e.target.value, current: true })
                    }
                  >
                    <option key={`select_key_1`}> {t('Ticket.Échange')}</option>
                    <option key={`select_key_2`}>{t('Ticket.Transfert')}</option>
                    <option key={`select_key_3`}> {t('Historique.Historique')}</option>
                    <option key={`select_key_4`}>{t('Ticket.Enregistrement')}</option>
                  </select>
                </div> */}
                <div className="hidden    sm:block">
                  <div className="border-b cursor-pointer border-gray-200">
                    <div className="-mb-px flex" aria-label="Tabs">
                      <>
                        <Menu
                          as="div"
                          key={`list_key_2`}
                          onClick={() =>
                            setTabName({
                              name: "General",
                              current: true,
                            })
                          }
                          className={classNames(
                            tabName.name === "General" && tabName.current
                              ? "border-indigo-500 dark:border-indigo-300 text-indigo-600 dark:text-indigo-300"
                              : "border-transparent dark:text-gray-50 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300  hover:border-gray-300",
                            "w-1/4 py-4 px-1 relative inline-block text-justify  border-b-2 font-medium text-sm"
                          )}
                        >
                          <div className="z-10">
                            <div
                              className={classNames(
                                tabName.name === "General" && tabName.current
                                  ? "border-indigo-500 dark:border-indigo-300 text-indigo-600 capitalize dark:text-indigo-300 inline-flex justify-center w-full rounded-md    dark:bg-gray-600 bg-white text-sm font-medium border-transparent"
                                  : "inline-flex justify-center w-full rounded-md capitalize    bg-white dark:bg-gray-600 text-sm font-medium border-transparent text-gray-500 dark:text-white hover:text-gray-700 dark:hover:text-gray-300  hover:border-gray-300  "
                              )}
                            >
                              {t("Ticket.Échange")}
                            </div>
                          </div>
                        </Menu>
                      </>

                      <>
                        <Menu
                          as="div"
                          key={`list_key_2`}
                          onClick={() =>
                            setTabName({
                              name: "Transfert",
                              current: true,
                            })
                          }
                          className={classNames(
                            tabName.name === "Transfert" && tabName.current
                              ? "border-indigo-500 dark:border-indigo-300 text-indigo-600 dark:text-indigo-300"
                              : "border-transparent dark:text-gray-50 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300  hover:border-gray-300",
                            "w-1/4 py-4 px-1 relative inline-block text-justify  border-b-2 font-medium text-sm"
                          )}
                        >
                          <div className="z-10">
                            <Menu.Button
                              className={classNames(
                                tabName.name === "Transfert" && tabName.current
                                  ? "border-indigo-500 dark:border-indigo-300 text-indigo-600 dark:text-indigo-300 inline-flex justify-center w-full rounded-md    dark:bg-gray-600 bg-white text-sm font-medium border-transparent"
                                  : "inline-flex justify-center w-full rounded-md    bg-white dark:bg-gray-600 text-sm font-medium border-transparent text-gray-500 dark:text-white hover:text-gray-700 dark:hover:text-gray-300  hover:border-gray-300  "
                              )}
                            >
                              {subSubMenu.length > 0 ? subSubMenu : "Transfert"}
                              <ChevronDownIcon
                                className="-mr-1 ml-2 h-5 w-5"
                                aria-hidden="true"
                              />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="origin-top-right absolute  z-10 right-0 mt-2 w-48 rounded-md dark:bg-gray-400  bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <div
                                      className={classNames(
                                        subSubMenu === "Transfert classique" ||
                                          active
                                          ? "bg-gray-100 dark:bg-gray-300  text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm"
                                      )}
                                      onClick={() =>
                                        setSubSubMenu("Transfert classique")
                                      }
                                    >
                                      {t("Ticket.TransfertClass")}
                                    </div>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <div
                                      className={classNames(
                                        subSubMenu === "messages" || active
                                          ? "bg-gray-100 dark:bg-gray-300 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm"
                                      )}
                                      onClick={() => setSubSubMenu("Niveau 1")}
                                    >
                                      {t("Ticket.Niveau")} 1
                                    </div>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <div
                                      className={classNames(
                                        subSubMenu === "Niveau 2" || active
                                          ? "bg-gray-100 dark:bg-gray-300 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm"
                                      )}
                                      onClick={() => setSubSubMenu("Niveau 2")}
                                    >
                                      {t("Ticket.Niveau")} 2
                                    </div>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <div
                                      className={classNames(
                                        subSubMenu === "Transfert libre" ||
                                          active
                                          ? "bg-gray-100 dark:bg-gray-300 text-gray-900"
                                          : "text-gray-700",
                                        "block w-full text-left px-4 py-2 text-sm"
                                      )}
                                      onClick={() =>
                                        setSubSubMenu("Transfert libre")
                                      }
                                    >
                                      {t("Ticket.TransfertLibre")}
                                    </div>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </>

                      <div
                        onClick={() =>
                          setTabName({
                            name: "Histo",
                            current: true,
                          })
                        }
                        key={`list_key_3`}
                        className={classNames(
                          tabName.name === "Histo" && tabName.current
                            ? "border-indigo-500 dark:border-indigo-300 text-indigo-600 dark:text-indigo-300"
                            : "border-transparent  dark:text-gray-50 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300  hover:border-gray-300",
                          "w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
                        )}
                        aria-current="page"
                      >
                        {t("Historique.Historique")}
                      </div>

                      <div
                        onClick={() =>
                          setTabName({
                            name: "Enregistrement",
                            current: true,
                          })
                        }
                        key={`list_key_4`}
                        className={classNames(
                          tabName.name === "Enregistrement" && tabName.current
                            ? "border-indigo-500 dark:border-indigo-300 text-indigo-600 dark:text-indigo-300"
                            : "border-transparent text-gray-500 dark:text-gray-50 hover:text-gray-700 dark:hover:text-gray-300  hover:border-gray-300",
                          "w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
                        )}
                        aria-current="page"
                      >
                        {t("Ticket.Enregistrement")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="  w-full  h-full  ">{getSubSubMenu()}</div>
            </div>
          </aside>

          <Transition.Root show={img.length > 0 ? true : false} as={Fragment}>
            <Dialog
              as="div"
              className="fixed z-10 inset-0 overflow-y-auto"
              initialFocus={cancelButtonRef}
              onClose={setImg}
            >
              <div className="flex   justify-center   sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0  bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className=" rounded-lg inline-flex justify-center p-3  h-5/6 w-5/6 overflow-hidden transform transition-all ">
                    <img className="object-cover" alt="" src={img} />
                    {/*<div className="relative flex items-center justify-center ">



                    //cancel
                    <div className="  absolute top-0 right-0  ml-2 flex justify-end hover:text-blue-400 cursor-pointer">
                      <a
                        id="#4283"
                        href={img}
                        target="_blank"
                        download={img}
                      >
                        <AiOutlineDownload
                          style={{
                            height: 50,
                            width: 50,
                            color: "#1A4C80",
                          }}
                        />{" "}
                      </a>
                    </div>

                  </div>*/}
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </>
      )}
    </div>
  );
}
export default VarHome;
