export const Get_Ticket = "Get_Ticket";
export const Get_Ticket_Failed = "Get_Ticket_Failed";
export const FETCH_TICKET = "FETCH_TICKET";

export const ADD_TICKET = "ADD_TICKET";
export const ADD_TICKET_FAILED = "ADD_TICKET_FAILED";

export const UPDATE_TICKET = "UPDATE_TICKET";
export const UPDATE_TICKET_FAILED = "UPDATE_TICKET_FAILED";

export const UPDATE_TICKET_SERVICE = "UPDATE_TICKET_SERVICE";
export const UPDATE_TICKET_SERVICE_FAILED = "UPDATE_TICKET_SERVICE_FAILED";

export const SUCCESS_TICKET_UPDATE = "SUCCESS_TICKET_UPDATE";
export const SUCCESS_TICKET_UPDATE_OUT = "SUCCESS_TICKET_UPDATE_OUT";

export const GET_TICKET_FILTER = "GET_TICKET_FILTER";
export const SET_STATUS_TO_FILTER = "SET_STATUS_TO_FILTER";
export const GET_TICKET_FILTER_OUT = "GET_TICKET_FILTER_OUT";

export const SET_FILTER_TICKET = "SET_FILTER_TICKET";

export const DELETE_GROUPE_TICKET = "DELETE_GROUPE_TICKET";
export const DELETE_GROUPE_TICKET_FAILED = "DELETE_GROUPE_TICKET_FAILED";
export const DELETE_TICKET = "DELETE_TICKET";
export const DELETE_TICKET_FAILED = "DELETE_TICKET_FAILED";

export const COMMENTER_GROUPE_TICKET = "COMMENTER_GROUPE_TICKET";
export const COMMENTER_GROUPE_TICKET_FAILED = "COMMENTER_GROUPE_TICKET_FAILED";
export const GET_COMMENTER_FETCH__TICKET = "GET_COMMENTER_FETCH_TICKET";

export const GET_COMMENTER_TICKET = "GET_COMMENTER_TICKET";
export const GET_COMMENTER_TICKET_FAILED = "GET_COMMENTER_TICKET_FAILED";

export const ADD_COMMENTER_TICKET = "ADD_COMMENTER_TICKET";
export const UPDATE_MINE_TICKET = "UPDATE_MINE_TICKET";
export const ENABLE_REFRESH = "ENABLE_REFRESH";
export const DISABLE_REFRESH = "DISABLE_REFRESH";
